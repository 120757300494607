import React, { FC } from 'react';
import { createBrowserRouter, Outlet, RouteObject, RouterProvider } from 'react-router-dom';
import PrivateRoute, { OpenRoute } from './component/PrivateRoute';
import Layout from './component/Layout';
import { routingConfig } from './RoutingConfig';
import ErrorPage from './component/ErrorPage';

const layout: RouteObject = {
  path: '/',
  element: <Layout children={<Outlet />} />,
  children: [],
  errorElement: <ErrorPage errorCode={1} />,
};

const routingConfigParsed = (): RouteObject[] => {
  let result: RouteObject = layout;
  for (let item of routingConfig) {
    let route: RouteObject = {
      path: item.path,
      Component: () => (item.openRoute ? <OpenRoute {...item} /> : <PrivateRoute {...item} />),
    };
    result.children?.push(route);
  }
  return [result];
};

const router = createBrowserRouter(routingConfigParsed());
export const AppRouter: FC = () => <RouterProvider router={router} />;
export default AppRouter;
