import React, { CSSProperties, FC, useContext, useRef } from 'react';
import {
  Close,
  GetApp,
  AddAPhoto,
  Description,
  Error,
  Help,
  RotateLeft,
  RotateRight,
  Save,
  Search,
  VisibilityOff,
  SelectAll,
  BorderAll,
  Warning,
  Undo,
  Home,
  Phone,
  Fax,
  Email,
  Crop,
  ContentPasteSearch,
  ViewWeek,
  Circle,
  KeyboardArrowUp,
  KeyboardArrowDown,
  ShoppingCart,
  FolderZip,
  FirstPage,
  KeyboardDoubleArrowLeft,
  NavigateBefore,
  NavigateNext,
  KeyboardDoubleArrowRight,
  LastPage,
  Favorite,
  FavoriteBorder,
  DragIndicator,
  DragHandle,
  ChevronRight,
  ChevronLeft,
  FormatIndentIncrease,
  FormatIndentDecrease,
  Expand,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import Utils from '../../helper/Utils';
import EditIcon from '@mui/icons-material/Edit';
import { GlobalContext } from '../../context/Global.context';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailIcon from '@mui/icons-material/Mail';

// Liste des icones disponibles
interface IconType {
  style?: CSSProperties;
  icon:
    | 'search'
    | 'description'
    | 'visible'
    | 'notVisible'
    | 'close'
    | 'help'
    | 'download'
    | 'delete'
    | 'add'
    | 'upload'
    | 'rotateLeft'
    | 'rotateRight'
    | 'save'
    | 'qrcode'
    | 'favorite'
    | 'favoriteBorder'
    | 'borderall'
    | 'warning'
    | 'arrowBack'
    | 'info'
    | 'edit'
    | 'undo'
    | 'OK'
    | 'KO'
    | 'home'
    | 'phone'
    | 'fax'
    | 'email'
    | 'waiting'
    | 'size'
    | 'contentSearch'
    | 'column'
    | 'circle'
    | 'arrowUp'
    | 'arrowDown'
    | 'moreVertical'
    | 'shoppingCart'
    | 'zip'
    | 'first'
    | 'last'
    | 'fastForward'
    | 'fastBackward'
    | 'prev'
    | 'next'
    | 'resize'
    | 'dragIndicator'
    | 'dragHandle'
    | 'right'
    | 'left'
    | 'up'
    | 'down'
    | 'indent'
    | 'unIndent'
    | 'expand'
    | 'accountBalanceIcon'
    | 'time'
    | 'mail'
    | '';
}

interface CmsIconProps extends IconType {
  // Type de couleur de l'icone
  typeColor?: 'primary' | 'valid' | 'error' | 'default';
  // Classe css à appliquer
  className?: string;
  // Fonction à appeler lors du clic
  onClick?: any;
  // Tooltip à afficher (message d'explication)
  tooltip?: string | React.ReactNode;
  // Etat actuel de l'élément (visible ou non)
  visible?: boolean;
  // Style à appliquer sur l'icone
  style?: CSSProperties;
  // Lien vers le fichier à télécharger ou une url de la V1
  href?: string;
  // Lien vers la V2 (navigation react)
  target?: string;
  // c'est un fichier téléchargeable
  download?: boolean;
  // Title du fichier téléchargeable
  pdfTitle?: string;
  disabled?: boolean;
  getMouseEvent?: boolean;
  // Position du texte du tooltip
  textPos?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

// Composant Icone, tout les icones material-ui doivent à terme passer par ici,
// Ce composant renvoi un icone, mais peut également transformer cette icone en bouton
// dès lors que l'on ajoute un méthode "onclick" sur le composant.
// on peut également ajouter un toolTip pour plus de lisibilité
/**
 * Composant Icone, tout les icones material-ui doivent à terme passer par ici,
 * Ne plus utiliser Buttons.Icons mais CmsIcon
 * @param icon nom de l'icone
 * @param onClick fonction à appeler lors du clic
 * @param download c'est un fichier téléchargeable
 * @param style style à appliquer sur l'icone
 * @param textPos position du texte du tooltip
 * @param tooltip tooltip à afficher (message d'explication)
 * @param href lien vers le fichier à télécharger ou une url de la V1
 * @param target lien vers la V2 (navigation react)
 * @param pdfTitle title du fichier téléchargeable
 * @param visible état actuel de l'élément (visible ou non)
 * @param disabled désactive le bouton
 * @param typeColor type de couleur de l'icone
 * @param className classe css à appliquer
 */
const CmsIcon: FC<CmsIconProps> = ({
  icon,
  onClick,
  download,
  style,
  textPos,
  tooltip,
  href,
  target,
  pdfTitle,
  visible = true,
  disabled = false,
  typeColor = 'default',
  getMouseEvent,
  className,
}) => {
  const ref = useRef(null);
  const { theming } = useContext(GlobalContext);
  if (!visible || icon === '') return <></>;
  const color = {
    primary: theming.get().palette.primary.main,
    valid: theming.get().cms.severity.valid,
    error: theming.get().cms.severity.error,
    default: theming.get().cms.default,
  }[typeColor];
  style = { color, ...style };
  if (disabled) style = { opacity: 0.5, ...style };
  let IconResult = (iconLib[icon] ?? Error) as any;
  IconResult = <IconResult style={style} />;
  if (!!tooltip)
    IconResult = (
      <Tooltip style={!!onClick ? {} : style} title={tooltip} placement={textPos ?? 'top'}>
        <span className="flex-center">{IconResult}</span>
      </Tooltip>
    );
  if (!!onClick)
    IconResult = (
      <IconButton
        style={style}
        ref={ref}
        className={className}
        disabled={disabled}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          onClick(getMouseEvent ? e : ref);
        }}
      >
        {IconResult}
      </IconButton>
    );
  else if (!!href && !!pdfTitle)
    IconResult = (
      <IconButton
        style={style}
        ref={ref}
        className={className}
        disabled={disabled}
        size="small"
        onClick={() => Utils.openPdfInNewTabWithTitle(href, pdfTitle)}
      >
        {IconResult}
      </IconButton>
    );
  else if (!!href)
    IconResult = (
      <IconButton
        target={target}
        style={style}
        ref={ref}
        type="a"
        className={className}
        disabled={disabled}
        size="small"
        href={href}
        download={download}
      >
        {IconResult}
      </IconButton>
    );
  return IconResult;
};

const iconLib = {
  search: Search,
  description: Description,
  visible: VisibilityIcon,
  notVisible: VisibilityOff,
  close: Close,
  help: Help,
  download: GetApp,
  delete: DeleteIcon,
  add: AddIcon,
  upload: AddAPhoto,
  rotateLeft: RotateLeft,
  rotateRight: RotateRight,
  save: Save,
  arrowBack: ArrowBackIcon,
  qrcode: SelectAll,
  borderall: BorderAll,
  warning: Warning,
  info: InfoIcon,
  edit: EditIcon,
  undo: Undo,
  OK: CheckIcon,
  KO: ClearIcon,
  home: Home,
  phone: Phone,
  fax: Fax,
  email: Email,
  waiting: CircularProgress,
  size: Crop,
  contentSearch: ContentPasteSearch,
  column: ViewWeek,
  circle: Circle,
  arrowUp: KeyboardArrowUp,
  arrowDown: KeyboardArrowDown,
  moreVertical: MoreVertIcon,
  shoppingCart: ShoppingCart,
  zip: FolderZip,
  first: FirstPage,
  last: LastPage,
  fastForward: KeyboardDoubleArrowRight,
  fastBackward: KeyboardDoubleArrowLeft,
  prev: NavigateBefore,
  next: NavigateNext,
  resize: OpenInFullIcon,
  favorite: Favorite,
  favoriteBorder: FavoriteBorder,
  dragIndicator: DragIndicator,
  dragHandle: DragHandle,
  right: ChevronRight,
  left: ChevronLeft,
  up: KeyboardArrowUp,
  down: KeyboardArrowDown,
  indent: FormatIndentIncrease,
  unIndent: FormatIndentDecrease,
  expand: Expand,
  accountBalanceIcon: AccountBalanceIcon,
  time: AccessTimeIcon,
  mail: MailIcon,
};

// <FirstPage />
// <KeyboardDoubleArrowLeft />
// <NavigateBefore />
// <NavigateNext />
// <KeyboardDoubleArrowRight />
// <LastPage />

export default CmsIcon;
