import { saveAs } from 'file-saver';
import NotificationService from './NotificationService';

/**
 * Enregistre un fichier sur l'ordinateur de l'utilisateur
 * TODO: vérifier que le code n'est pas dupliquer dans le projet
 * @param blob Le fichier à enregistrer
 * @param fileName Le nom du fichier
 */
function saveBlob(blob: Blob, fileName: string): void {
  try {
    saveAs(blob, fileName);
  } catch (e) {
    console.error(e);
    NotificationService.error(
      "Une erreur est survenue lors de la tentative d'enregistrement du fichier sur votre ordinateur",
    );
  }
}

const FileService = {
  saveBlob,
};

export default FileService;
