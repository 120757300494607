import React, { FC } from 'react';
import { AddNavigationButton } from '../../component/shared/Buttons';
import { GCElement } from '../../context/Global.context';
import APIRoute from '../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../component/table/CmsTable';
import { ClStation } from '../../interface/ClType';
import CmsTableFilter from '../../component/table/helper/CmsTableFilter';
import CmsIcon from '../../component/shared/CmsIcon';
import ROLE from '../../constant/role.constant';
import CmsTableCell from 'component/table/helper/CmsTableCell';

/**
 * Liste des stations
 */
const StationList: FC = () => (
  <CmsFrontendTable
    title="Liste des sites"
    actions={[
      <CmsIcon href="/help/Sites/" icon="info" tooltip="Aide" target="_blank" />,
      <AddNavigationButton
        key="nav"
        title="Ajouter une station"
        to="/castres/client/station/create"
        access={[ROLE.ADMIN_PARK_STATION_CREATE]}
      />,
    ]}
    route={APIRoute.ClStation}
    globalTable={{ target: GCElement.STATION }}
    columns={StationListConfig}
    downloadable="csv"
    navigateTo={(id: string | number) => `/castres/client/station/${id}/show`}
    rowStyle={(row) => (row.original.active ? {} : { opacity: '0.5' })} //'1px solid rgba(255,0,0,0.2)'
    autoRefresh
    showFooter
    setFiltersInUrl
    invertClick
  />
);

export const StationListConfig: CmsColumnDef<ClStation>[] = [
  {
    header: 'Référence',
    id: 'ref',
    Filter: CmsTableFilter.Search,
    size: 100,
    cell: (info) => <CmsTableCell.Ref cell={info} style={{ textAlign: 'center' }} />,
  },
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Enseigne', id: 'companyName', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Réseau', id: 'networkName', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Service', id: 'serviceLabel', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Téléphone', id: 'tel', Filter: CmsTableFilter.Text, size: 90 },
  { header: 'CP', id: 'postcode', Filter: CmsTableFilter.Text, size: 60 },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  { header: 'Manager', id: 'manager', Filter: CmsTableFilter.Select, filterOptions: { multiple: true } },
  {
    header: "Chargé d'affaire",
    id: 'accountManagerName',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
  },
  {
    header: 'Adresse',
    id: 'address',
    Filter: CmsTableFilter.Text,
    noClick: true,
    hide: 'hiddenByDefault',
    cell: (info) => <CmsTableCell.Address {...info} />,
  },
  {
    header: 'Dispatcher',
    id: 'dispatcherName',
    hide: 'hiddenByDefault',
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true },
  },
  { header: 'Email', id: 'email', hide: 'hiddenByDefault', Filter: CmsTableFilter.Text },
  { header: 'Nom LVNET', id: 'lvnetName', hide: 'hiddenByDefault' },
  {
    header: 'Département',
    id: 'departmentId',
    hide: true,
    Filter: CmsTableFilter.Select,
    filterOptions: { multiple: true, optionList: 'LoDepartment', optionLabel: 'nameWithCode', rawEndpoint: true },
  },
  { header: 'SIRET valide', id: 'haveSiret', Filter: CmsTableFilter.Bool, hide: true },
  { header: 'SIRET', id: 'siret', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour le', id: 'sirenUpdatedAt', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour par', id: 'sirenUpdatedByName', Filter: CmsTableFilter.Select, hide: 'hiddenByDefault' },
  { header: 'Date synchronisation API', id: 'lastApiSync', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  {
    header: 'Synchronisation API par',
    id: 'lastApiSyncByName',
    Filter: CmsTableFilter.Select,
    hide: 'hiddenByDefault',
  },
  { header: 'Synchronisé API', id: 'isApiSync', Filter: CmsTableFilter.Bool, size: 20, hide: 'hiddenByDefault' },
  {
    header: <CmsIcon icon="accountBalanceIcon" tooltip="Contrat CRIBE" />,
    filterHeader: 'Contrat CRIBE',
    id: 'contractCribe',
    Filter: CmsTableFilter.Bool,
    size: 1,
  },
  {
    header: <CmsIcon icon="time" tooltip="Astreinte" />,
    filterHeader: 'Astreinte',
    id: 'onCall',
    Filter: CmsTableFilter.Bool,
    size: 1,
  },
  {
    header: <CmsIcon icon="mail" tooltip="Envoi des rapports par mail" />,
    filterHeader: 'Envoi des rapports par mail',
    id: 'sentMail',
    Filter: CmsTableFilter.Bool,
    size: 1,
  },
  { header: 'Actif', id: 'active', Filter: CmsTableFilter.Bool, size: 1 },
];

export default StationList;
