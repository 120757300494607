import React from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Billing: NavProps = {
  name: 'Facturation',
  icon: <AccountBalanceIcon />,
  subList: [
    {
      name: 'Factures Ventes',
      linkV1: 'castres/billing/invoice/list',
      feature: ROLE.ADMIN_BILLING_INVOICE_LIST,
    },
    {
      name: 'Factures Achats',
      linkV1: 'castres/billing/invoicesupplier/list',
      feature: ROLE.ADMIN_ORDER_INVOICE_LIST,
    },
    {
      name: 'Fournisseur',
      linkV1: 'castres/stock/company/list',
      feature: ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_LIST,
    },
    {
      name: 'Magasin',
      linkV1: 'castres/stock/supplier/list',
      feature: ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_LIST,
    },
    {
      name: 'Exports',
      subList: [
        {
          name: 'Export Client',
          linkV1: 'castres/client/client/xport',
          feature: ROLE.ADMIN_CLIENT_EXPORT,
        },
        {
          name: 'Export Fournisseur',
          linkV1: 'castres/stock/company/xport',
          feature: ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_EXPORT,
        },
        {
          name: 'Export Facture Vente',
          linkV1: 'castres/billing/invoice/xport',
          feature: ROLE.ADMIN_BILLING_INVOICE_EXPORT_BOOK,
        },
        {
          name: 'Export Facture Achat',
          linkV1: 'castres/billing/invoicesupplier/xport',
          feature: ROLE.ADMIN_ORDER_INVOICE_EXPORT_BOOK,
        },
        {
          name: 'Export Journal Vente',
          linkV1: 'billing/book',
          feature: ROLE.ADMIN_BILLING_INVOICE_EXPORT_BOOK,
        },
      ],
    },
  ],
};
