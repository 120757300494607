import React, { FC, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { useRouteError } from 'react-router-dom';
import { CmsContainer } from 'component/shared/Ui';
import { GlobalContext } from '../context/Global.context';
import * as Sentry from '@sentry/react';

const exportPageStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

// Retourne un message d'erreur générique en fonction du code d'erreur
const errorContent = (code: number) => {
  switch (code) {
    case 403:
      return "Erreur 403 : Accès refusé. Vous n'avez pas les droits nécessaires pour accéder à cette page.";
    case 404:
      return "Erreur 404 : Page introuvable. La page que vous recherchez n'existe pas.";
    case 418:
      return 'Erreur : Vous auriez du être redirigé vers la V1, désolé !';
    case 1: //problème React Router
      return 'Veuillez recharger la page et contacter le service développement si le problème persiste.\n(avec pour information : Problème React Router)';
    default:
      return 'Un problème est survenu. Veuillez recharger la page et contacter le service développement si le problème persiste.';
  }
};

/**
 * Composant permettant d'afficher une page d'erreur
 * @param errorCode Code d'erreur
 */
export const ErrorPage: FC<{ errorCode: number }> = ({ errorCode }) => {
  const { theming } = useContext(GlobalContext);
  const routeError = useRouteError(); // Capture l'erreur depuis React Router

  // Capture l'erreur avec Sentry
  React.useEffect(() => {
    if (routeError) {
      Sentry.captureException(routeError);
    }
  }, [routeError]);
  return (
    <CmsContainer style={exportPageStyle}>
      <Typography variant="h1" style={{ color: theming.get().palette.text.primary }}>
        {errorCode === 1 ? 'Ressource introuvable' : errorCode}
      </Typography>
      <Typography variant="h4" style={{ color: theming.get().palette.text.primary }}>
        {errorContent(errorCode)}
      </Typography>
    </CmsContainer>
  );
};

export default ErrorPage;
