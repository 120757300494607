import React, { FC, ReactNode, useEffect, useState } from 'react';
import './shared.scss';
import { InputUI } from './index';
import CmsIcon from './CmsIcon';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface CmsImportPanelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  title: string | ReactNode;
}

export const CmsImportPanel: FC<CmsImportPanelProps> = ({ open, setOpen, children, title }) => {
  return (
    <div className="import-panel-container">
      <div className={'import-panel ' + (open ? 'open' : 'close')}>
        {!open && (
          <div className="open-button">
            <CmsIcon icon="add" onClick={() => setOpen(true)} />
          </div>
        )}
        <div className="header">
          <div className="flex-h space-between">
            {title}
            <CmsIcon icon="close" onClick={() => setOpen(false)} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

interface CmsInportSimpleListProps {
  optionsList: IdLabelCardProps[];
  onClick?: (option: any) => void;
}

interface IdLabelCardProps {
  id: string | number;
  label: string;
  card?: React.ReactNode;
}

export const CmsImportSimpleList: FC<CmsInportSimpleListProps> = ({ optionsList, onClick }) => {
  const [optionList, setOptionList] = useState<IdLabelCardProps[]>(optionsList);
  const [filter, setFilter] = useState<string>();
  useEffect(() => {
    setOptionList(optionsList);
  }, [optionsList]);
  const filteredOptions = optionList.filter((x: any) => x.label.toLowerCase().includes(filter?.toLowerCase() ?? ''));
  return (
    <div className="import-panel-simple-list">
      <InputUI.DebouncedInput onChange={setFilter} />
      <div className="list-container">
        {filteredOptions.map((x: any) =>
          !onClick ? (
            <div key={'import-list' + x.id}>- {x.card ?? x.label}</div>
          ) : (
            <div key={'import-list' + x.id} className="clickable" onClick={() => onClick(x)}>
              - {x.card ?? x.label}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export interface CmsTreeViewProps {
  tree: CmsTreeViewChildrenProps[];
  defaultCard?: (line: CmsTreeViewChildrenProps | any) => ReactNode;
  onCardClick: (line: CmsTreeViewChildrenProps) => void;
}

export interface CmsTreeViewChildrenProps {
  children?: CmsTreeViewChildrenProps[];
  id: string;
  label: ReactNode;
}

export const CmsTreeView: FC<CmsTreeViewProps> = ({ tree, defaultCard, onCardClick }) => {
  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {tree.map((node) => CmsTreeViewNode({ key: node.id, node, defaultCard, onCardClick }))}
    </TreeView>
  );
};

interface CmsTreeViewNodeProps {
  key: string;
  node: CmsTreeViewChildrenProps;
  onCardClick: (line: CmsTreeViewChildrenProps) => void;
  defaultCard?: (line: CmsTreeViewChildrenProps) => ReactNode;
  depth?: number;
}

const CmsTreeViewNode: FC<CmsTreeViewNodeProps> = ({ node, defaultCard, onCardClick, depth = 0 }) => {
  const handleCardClick = () => !node.children && onCardClick(node);
  return (
    <TreeItem
      onClick={handleCardClick}
      nodeId={depth + 'depth' + node.id}
      label={defaultCard ? defaultCard(node) : node.label}
    >
      {node.children?.map((node, i) => (
        <CmsTreeViewNode key={'node' + node.id + '-' + i} depth={depth + 1} {...{ node, defaultCard, onCardClick }} />
      ))}
    </TreeItem>
  );
};
