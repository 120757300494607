import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { WkUnit } from '../../../interface/WkType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC, useState } from 'react';
import ROLE from '../../../constant/role.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';

const manPowerConfigList: CmsColumnDef<WkUnit>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  {
    header: 'Taux horaire',
    id: 'hourlyRate',
    Filter: CmsTableFilter.Number,
    cell: (info) => info.getValue() + ' € / h',
  },
];

export const BlManPowerList: FC = () => {
  return (
    <CmsFrontendTable
      title="Taux horaire main d’œuvre "
      route={APIRoute.BlManPower}
      columns={manPowerConfigList}
      navigateTo={(id: number) => `/castres/billing/manpower/${id}/edit`}
      setFiltersInUrl
      actions={[
        <AddNavigationButton
          access={[ROLE.ADMIN_CONFIGURATION_JOB_CREATE]}
          title="Ajouter un taux horaire main d’œuvre "
          to="/castres/billing/manpower/create"
        />,
      ]}
    />
  );
};

export const BlManpowerCreateEdit: FC = ({ id }: any) => {
  const [history, getHistory] = useState([]);
  return (
    <div className="cms-grid" style={{ gridTemplateColumns: '600px auto' }}>
      <CmsForm
        id={id}
        currentUrl="/castres/billing/manpower/"
        route={APIRoute.BlManPower}
        title="Taux de main d'œuvre"
        onGetEditData={(manpower) => {
          getHistory(manpower.blManpowerHistory);
          return manpower;
        }}
        thin
      >
        <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
        <CmsFormInput.Text id="code" label="Code" required min={2} max={255} />
        <CmsFormInput.Number id="hourlyRate" label="Taux horaire" required />
      </CmsForm>
      <CmsFrontendTable
        columns={[
          { header: 'Date de fin', id: 'endDate', Filter: CmsTableFilter.Date },
          { header: 'Taux', id: 'hourlyRate', Filter: CmsTableFilter.Text },
        ]}
        controlledState={{ state: history, setState: getHistory }}
        title="Historique"
        route="none"
      />
    </div>
  );
};
