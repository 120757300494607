import React, { CSSProperties, FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavigationConf, NavProps } from './configuration';
import { authenticationService } from '../../service/Authentication.service';
import { BASE_URL, ENV_ISDEV, FRONTEND_VERSION } from '../../constant/API_URL';
import './Navigation.scss';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import { NotificationNavigationButton } from '../../page/notification/Notification';
import Utils from '../../helper/Utils';
import { InputUI, UI } from '../shared';
import { GlobalContext } from '../../context/Global.context';
import { darkTheme, lightTheme } from '../Theming';
import CmsIcon from '../shared/CmsIcon';
import ROLE from '../../constant/role.constant';

// État initial du menu
const initialState = {
  // Menu principal actif
  main: '',
  // Sous menu actif
  sub: '',
};

// sauvegarde du logo pour la compilation
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const darkLogo = <img src="/logo_dark.svg" title="logo" alt="logo" />;

// Le composant navigation effectue le filtrage uniquement à la première instanciation pour des gains de performances,
// c'est la raison pour laquelle le composant est scindé ;
export default function Navigation({ menuSettings }: { menuSettings: any }) {
  return <NavigationComponent list={filterWithFeatures()} menuSettings={menuSettings} />;
}

interface NavCompProps {
  // Liste des liens de menu
  list: Array<NavProps>;
  // Paramètres du menu
  menuSettings: any;
}

// Composant du menu de navigation, readme disponible dans le même dossier
const NavigationComponent: FC<NavCompProps> = ({ list, menuSettings }) => {
  const [navState, setNav] = useState(initialState);
  const [saveNav, setSaveNav] = useState(initialState);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const divRef: any = useRef(null);
  const { theming } = useContext(GlobalContext);
  const envColor = Utils.getEnvIconUrl(theming, true);
  const navigate = useNavigate();
  const isMobile = Utils.isMobileBrowser();
  const handleSetNav = (nav: any) => {
    setNav(nav);
    setSaveNav(nav);
  };
  const navStateHandler = { navState, handleSetNav, setMobileMenuOpen };

  // Lorsque l'on clique sur le logo, on redirige vers la page d'accueil de l'application sauf si on est technicien,
  // dans ce cas on redirige vers le dashboard.
  const logoClick = useMemo(() => {
    const user = authenticationService.getCurrentUser();
    if (user?.groupNameList?.find((x: string) => x === 'Technicien')) return () => navigate('/castres/user/dashboard');
    else return () => (window.location.href = BASE_URL);
  }, [navigate]);

  const handleBlur = () => {
    divRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    if (!menuSettings.isMenuLocked) setNav({ main: '', sub: '' });
  };
  const handleMobileBlur = () => setMobileMenuOpen(false);

  // On gère l'animation du menu avec le css
  const cssNavigation: any = {
    width: menuSettings.isMenuLocked ? '320px' : '60px',
    img: { width: menuSettings.isMenuLocked ? '320px' : '60px' },
    '&:hover': { width: '320px', img: { width: '320px' } },
    backgroundColor: envColor + ' !important',
  };

  const child = (
    <List component="nav" aria-labelledby="nested-list-subheader" dense={true}>
      <NotificationNavigationButton onClick={() => setMobileMenuOpen(false)} />
      {list &&
        list.map((item: NavProps, index: number) => (
          <NavSimpleListItem subItem={item} key={index} navStateHandler={navStateHandler} depth={0} />
        ))}
    </List>
  );

  const closeMenu = () => {
    setMobileMenuOpen(false);
    logoClick();
  };

  const className = 'nav-style navigation styled-scroll ' + theming.get().palette.mode;
  // Version mobile du menu
  if (isMobile)
    return (
      <div className="mobile-nav-container nav-style" onMouseLeave={handleMobileBlur}>
        <MobileHeader onMenuClick={() => setMobileMenuOpen(true)} logoClick={logoClick} />
        <div
          className={'mobile-background ' + (mobileMenuOpen ? 'open' : 'close')}
          onClick={() => setMobileMenuOpen(false)}
        />
        <div
          style={{ background: theming.get().cms.main.navClosed }}
          className={className + (mobileMenuOpen ? ' open' : ' close')}
        >
          <div className="logo" onClick={closeMenu}>
            <img src={Utils.getEnvIconUrl(theming)} title="logo" alt="logo" />
          </div>
          {child}
          <NavigationFooter menuSettings={menuSettings} isMobile={isMobile} />
        </div>
      </div>
    );

  // Version desktop du menu
  const navStyle: CSSProperties = { background: theming.get().cms.main.navClosed };
  return (
    <div className="nav-container nav-style" onMouseEnter={() => setNav(saveNav)} onMouseLeave={handleBlur}>
      <div ref={divRef} style={navStyle} className={className} css={cssNavigation}>
        <div>
          <div className="logo" onClick={logoClick}>
            <img src={Utils.getEnvIconUrl(theming)} title="logo" alt="logo" />
          </div>
          {child}
        </div>
        <NavigationFooter menuSettings={menuSettings} isMobile={isMobile} />
      </div>
    </div>
  );
};

interface MobileHeaderProps {
  // Fonction appelée lors du clic sur le bouton du menu
  onMenuClick: any;
  // Fonction appelée lors du clic sur le logo
  logoClick: any;
}

// Changement de l'affichage du menu en fonction de la taille de l'écran
const MobileHeader: FC<MobileHeaderProps> = ({ onMenuClick, logoClick }) => {
  const { theming } = useContext(GlobalContext);

  return (
    <div style={{ background: theming.get().cms.main.navOpened }} className="mobile-header">
      <div className="logo">
        <CmsIcon icon="moreVertical" style={{ marginRight: '1.4em' }} onClick={onMenuClick} />
        <img src={Utils.getEnvIconUrl(theming)} title="logo" alt="logo" onClick={logoClick} />
      </div>
    </div>
  );
};

// Composant du footer du menu de navigation, ici sont afficher les versions du backend, du frontend
// et de la base de données. On y retrouve également le copyright de l'application ainsi que
// le lien pour avetir d'un bug ou bloquer la navigation à ouvert
const NavigationFooter: FC<{ menuSettings: any; isMobile: boolean }> = ({ menuSettings, isMobile }) => {
  const { theming } = useContext(GlobalContext);
  const [backendVersion, setBackendVersion] = useState<string>('0.0');
  const [dbVersion, setDbVersion] = useState<string>('0.0');

  useEffect(() => {
    CRUD.getById<any>(APIRoute.Login, 'GetVersion').then((result) => setBackendVersion(result?.version));
    CRUD.getById<any>(APIRoute.Login, 'GetDbVersion').then((result) => setDbVersion(result?.version));
  }, []);

  return (
    <div className="nav-footer">
      <small className="flex-center">
        <InputUI.CmsSwitch
          inline
          value={theming.get() === darkTheme}
          label="Thème"
          onChange={() => theming.set(theming.get() === darkTheme ? lightTheme : darkTheme)}
        />
      </small>
      {!isMobile && (
        <div>
          <small className="flex-center">
            <InputUI.CmsSwitch
              inline
              value={menuSettings.isMenuLocked}
              label="Garder menu ouvert"
              onChange={menuSettings.toggleMenu}
            />
          </small>
        </div>
      )}
      <small style={{ display: 'block' }}>Version Front: {FRONTEND_VERSION}</small>
      <small style={{ display: 'block' }}>Version Back: {backendVersion}</small>
      <small style={{ display: 'block' }}>Version BDD: {dbVersion}</small>
      <small style={{ display: 'block' }}>
        <UI.CmsLink label={'Rapporter un bug'} href="mailto:cms@castres-equipement.com" />
      </small>
      <small style={{ fontWeight: 'bold', marginTop: '0.25em' }}>© Castres Management System</small>
    </div>
  );
};

interface NavSimpleListItemProps {
  // Sous-liste du menu de navigation
  subItem: any;
  // Fonction permettant de gérer l'état du menu de navigation
  navStateHandler: any;
  // Profondeur de la sous-liste
  depth: number;
}

// Fonction récursive qui permet de créer les sous-listes du menu de navigation
const NavSimpleListItem: FC<NavSimpleListItemProps> = ({ subItem, navStateHandler, depth }: any) => {
  const { name, link, linkV1, subList, tabulation } = subItem;
  const { navState, handleSetNav, setMobileMenuOpen } = navStateHandler;
  const user = useMemo(() => authenticationService.getCurrentUser(), []);

  const handelSubList = () => {
    if (depth === 0) handleSetNav(navState.main === name ? initialState : { main: name });
    else handleSetNav({ ...navState, sub: navState.sub === name ? '' : name });
  };

  const testIfOpen = () => (depth === 0 ? name === navState.main : name === navState.sub);
  const toV2 = (link && ENV_ISDEV) || (!ENV_ISDEV && !linkV1);
  let href = !subList && !toV2 ? BASE_URL + linkV1 : undefined;
  if (!!user.originalUserLabel && !!href) href += (href.includes('?') ? '&' : '?') + `_switch_user=${user.username}`;
  const to = !subList && toV2 ? link : undefined;
  const click = subList ? handelSubList : () => setMobileMenuOpen(false);
  const getIcon = () => {
    if (subItem?.icon) return subItem.icon;
    if (!subList) return <CmsIcon icon="circle" style={{ fontSize: '.6em', marginLeft: '.9em' }} />;
    return testIfOpen() ? <ArrowDropDownIcon /> : <ArrowRightIcon />;
  };
  let attr: any = { component: toV2 ? Link : 'a', target: subItem.forceOpenNewTab ? '_blank' : '_self', href, to };
  if (!!subList) attr = {};
  return (
    <>
      <ListItem className="nav-list-item" {...attr} onClick={click}>
        <ListItemIcon style={{ marginLeft: tabulation ? '1em' : '0' }}>{getIcon()}</ListItemIcon>
        <ListItemText style={{ whiteSpace: 'nowrap' }} primary={name} />
      </ListItem>
      {subList && (
        <Collapse in={testIfOpen()} timeout="auto" unmountOnExit className={'sub-list'}>
          <List component="div" disablePadding dense={true}>
            {subList.map((item: NavProps, index: number) => (
              <NavSimpleListItem subItem={item} key={index} navStateHandler={navStateHandler} depth={depth + 1} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

/**
 * filtrage des liens en fonction des droits d'accès de l'utilisateur courant et supprime les listes vides
 * @return La liste des liens filtrés
 */
function filterWithFeatures(): NavProps[] {
  const user = authenticationService.getCurrentUser();
  let finalNav: Array<NavProps> = NavigationConf;
  if (!user?.roles) return [];
  if (user.roles.includes(ROLE.SUPER_ADMIN)) return finalNav;
  for (let menu of finalNav) {
    menu.subList = filterPermissionList(menu, user.roles);
    if (!menu.subList) continue;
    for (let subMenu of menu.subList) subMenu.subList = filterPermissionList(subMenu, user.roles);
    menu.subList = menu.subList.filter((sub: NavProps) => !(sub.subList && sub.subList.length === 0));
  }
  finalNav = finalNav.filter((menu: NavProps) => menu && ((menu.subList && menu.subList.length > 0) || menu.rootLink));
  return finalNav;
}

/**
 * Filtre chaque lien de navigation en fonction des roles de l'utilisateur.
 * @param navItemParent Le lien de navigation parent.
 * @param roles Les roles de l'utilisateur.
 * @returns La liste des liens de navigation filtrés.
 */
function filterPermissionList(navItemParent: NavProps, roles: any[]): NavProps[] | undefined {
  if (!navItemParent?.subList || (navItemParent.devOnly && !ENV_ISDEV)) return;
  if (navItemParent.feature && roles.filter((x: string) => x === navItemParent.feature).length === 0) return;
  return navItemParent.subList.filter((navItem: NavProps) => {
    if (navItem.devOnly && !ENV_ISDEV) return false;
    if (Array.isArray(navItem.feature)) {
      for (let subfeature of navItem.feature) if (roles.find((x) => x === subfeature)) return true;
    } else if (!navItem.feature || roles.find((x) => x === navItem.feature)) return true;
    return false;
  });
}
