import React, { FC, useEffect } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  ViewNavigationButton,
} from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { LabelValue } from '../../../interface/CommonType';
import CRUD from '../../../service/CRUD.service';
import { ClLegalEntity } from '../../../interface/ClType';
import LoadingScreen from '../../../component/LoadingScreen';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HeaderPanel, CmsPaper } from '../../../component/shared/Ui';
import Utils from '../../../helper/Utils';
import { TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import notificationService from '../../../service/NotificationService';
import ROLE from '../../../constant/role.constant';
import CmsIcon from 'component/shared/CmsIcon';

export const ClLegalEntityListConfig: CmsColumnDef<ClLegalEntity>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code', id: 'code', Filter: CmsTableFilter.Text },
  {
    header: (
      <div className="flex-h align-center">
        Identifiant factures
        <CmsIcon
          icon="info"
          tooltip="Identifiant utilisé pour la génération des références pour les factures, les commandes et les livraisons. Chaque référence doit être unique pour chaque entité juridique, sur les différents CMS."
        />
      </div>
    ),
    id: 'documentCode',
    Filter: CmsTableFilter.Text,
  },
];

/**
 * Liste des entités juridiques
 */
export const ClLegalEntityList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_LEGALENTITY_CREATE])
    ? [<AddNavigationButton title="Ajouter une entité juridique" to="/castres/client/legalentity/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des entités juridiques"
      route={APIRoute.ClLegalEntity}
      columns={ClLegalEntityListConfig}
      navigateTo={(id: number) => `/castres/client/legalentity/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

const LegalEntityContactConfig: LabelValue[] = [
  { label: 'Ville', value: (x: ClLegalEntity) => x.city },
  { label: 'Adresse', value: (x: ClLegalEntity) => x.address },
  { label: 'Code Postal', value: (x: ClLegalEntity) => x.postcode },
  { label: 'Téléphone', value: (x: ClLegalEntity) => x.tel },
  { label: 'Email factures', value: (x: ClLegalEntity) => x.email },
  { label: 'Email commandes', value: (x: ClLegalEntity) => x.emailOrder },
  { label: 'Site web', value: (x: ClLegalEntity) => x.website },
];

const LegalEntityDocumentConfig: LabelValue[] = [
  { label: 'Identifiant factures', value: (x: ClLegalEntity) => x.documentCode },
  { label: 'Capital', value: (x: ClLegalEntity) => x.capital },
  { label: 'SIRET', value: (x: ClLegalEntity) => x.siret },
  { label: 'APE', value: (x: ClLegalEntity) => x.ape },
  { label: 'TVA', value: (x: ClLegalEntity) => x.tva },
  { label: 'BIC', value: (x: ClLegalEntity) => x.bic },
  { label: 'IBAN', value: (x: ClLegalEntity) => x.iban },
  { label: 'Formation', value: (x: ClLegalEntity) => x.training },
];

/**
 * Page de présentation d'une entité juridique
 * @param id Identifiant de l'entité juridique
 */
export const ClLegalEntityShow: FC = ({ id }: any) => {
  const [legalEntity, setLegalEntity] = React.useState<ClLegalEntity | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/castres/client/legalentity/list');
    else CRUD.getById<ClLegalEntity>(APIRoute.ClLegalEntity, id).then(setLegalEntity);
  }, [navigate, id]);

  if (!legalEntity) return <LoadingScreen />;

  return (
    <>
      <HeaderPanel
        title={`${legalEntity?.name}`}
        actions={[
          <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/client/legalentity/list" />,
          AccessFilter([ROLE.ADMIN_CONFIGURATION_LEGALENTITY_EDIT]) && (
            <EditNavigationButton
              title="Éditer l'entité juridique"
              to={`/castres/client/legalentity/${legalEntity?.id}/edit`}
            />
          ),
        ]}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} lg={4}>
          <LegalEntityTab legalEntity={legalEntity} title="Général" col="4fr 6fr" conf={LegalEntityGeneralConfig} />
        </Grid>
        <Grid item sm={12} lg={4}>
          <LegalEntityTab legalEntity={legalEntity} title="Contact" conf={LegalEntityContactConfig} />
        </Grid>
        <Grid item sm={12} lg={4}>
          <LegalEntityTab legalEntity={legalEntity} title="Document" conf={LegalEntityDocumentConfig} />
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Affiche le mois de renouvellement
 * @param dateString Date de renouvellement
 */
const showRenewalMonth = (dateString: Date) => {
  const date = new Date(dateString);
  const month = Utils.Date.getMonthOfTheYearList().find((x) => x.id === date.getMonth() + 1)?.label;
  return date.getDate() + ' ' + month;
};

const LegalEntityGeneralConfig: LabelValue[] = [
  { label: 'Nom', value: (x: ClLegalEntity) => x.name },
  { label: 'Code', value: (x: ClLegalEntity) => x.code },
  { label: 'Renouvellement des RTT', value: (x: ClLegalEntity) => showRenewalMonth(x.rttRenewal) },
  { label: 'Renouvellement des congés payés', value: (x: ClLegalEntity) => showRenewalMonth(x.paidLeaveRenewal) },
  { label: 'Travail le samedi', value: (x: ClLegalEntity) => (x.workSaturday ? 'oui' : 'non') },
  {
    label: 'Liste des services associés',
    value: (x: ClLegalEntity) => {
      const ordered = x.serviceNameList?.split(', ')?.sort();
      return ordered?.map((x, i) => <div key={i}>{x}</div>);
    },
  },
];

/**
 * Liste d'affichage pour une entité juridique
 * @param legalEntity Entité juridique
 * @param col Style des colonnes
 * @param title Titre de la liste
 * @param conf Configuration de la liste
 */
const LegalEntityTab: FC<{ legalEntity: ClLegalEntity; title: string; col?: string; conf: any[] }> = ({
  legalEntity,
  col,
  title,
  conf,
}) => (
  <CmsPaper title={title}>
    <TabStyleDataView gridTemplateColumns={col} conf={conf} mapFrom={legalEntity} />
  </CmsPaper>
);

export const ClLegalEntityCreateEdit: FC = ({ id }: any) => {
  const navigate = useNavigate();
  return (
    <CmsForm
      id={id}
      title="Entité juridique"
      route={APIRoute.ClLegalEntity}
      currentUrl="/castres/client/legalentity/"
      actions={[<ViewNavigationButton title="Voir" to={`/castres/client/legalentity/${id}/show`} />]}
      onSubmit={(result: ClLegalEntity) => {
        return CRUD.post<ClLegalEntity>(APIRoute.ClLegalEntity, result, !!id).then((result) => {
          notificationService.success(!!id ? 'Entité juridique modifiée' : 'Entité juridique créée');
          navigate(`/castres/client/legalentity/${result.id}/show`);
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} lg={4}>
          <h2 style={{ textAlign: 'center' }}>Général</h2>
          <CmsFormInput.Text id="name" label="Nom" required max={50} />
          <CmsFormInput.Text id="code" label="Code" required max={50} />
          <CmsFormInput.Date id="rttRenewal" label="Renouvellement des RTT" required format="dd/MM" />
          <CmsFormInput.Date id="paidLeaveRenewal" label="Renouvellement des congés payés" required format="dd/MM" />
          <CmsFormInput.Switch id="workSaturday" label="Travail le samedi" />
          <CmsFormInput.Select
            id="serviceIdList"
            label="Liste des services associés"
            options={APIRoute.SfService + '/Simplified'}
            multiple
          />
        </Grid>
        <Grid item sm={12} lg={4}>
          <h2 style={{ textAlign: 'center' }}>Contact</h2>
          <CmsFormInput.Text id="city" label="Ville" required max={50} />
          <CmsFormInput.Text id="address" label="Adresse" required max={50} />
          <CmsFormInput.Text id="postcode" label="Code Postal" required max={10} />
          <CmsFormInput.Text id="tel" label="Téléphone" required max={50} />
          <CmsFormInput.Text id="email" label="Email factures" />
          <CmsFormInput.Text id="emailOrder" label="Email commandes" />
          <CmsFormInput.Text id="website" label="Site web" />
        </Grid>
        <Grid item sm={12} lg={4}>
          <h2 style={{ textAlign: 'center' }}>Document</h2>
          <CmsFormInput.Text id="documentCode" label="Identifiant factures" required max={1} />
          <CmsFormInput.Text id="capital" label="Capital" required max={50} />
          <CmsFormInput.Text id="siret" label="SIRET" required max={50} />
          <CmsFormInput.Text id="ape" label="APE" required max={50} />
          <CmsFormInput.Text id="tva" label="TVA" required max={50} />
          <CmsFormInput.Text id="bic" label="BIC" required max={11} />
          <CmsFormInput.Text id="iban" label="IBAN" required max={50} />
          <CmsFormInput.Text id="training" label="Formation" />
        </Grid>
      </Grid>
    </CmsForm>
  );
};
