import React, { CSSProperties, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import APIRoute from '../../../constant/API.constant';
import { AddNavigationButton } from '../../../component/shared/Buttons';
import ROLE from '../../../constant/role.constant';
import { CmsForm, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import {
  BlPriceSchedule,
  BlPriceScheduleGroup,
  BlReferenceStudyGroup,
  BlReferenceStudyGroupLine,
  BlReferenceStudyLine,
} from '../../../interface/BlType';
import { CmsLink, CmsPaper, WarningBubble } from '../../../component/shared/Ui';
import { Buttons, InputUI, UI } from '../../../component/shared';
import CRUD from '../../../service/CRUD.service';
import notificationService from '../../../service/NotificationService';
import { Link } from '@mui/material';
import CmsFilterCalculator from '../../../component/table/helper/CmsTableFilterCalculator';
import CmsTableCell from '../../../component/table/helper/CmsTableCell';
import { BlReferenceStudyLineForm } from './BlReferenceStudyLine.pack';
import { UseFormReturn } from 'react-hook-form';
import CmsIcon from '../../../component/shared/CmsIcon';
import { IdLabel } from '../../../interface/CommonType';
import { CmsImportPanel, CmsImportSimpleList, CmsTreeView } from '../../../component/shared/ImportPanel';
import { CellDndContext, CmsColumnDndDef, CmsDndTable } from '../../../component/table/CmsDndTable';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { GlobalContext } from '../../../context/Global.context';
import { TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { useNavigate } from 'react-router-dom';
import { BlReferenceStudyTypeOptions } from './BlStudyCategory.pack';
import './priceSchedule.scss';
import {
  colorCellFromBlReferenceStudyGroupLine,
  getAllLineGroupCalculation,
  SubRowCompanyComponent,
} from './BlPriceScheduleTools';
import Tooltip from '@mui/material/Tooltip';
import { SubRowRefToRefStudyComponent } from './BlReferenceStudyGroupTools';
import { toolTipPriceScheduleWarningConfigList, WarningAcknowledgment } from './BlPriceSchedule.all.pack';
import Utils from '../../../helper/Utils';
import { DndCell, DndInput } from '../../../component/table/DnDTableCell';

export const BlReferenceStudyGroupListConfigList: CmsColumnDef<BlReferenceStudyGroup>[] = [
  { header: 'Libellé', id: 'label', Filter: CmsTableFilter.Text, size: 600 },
  {
    header: 'Unité',
    id: 'unitId',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.WkUnit },
    cell: (x) => x.row.original.unitLabel,
  },
  {
    header: "Ligne de référence d'étude",
    id: 'lineIdList',
    Filter: CmsTableFilter.Select,
    filterFn: CmsFilterCalculator.containsSomeId,
    filterOptions: { multiple: true, optionList: APIRoute.BlReferenceStudyLine },
    hide: true,
  },
  {
    header: "Ligne de référence d'étude",
    id: 'lineLabelList',
    hide: 'hiddenByDefault',
  },
  {
    header: "Ligne de référence d'étude",
    id: 'blReferenceStudyGroupLine',
    size: 600,
    noClick: true,
    cell: (info) => {
      if (!Array.isArray(info.getValue())) return <></>;
      return (
        <ul>
          {info.getValue().map((groupLine: any) => (
            <li key={groupLine.id}>
              <Link target="_blank" href={`/castres/billing/referencestudyline/${groupLine.lineId}/edit`}>
                {groupLine.quantity}x {groupLine.line?.label}
              </Link>
            </li>
          ))}
        </ul>
      );
    },
  },
  { header: 'Prix de revient', id: 'costPrice', Filter: CmsTableFilter.Number, cell: CmsTableCell.Price, size: 0 },
  { header: 'Prix avec marge', id: 'markupPrice', Filter: CmsTableFilter.Number, cell: CmsTableCell.Price, size: 0 },
];

export const BlReferenceStudyGroupList: FC = () => {
  return (
    <CmsFrontendTable
      route={APIRoute.BlReferenceStudyGroup}
      columns={BlReferenceStudyGroupListConfigList}
      title="Liste des groupes de référence d'études"
      navigateTo={(id: number) => `/castres/billing/referencestudygroup/${id}/edit`}
      actions={[
        <AddNavigationButton
          title="Ajouter un groupe de référence d'étude"
          to="/castres/billing/referencestudygroup/create"
          access={[ROLE.ADMIN_STUDY_REFERENCE_EDIT]}
        />,
      ]}
      setFiltersInUrl
    />
  );
};

const costList = [
  { id: 'flatRateCost', label: 'Coût invariable', value: (x: any) => costPriceShow(x.flatRateCost) },
  { id: 'variableRateCost', label: 'Coût variable', value: (x: any) => costPriceShow(x.variableRateCost) },
  { id: 'costPrice', label: 'Prix de revient', value: (x: any) => costPriceShow(x.costPrice) },
  { id: 'markupPrice', label: 'Prix avec marge', value: (x: any) => costPriceShow(x.markupPrice) },
];

const costPriceShow = (x: any) => <div style={{ textAlign: 'right', width: '8rem' }}>{x?.toFixed(2)} €</div>;

export const BlReferenceStudyGroupCreateEdit: FC = ({ id }: any) => {
  const [state, setState] = useState<BlReferenceStudyGroupLine[]>([]);
  const [priceScheduleGroupList, setPriceScheduleGroupList] = useState<BlPriceSchedule[]>([]);
  const [expanded, setExpanded] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [warningDate, setWarningDate] = useState<Date>();
  const [openPanel, setOpenPanel] = useState(false);
  const [refStudyList, setRefStudyList] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    CRUD.getList<BlReferenceStudyLine>(APIRoute.BlReferenceStudyLine).then(setRefStudyList);
  }, []);

  const handleRefStudyGroupLineUpdate = (data: any[]) => {
    data = data.map((x, i) => ({ ...x, canRowExpand: true, position: i }));
    for (let priceSchedule of priceScheduleGroupList) {
      const refStudyGroup = priceSchedule.blPriceScheduleLineGroup?.find((x) => x.groupId === +id);
      if (!refStudyGroup) continue;
      refStudyGroup.group = { ...refStudyGroup.group, ...getRefStudyGroupSum(data), lastWarningDate: new Date() };
      refStudyGroup.group.blReferenceStudyGroupLine = data;
    }
    setPriceScheduleGroupList([...priceScheduleGroupList]);
    setState(data);
  };

  const onSubmitAll = (data: any) => {
    const payload = { ...data, blReferenceStudyGroupLine: state, blPriceSchedule: priceScheduleGroupList };
    return CRUD.post<BlPriceScheduleGroup>(APIRoute.BlReferenceStudyGroup, payload, !!id).then((result) => {
      notificationService.success('Les modifications ont bien été enregistrées');
      if (!id) navigate(`/castres/billing/referencestudygroup/${result.id}/edit`);
      return result;
    });
  };

  const cost = useMemo(() => getRefStudyGroupSum(state), [state]);

  const handleCreateLine = (data: any, form: UseFormReturn) => {
    const url = APIRoute.BlReferenceStudyGroup + '/Line/Create';
    return CRUD.post<BlReferenceStudyLine>(url, { ...data, groupId: +id })
      .then((line) => {
        notificationService.success("La ligne de référence d'étude a bien été créée");
        setOpen(false);
        handleRefStudyGroupLineUpdate([...state, { ...line, canRowExpand: true }] as any);
        form.reset();
      })
      .catch(() => {});
  };

  const handleRefStudyImport = (refStudy: BlReferenceStudyLine) => {
    const newLine = { id: Utils.getNewId(state), lineId: refStudy.id, line: refStudy, quantity: 1, canRowExpand: true };
    let alreadyExist = state.find((x) => x.lineId === refStudy.id);
    if (!alreadyExist) return handleRefStudyGroupLineUpdate([...state, newLine] as any);
    alreadyExist.quantity++;
    handleRefStudyGroupLineUpdate([...state]);
  };

  const handleExpand = () => {
    if (Object.entries(expanded).length > 0) return setExpanded({});
    let newExpanded: any = {};
    for (let row of state.map((x, i) => ({ ...x, index: i })) as any[])
      if (row.canRowExpand) newExpanded[row.index] = true;
    setExpanded(newExpanded);
  };

  return (
    <>
      <CmsForm
        id={id}
        {...{ currentUrl: '/castres/billing/referencestudygroup/', route: APIRoute.BlReferenceStudyGroup }}
        onSubmit={onSubmitAll}
        onGetEditData={(data: BlReferenceStudyGroup) => {
          const lines = data.blReferenceStudyGroupLine?.map((x) => ({
            ...x,
            canRowExpand: true,
            groupUpdatedAt: data.updatedAt,
          }));
          setWarningDate(new Date(data.lastWarningDate ?? 0));
          handleRefStudyGroupLineUpdate(lines as any);
          setPriceScheduleGroupList(
            data.blPriceScheduleLineGroup?.map((x) => ({
              ...x.line,
              canRowExpand: true,
              blPriceScheduleLineCompany: x.line.blPriceScheduleLineCompany?.map((x) => ({
                ...x,
                newWarningAcknowledgmentDate: x.warningAcknowledgmentDate,
              })),
            })) ?? [],
          );
          return data;
        }}
        renderForm={(form) => {
          return (
            <CmsPaper title="Groupe de référence d'étude">
              <WarningGroup group={form.watch() as any} />
              <div className="cms-grid fr3">
                <CmsFormInput.Text id="label" label="Libellé" required min={2} max={255} />
                <CmsFormInput.Select id="unitId" label="Unité" options={APIRoute.WkUnit + '/Simplified'} required />
                <div style={{ gridArea: '2/1/2/3' }}>
                  <CmsFormInput.Text id="comment" label="Commentaire" multiline />
                </div>
                <div style={{ gridArea: '1/3/3/3' }}>
                  <TabStyleDataView conf={costList} mapFrom={cost} />
                </div>
              </div>
              <CmsFormSubmit type={!form.watch().id ? 'createForm' : 'editForm'} />
            </CmsPaper>
          );
        }}
      />
      {!!id && [
        <CmsDndTable
          actions={[
            <CmsIcon icon="expand" onClick={handleExpand} tooltip="Tout déplier/refermer" />,
            <Buttons.Valid onClick={() => setOpenPanel(!openPanel)}>
              Importer des lignes de référence d'études
            </Buttons.Valid>,
            <Buttons.Valid onClick={() => setOpen(true)}>Créer une nouvelle ligne de référence d'étude</Buttons.Valid>,
          ]}
          title="Liste des lignes de référence d'études"
          stateHandler={{ state, setState: handleRefStudyGroupLineUpdate as any }}
          expandStateHandler={{ state: expanded, setState: setExpanded }}
          columns={StudyLineConfigList}
          cellStyle={() => ({ padding: '0 0.2rem' })}
          SubRowComponent={SubRowComponent}
        />,
        <BlPriceScheduleDndTable
          cost={cost}
          state={priceScheduleGroupList}
          setState={setPriceScheduleGroupList}
          warningDate={warningDate}
        />,
      ]}
      <UI.Dialog open={open}>
        <BlReferenceStudyLineForm
          style={{ marginBottom: 0 }}
          onSubmit={handleCreateLine}
          noReturn={true}
          onCancel={() => setOpen(false)}
        />
      </UI.Dialog>
      <BlRefStudyImportPanel {...{ open: openPanel, setOpen: setOpenPanel, refStudyList, handleRefStudyImport }} />
    </>
  );
};

const getRefStudyGroupSum = (refStudyGroupLine: BlReferenceStudyGroupLine[]) => {
  let sum = {
    flatRateCost: 0,
    variableRateCost: 0,
    flatRateMarkup: 0,
    variableRateMarkup: 0,
    costPrice: 0,
    markupPrice: 0,
  };
  refStudyGroupLine.forEach((line: any) => {
    if (line.isFlatRate) {
      sum.flatRateCost += (line.quantity ?? 0) * (line.line?.costPrice ?? 0);
      sum.flatRateMarkup += (line.quantity ?? 0) * (line.line?.markupPrice ?? 0);
    } else {
      sum.variableRateCost += (line.quantity ?? 0) * (line.line?.costPrice ?? 0);
      sum.variableRateMarkup += (line.quantity ?? 0) * (line.line?.markupPrice ?? 0);
    }
    sum.costPrice += (line.quantity ?? 0) * (line.line?.costPrice ?? 0);
    sum.markupPrice += (line.quantity ?? 0) * (line.line?.markupPrice ?? 0);
  });
  return sum;
};

const WarningGroup: FC<{ group: BlReferenceStudyGroup }> = ({ group }) => {
  if (!group.blReferenceStudyGroupLine?.find((x) => x.warningList?.length > 0)) return [];
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} type={'warn'}>
      Attention, une ou plusieurs lignes de référence d'études rattaché à ce groupe contiennent des avertissements.
    </WarningBubble>
  );
};

const cols = [
  { label: 'Libellé', id: 'label', cell: (x: any) => <>{x.line.label}</>, width: '20rem' },
  {
    label: 'Article',
    id: 'articleLabel',
    cell: (x: any) => (
      <CmsLink
        label={x.line.articleLabel}
        target="_blank"
        href={`/castres/stock/article/${x.line.articleId}/edit`}
        defaultStyle
      />
    ),
  },
  { label: 'Catégorie', id: 'categoryLabel', cell: (x: any) => <>{x.line.categoryLabel}</>, width: '10rem' },
  { label: "Corps d'état", id: 'jobLabel', cell: (x: any) => <>{x.line.jobLabel}</>, width: '10rem' },
  { label: 'Unité', id: 'unitLabel', cell: (x: any) => <>{x.line.unitLabel}</>, width: '8rem' },
  {
    label: 'Quantité',
    id: 'quantity',
    cell: (x: any) => <div style={{ textAlign: 'right' }}>{x.line.quantityWithLoss}</div>,
    width: '4rem',
  },
  {
    label: 'P.Unitaire',
    id: 'calculatedUnitPrice',
    cell: (x: any) => <div style={{ textAlign: 'right' }}>{(x.line.calculatedUnitPrice ?? 0) + ' €'}</div>,
    width: '5.5rem',
  },
  {
    label: 'Perte',
    id: 'lossFactor',
    cell: (x: any) => <div style={{ textAlign: 'right' }}>{(x.line.lossFactor ?? 0) + ' %'}</div>,
    width: '3rem',
  },
  { label: 'P. revient', id: 'costPrice', cell: (x: any) => CellPrice(x.line.costPrice), width: '5.5rem' },
  { label: 'P.V Coeff.', id: 'markupPrice', cell: (x: any) => CellPrice(x.line.markupPrice), width: '5.5rem' },
];

const SubRowComponent: FC<{ row: any }> = ({ row }) => {
  const { theming } = useContext(GlobalContext);
  const headerStyle: CSSProperties = { backgroundColor: theming.get().cms.main.subHeader };
  const data = row.original as BlReferenceStudyGroupLine;
  return (
    <Table>
      <TableHead>
        <TableRow style={headerStyle}>
          {cols.map((x) => (
            <TableCell key={x.id} style={x.width ? { width: x.width } : {}}>
              {x.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {cols.map((x) => (
            <TableCell key={x.id} style={{ padding: '0 0.2rem' }}>
              {x.cell(data)}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ActionCell: FC<any> = (x: CellDndContext<any>) => {
  const { state, setState } = x.cell.stateHandler;
  const message: string[] = x.row.original.warningList ?? [];
  return (
    <div className="flex-center">
      {message.length !== 0 && (
        <CmsIcon
          key="warning"
          icon="warning"
          style={{ color: 'yellow', margin: '-0.2rem 0' }}
          tooltip={message.map((x: string, i: number) => (
            <p>{i + 1 + ') ' + x}</p>
          ))}
          textPos="left"
        />
      )}
      <CmsIcon
        icon="delete"
        style={{ margin: '-0.2rem 0' }}
        onClick={() => setState(state.filter((y: any) => y.id !== x.getValue()))}
      />
    </div>
  );
};

const StudyLineConfigList: CmsColumnDndDef<any>[] = [
  { header: 'Invariable (éditable)', id: 'isFlatRate', size: 0, cell: DndInput.Switch },
  { header: 'Quantité (éditable)', id: 'quantity', size: 0, cell: DndInput.Number },
  {
    header: 'Libellé',
    id: 'line.label',
    size: 600,
    cell: DndCell.Link((rowData) => `/castres/billing/referencestudyline/${rowData.line.id}/edit`),
  },
  { header: 'Type', id: 'line.type', size: 0, cell: (x) => BlReferenceStudyTypeOptions[x.getValue()].label },
  {
    header: 'Quantité Total',
    id: 'id',
    size: 0,
    cell: (x) => (
      <div style={{ textAlign: 'right' }}>
        {(
          (x.row.original.quantity ?? 0) *
          (x.row.original.line?.quantity ?? 0) *
          (1 + (x.row.original.line?.lossFactor ?? 0) / 100)
        ).toFixed(2)}
      </div>
    ),
  },
  { header: 'Unité', id: 'line.unitLabel', size: 0 },
  {
    header: 'Prix de revient',
    id: 'line.costPrice',
    size: 0,
    cell: (x) => (
      <div style={{ textAlign: 'right' }}>{((x.getValue() ?? 0) * (x.row.original.quantity ?? 0)).toFixed(2)} €</div>
    ),
  },
  { header: 'Action', id: 'id', size: 0, cell: ActionCell },
];

const CellPrice = (x: any) => (x ? <div style={{ textAlign: 'right' }}>{x.toFixed(2)} €</div> : <></>);

interface BlRefStudyImportPanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refStudyList: any[];
  handleRefStudyImport: (refStudy: any) => void;
}

const BlRefStudyImportPanel: FC<BlRefStudyImportPanelProps> = ({
  open,
  setOpen,
  refStudyList,
  handleRefStudyImport,
}) => {
  const [selectedButton, setSelectedButton] = useState(1);
  const buttons: IdLabel[] = [
    { id: 1, label: 'Tous' },
    { id: 2, label: 'Par unité' },
  ];
  return (
    <CmsImportPanel {...{ open, setOpen }} title={<h3>Panneau d'import</h3>}>
      <div className="flex-center" style={{ marginBottom: '.5rem' }}>
        <InputUI.CmsButtonGroup value={selectedButton} options={buttons} onClick={setSelectedButton} />
      </div>
      {selectedButton === 1 && <CmsImportSimpleList optionsList={refStudyList} onClick={handleRefStudyImport} />}
      {selectedButton === 2 && <BlRefStudyImport {...{ refStudyList, handleRefStudyImport }} />}
    </CmsImportPanel>
  );
};

interface BlPriceScheduleImportProps {
  refStudyList: any[];
  handleRefStudyImport: (refStudy: any) => void;
}

const BlRefStudyImport: FC<BlPriceScheduleImportProps> = ({ refStudyList, handleRefStudyImport }) => {
  const [filterValue, setFilterValue] = useState<string>();
  const groupedByUnit = useMemo(() => {
    let grouped: { id: any; label: string; children: any[] }[] = [];
    for (let x of refStudyList) {
      let unit = grouped.find((y) => y.id === x.unitId);
      if (!unit) {
        unit = { id: x.unitId, label: x.unitLabel, children: [] };
        grouped.push(unit);
      }
      unit.children.push(x);
    }
    return grouped;
  }, [refStudyList]);
  const filteredAndGroupedByUnit = useMemo(() => {
    if (!filterValue) return groupedByUnit;
    let filtered: { id: any; label: string; children: any[] }[] = [];
    for (let x of groupedByUnit) {
      let unitFilteredChildren = x.children.filter((y: any) =>
        (y.label as string)?.toLowerCase().includes(filterValue ?? ''),
      );
      if (unitFilteredChildren.length === 0) continue;
      filtered.push({ ...x, children: unitFilteredChildren });
    }
    return filtered;
  }, [groupedByUnit, filterValue]);
  return (
    <div>
      <InputUI.DebouncedInput onChange={(x: string) => setFilterValue(x?.toLowerCase())} />
      <CmsTreeView tree={filteredAndGroupedByUnit} defaultCard={CustomCard} onCardClick={handleRefStudyImport} />
    </div>
  );
};

const CustomCard: FC = (line: any) => {
  if (line.children) return line.label;
  return <div style={{ color: '#61daff' }}>{line.label}</div>;
};

const handleUnitPrice: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  let value = x.getValue();
  const selectedCompany = x.cell.lineSharedFunctionList.selectedCompany;
  if (selectedCompany) {
    const priceCompany = x.row.original.blPriceScheduleLineCompany?.find(
      (x: any) => x.companyId === selectedCompany.id,
    );
    if (priceCompany !== undefined) value = priceCompany.price;
  }
  const handleOnChange = (value: any) => {
    if (!selectedCompany) return x.cell.setCellValue(value);
    const line = x.row.original;
    const priceCompany = line.blPriceScheduleLineCompany?.find((x: any) => x.companyId === selectedCompany.id);
    if (!priceCompany) return notificationService.error('Prix introuvable');
    priceCompany.price = value;
    x.cell.lineSharedFunctionList.setState([...x.cell.stateHandler.state]);
  };

  const payload = getAllLineGroupCalculation(value, x.row.original.blPriceScheduleLineGroup);
  const input = (
    <InputUI.DebouncedInput
      variant="standard"
      key={x.cell.key}
      onChange={handleOnChange}
      valueCanUpdate
      value={value}
      priceInput
    />
  );
  if (!payload.costPrice) return input;
  const spaceStyle = { margin: '0 -0.2rem', padding: '0 0.2rem' };
  return (
    <div style={{ ...colorCellFromBlReferenceStudyGroupLine(value, payload), ...spaceStyle }}>
      <Tooltip
        title={
          <TabStyleDataView
            style={{ fontSize: '1rem' }}
            conf={toolTipPriceScheduleWarningConfigList}
            mapFrom={payload}
          />
        }
        style={{ maxWidth: '20rem' }}
        placement="left"
      >
        <span>{input}</span>
      </Tooltip>
    </div>
  );
};

const PriceScheduleActionCell: FC<any> = (x: CellDndContext<any>) => {
  const { state, setState } = x.cell.stateHandler;
  const groupWarningDate = getLastWarningDateOfAllGroups(x.row.original);
  const oldWarningDate = new Date(x.row.original.warningAcknowledgmentDate ?? 0);
  const newWarningDate = new Date(x.row.original.newWarningAcknowledgmentDate ?? 0);
  const wasWarning = oldWarningDate < new Date(groupWarningDate ?? 0);
  const isWarning = newWarningDate < new Date(groupWarningDate ?? 0);
  if (!wasWarning) return <></>;
  const handleAcknowledgement = () => {
    if (isWarning) x.row.original.newWarningAcknowledgmentDate = new Date();
    else x.row.original.newWarningAcknowledgmentDate = x.row.original.warningAcknowledgmentDate;
    setState([...state]);
  };
  return (
    <div className="flex-center">
      <CmsIcon
        icon="warning"
        tooltip={isWarning ? 'Veuillez acquitter cette ligne' : 'Acquitté'}
        style={{ color: isWarning ? 'yellow' : 'inherit', margin: '-0.2rem' }}
        onClick={handleAcknowledgement}
      />
    </div>
  );
};

function getLastWarningDateOfAllGroups(line: BlPriceSchedule): Date {
  let lastWarningDate = new Date(0);
  for (let lineGroup of line.blPriceScheduleLineGroup ?? []) {
    if (!lineGroup.group.lastWarningDate) continue;
    let date = new Date(lineGroup.group.lastWarningDate);
    if (date > lastWarningDate) lastWarningDate = new Date(lineGroup.group.lastWarningDate);
  }
  return lastWarningDate;
}

const priceScheduleReadOnlyConfigList: CmsColumnDndDef<any>[] = [
  {
    header: 'Bordereau',
    id: 'groupLabel',
    size: 200,
    cell: (x) => (
      <CmsLink
        href={`/castres/billing/priceschedule/${x.row.original.group?.id}/edit`}
        label={x.row.original.group?.label}
        defaultStyle
        target="_blank"
      />
    ),
  },
  { header: 'Désignation', id: 'label', size: 500 },
  { header: 'Unité', id: 'unit', size: 76 },
  { header: 'Prix unitaire', id: 'price', size: 0, cell: handleUnitPrice },
  { header: 'Action', id: 'action', size: 0, cell: PriceScheduleActionCell },
];

function isAnyLineWarningAcknowledgment(state: BlPriceSchedule[]): boolean {
  return state.some(
    (x) =>
      x.newWarningAcknowledgmentDate &&
      x.lastGroupWarningDate &&
      x.newWarningAcknowledgmentDate < x.lastGroupWarningDate,
  );
}

interface BlPriceScheduleDndTableProps {
  state: BlPriceSchedule[];
  setState: any;
  cost: any;
  warningDate?: Date;
}

const BlPriceScheduleDndTable: FC<BlPriceScheduleDndTableProps> = ({ state, setState, cost, warningDate }) => {
  const [subRowContentType, setSubRowContentType] = useState<any>('refStudy');
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const handleWarningAcknowledgment = () => {
    for (let line of state) line.newWarningAcknowledgmentDate = new Date();
    setState([...state]);
    setWarningModalOpen(false);
  };

  const handleSubRowToggle = (id: number) => {
    setSubRowContentType(subRowContentType === 'refStudy' ? 'company' : 'refStudy');
  };
  const SubRow: FC<{ row: any; index: number }> = ({ row }) => {
    const data = row.original;
    data.warningDate = warningDate;
    const handleChange = (refStudyId: number, value: any, attr = 'price') => {
      const line = state.find((x: any) => x.id === row.original.id);
      if (!line) return notificationService.error('ligne non trouvée');
      const company: any = line.blPriceScheduleLineCompany?.find((x: any) => x.id === refStudyId);
      if (!company) return notificationService.error("référence d'étude non trouvée");
      company[attr] = value;
      setState([...state]);
    };

    if (subRowContentType === 'company')
      // return <SubRowRefGroupCompanyComponent key={'sub-' + data.id} {...{ data, handleChange }} />;
      return <SubRowCompanyComponent key={'sub-' + data.id} {...{ data, handleChange }} />;
    return <SubRowRefToRefStudyComponent data={data} handleChange={() => {}} />;
  };

  return [
    <CmsDndTable
      title="Lignes de bordereau rattachant ce groupe de référence d'étude"
      actions={[
        isAnyLineWarningAcknowledgment(state) && (
          <CmsIcon
            icon="warning"
            onClick={() => setWarningModalOpen(true)}
            tooltip="Aquitter tout les avertissement"
            style={{ color: 'yellow' }}
          />
        ),
        <InputUI.CmsButtonGroup
          value={subRowContentType}
          options={[
            { id: 'refStudy', label: "Réf d'études" },
            { id: 'company', label: 'Prix par enseigne' },
          ]}
          onClick={handleSubRowToggle}
        />,
      ]}
      cellStyle={() => ({ padding: '0 0.2rem' })}
      stateHandler={{ state, setState }}
      lineSharedFunctionList={{ cost, warningDate }}
      columns={priceScheduleReadOnlyConfigList}
      SubRowComponent={SubRow}
      withoutDnd
    />,
    <WarningAcknowledgment
      open={warningModalOpen}
      setOpen={setWarningModalOpen}
      onValidate={handleWarningAcknowledgment}
    />,
  ];
};
