import React, { FC } from 'react';
import './../../style/theme.scss';
import { WkGreenfluxStation } from 'interface/WkType';
import { UI } from 'component/shared';
import { ArrowBackNavigationButton, NavigationButton } from 'component/shared/Buttons';
import { OsmMapShow } from '../../component/map/OsmMap';
import AccessFilter from '../../helper/AccessFilter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Link from '@mui/material/Link';
import ROLE from '../../constant/role.constant';

/**
 * Effectue la mise en forme des données récupérées depuis le backend pour une station Greenflux.
 * @param station contient les données de la station à mettre en page
 */
const StationGFDetailUi: FC<{ station: WkGreenfluxStation }> = ({ station }) => (
  <>
    <UI.HeaderPanel
      title="Station Greenflux"
      subtitle={station.name + ' - ' + station.locationId}
      actions={[
        <ArrowBackNavigationButton title="Retour à la liste" to="/castres/greenflux/stations/list" key={''} />,
        <NavigationButton
          title="Éditer la station"
          to={`/castres/greenflux/stations/${station.id}/edit`}
          access={[ROLE.ADMIN_GF_STATION_EDIT]}
          key={''}
        />,
      ]}
    />

    <div className="station-container">
      {/* Panneau gauche */}
      <div style={{ marginRight: '0.25em' }}>
        <General station={station} />
        <Coordonnees station={station} />
      </div>

      {/* Panneau droit */}
      <div>
        {station['clientId'] ? (
          <Operateur
            type={'Client'}
            name={station['clientName']}
            website={station['clientWebsite']}
            email={station['clientEmail']}
            phone={station['clientPhone']}
            key={''}
          />
        ) : (
          <UI.ChipInfo
            icon={<CheckCircleIcon />}
            label="Client non identifiable veuillez régler cela dans l'édition"
            isValid={false}
          />
        )}
        {[
          { id: 'op', label: 'Opérateur' },
          { id: 'sop', label: 'Sous Opérateur' },
          { id: 'ow', label: 'Propriétaire' },
        ].map((item) => (
          <Operateur
            type={item.label}
            name={station[item.id + 'Name']}
            website={station[item.id + 'Website']}
            email={station[item.id + 'Email']}
            phone={station[item.id + 'Phone']}
            key={''}
          />
        ))}
      </div>
    </div>
  </>
);

/*****************************************************\
 *      Fonctions de mise en page des blocs          * |
\*****************************************************/

/**
 * Affiche les informations générales de la station.
 * @param station contient les données de la station à mettre en page
 */
const General: FC<{ station: WkGreenfluxStation }> = ({ station }) => {
  return (
    <UI.Paper title="Données de la station Greenflux" tied actions={[]}>
      <UI.LabelValue label="Nom de la station">{station.name}</UI.LabelValue>
      <UI.LabelValue label="ID de la station">{station.locationId}</UI.LabelValue>

      <UI.Divider />

      <UI.ChipInfo icon={<CheckCircleIcon />} label="Station Greenflux publiée" isValid={station.publish} />
      <UI.ChipInfo
        icon={<CheckCircleIcon />}
        label="Lien entre la station Greenflux et la station CMS"
        isValid={station.isLinked}
      />
      <UI.LabelValue label="Référence de la station CMS">
        <NavigationButton
          title={station.stationCms}
          to={`/castres/client/station/${station.stationCmsId}/show`}
          access={[ROLE.ADMIN_PARK_STATION_LIST]}
        />
        {!AccessFilter([ROLE.ADMIN_PARK_STATION_LIST]) && station.stationCms}
      </UI.LabelValue>

      <UI.Divider />

      <UI.LabelValue label="Type de station">{station.type}</UI.LabelValue>
      <UI.LabelValue label="Type d'accès à la station">{station.accessType}</UI.LabelValue>
    </UI.Paper>
  );
};

/**
 * Affiche les données de localisation de la station.
 * @param station contient les données de la station à mettre en page
 */
const Coordonnees: FC<{ station: WkGreenfluxStation }> = ({ station }) => (
  <UI.Paper title="Coordonnées" style={{ display: 'flex', flexDirection: 'column' }} tied>
    <UI.LabelValue label="Pays">{station.country}</UI.LabelValue>
    <UI.LabelBlockValue label="Adresse">
      <p>{station.address}</p>
      <p>{`${station.postalCode} ${station.city}`}</p>
    </UI.LabelBlockValue>

    <UI.Divider />
    {!!station.latitude && !!station.longitude && (
      <OsmMapShow
        setCenter={{
          lat: parseFloat(station.latitude ? station.latitude : '0.0'),
          lng: parseFloat(station.longitude ? station.longitude : '0.0'),
        }}
      />
    )}
  </UI.Paper>
);

interface OperateurProps {
  type?: string;
  name?: string;
  website?: string;
  email?: string;
  phone?: string;
}

/**
 * Affiche les données liées au propriétaire, à l'opérateur et au sous-opérateur.
 * @param type donne le titre à afficher, indiquant si c'est le propriétaire, l'opérateur ou autre.
 * @param name est le nom du type.
 * @param website site web du type.
 * @param email du type.
 * @param phone du type.
 */
const Operateur: FC<OperateurProps> = ({ type, name, website, email, phone }) => {
  return (
    <UI.Paper title={type} style={{ display: 'flex', flexDirection: 'column' }} tied>
      <UI.LabelValue label="Nom">{name}</UI.LabelValue>
      <UI.LabelValue label="Site web">
        <Link key={'Site web : ' + type} style={{ color: 'inherit' }} href={website}>
          {website}
        </Link>
      </UI.LabelValue>
      <UI.LabelValue label="Email">
        <Link href={'mailto:' + email}>{email}</Link>
      </UI.LabelValue>
      <UI.LabelValue label="Numéro de téléphone">
        <Link href={'tel:' + phone}> {phone}</Link>
      </UI.LabelValue>
    </UI.Paper>
  );
};

export default StationGFDetailUi;
