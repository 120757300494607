import { BlPriceSchedule, BlReferenceStudyLineGroup } from '../../../interface/BlType';
import React, { CSSProperties, FC, useContext } from 'react';
import { GlobalContext } from '../../../context/Global.context';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { getAllLineGroupCalculation, getIndentStyle, SubSubRowComponent } from './BlPriceScheduleTools';
import CmsIcon from '../../../component/shared/CmsIcon';
import { CmsLink } from '../../../component/shared/Ui';
import './priceSchedule.scss';

interface SubRowRefToRefStudy {
  data: BlPriceSchedule;
  handleChange: (refStudyId: number, value: any, attr?: string, table?: string) => void;
}

export const SubRowRefToRefStudyComponent: FC<SubRowRefToRefStudy> = ({ data }) => {
  const { theming } = useContext(GlobalContext);
  const mainTheme = theming.get().cms.main;
  const [subSubRowHandler, setSubSubRowHandler] = React.useState<any>([]);
  const payload = getAllLineGroupCalculation(0, data.blPriceScheduleLineGroup);
  const headerStyle: CSSProperties = { backgroundColor: theming.get().cms.main.header };

  const handleSubSubRowToggle = (id: number) => {
    setSubSubRowHandler({ ...subSubRowHandler, [id]: !subSubRowHandler[id] });
  };
  return (
    <div
      key={'subrow-body-' + data.id}
      className="cms-grid"
      style={{ gridTemplateColumns: '10rem auto', padding: '0.2rem' }}
    >
      <div>
        <h3>Référence </h3>
        <h3>d'études: </h3>
      </div>
      <div style={{ ...getIndentStyle(data.indent ?? 0, -1) }}>
        <Table>
          <TableHead>
            <TableRow style={headerStyle}>
              <TableCell style={{ width: '3rem' }}>
                <CmsIcon icon="visible" tooltip="Voir les lignes de référence d'études" />
              </TableCell>
              <TableCell style={{ width: '8rem' }}>Quantité</TableCell>
              <TableCell>Désignation</TableCell>
              <TableCell style={{ width: '25rem' }}>Commentaire</TableCell>
              <TableCell style={{ width: '12rem' }}>
                Coût de revient <b>({payload.costPrice.toFixed(2)} €)</b>
              </TableCell>
              <TableCell style={{ width: '11rem' }}>
                Avec marge <b>({payload.markupPrice.toFixed(2)} €)</b>
              </TableCell>
              <TableCell style={{ width: '5rem' }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="study-group-body">
            {data.blPriceScheduleLineGroup?.map((lineGroup: any, index) => {
              const isWarning = isPriceScheduleWarning(lineGroup, data);
              return [
                <TableRow style={{ backgroundColor: index % 2 === 1 ? mainTheme.rowOdd : mainTheme.rowEven }}>
                  <TableCell>
                    <div className="flex-center">
                      <CmsIcon
                        icon={subSubRowHandler[lineGroup.id] ? 'down' : 'right'}
                        onClick={() => handleSubSubRowToggle(lineGroup.id)}
                        style={{ margin: '-0.2rem' }}
                      />
                    </div>
                  </TableCell>
                  <TableCell>{lineGroup.quantity ?? 0}</TableCell>
                  <TableCell>
                    <CmsLink
                      target="_blank"
                      defaultStyle
                      href={`/castres/billing/referencestudygroup/${lineGroup.groupId}/edit`}
                      label={lineGroup.group.label}
                    />
                  </TableCell>
                  <TableCell>{lineGroup.comment}</TableCell>
                  <PriceCell
                    price={
                      (lineGroup.group?.variableRateCost ?? 0) * (lineGroup.quantity ?? 0) +
                      (lineGroup.group?.flatRateCost ?? 0)
                    }
                  />
                  <PriceCell
                    price={
                      (lineGroup.group?.variableRateMarkup ?? 0) * (lineGroup.quantity ?? 0) +
                      (lineGroup.group?.flatRateMarkup ?? 0)
                    }
                  />
                  <TableCell>
                    {isWarning && (
                      <CmsIcon
                        icon="warning"
                        style={{ color: 'yellow' }}
                        tooltip="Veuillez acquitter la ligne de bordereau"
                      />
                    )}
                  </TableCell>
                </TableRow>,
                !!subSubRowHandler[lineGroup.id] && (
                  <SubSubRowComponent group={lineGroup.group} isWarning={isWarning} />
                ),
              ];
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

function isPriceScheduleWarning(lineGroup: BlReferenceStudyLineGroup, data: BlPriceSchedule): boolean {
  if (lineGroup.group.lastWarningDate === null) return false;
  return new Date(lineGroup.group.lastWarningDate ?? 0) > new Date(data.newWarningAcknowledgmentDate ?? 0);
}

const PriceCell = ({ price }: { price: number }) => (
  <TableCell style={{ textAlign: 'right', paddingRight: '0.2rem' }}>{price?.toFixed(2) ?? 0} €</TableCell>
);
