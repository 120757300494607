import React, { FC, useEffect, useMemo, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsForm } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import Grid from '@mui/material/Grid';
import { FirstLevelProps, MultiLevelForm } from 'component/form/MultiLevelForm';
import { CmsPaper } from 'component/shared/Ui';
import { MultiLevelBoolean, MultiLevelInput, MultiLevelSelect } from 'component/form/MultiLevelInput';
import CRUD from 'service/CRUD.service';
import { WkReport, WkTask } from 'interface/WkType';
import NotificationService from 'service/NotificationService';
import { authenticationService } from 'service/Authentication.service';

export const WkReportCreateEdit: FC = ({ id }: any) => {
  const [userOptionList, setUserOptionList] = useState<any[]>([]);
  const [stationId, setStationId] = useState<number>();
  const [equipmentOptionList, setEquipmentOptionList] = useState<any[]>();
  const [formState, setFormState] = useState<any>({});

  useEffect(() => {
    CRUD.getList(APIRoute.UsUsers + '/Simplified?withDisabled=true').then(setUserOptionList);
  }, []);

  useEffect(() => {
    if (!!stationId)
      CRUD.getList(APIRoute.EqEquipment + '/ByStation/' + stationId + '/Simplified').then(setEquipmentOptionList);
  }, [stationId]);

  const config: FirstLevelProps = useMemo(() => {
    return {
      subListTitle: 'Liste des actions effectuées',
      attrToList: 'attrtolist',
      subItem: {
        subListTitle: '',
        postRoute: APIRoute.WkActionEquipment,
        attrToList: 'wkActionEquipment',
        parentIdName: 'taskId',
        columnList: [
          {
            id: 'diagnostic',
            label: 'Diagnostic avant intervention',
            FormComponent: MultiLevelInput,
            inputOption: { multilineToggle: true },
          },

          {
            id: 'description',
            label: "Description de l'action réalisée",
            FormComponent: MultiLevelInput,
            inputOption: { multilineToggle: true },
          },
          {
            id: 'equipmentId',
            label: 'Équipement',
            FormComponent: MultiLevelSelect,
            inputOption: { options: equipmentOptionList },
          },
          {
            id: 'domainId',
            label: 'Domaine',
            FormComponent: MultiLevelSelect,
            optionRoute: APIRoute.EqDomain + '/Simplified',
          },
          {
            id: 'natureId',
            label: 'Panne constatée',
            FormComponent: MultiLevelSelect,
            customOptionRoute: { route: APIRoute.EqNature + '/Simplified/', input: 'domainId' },
          },
          {
            id: 'actionId',
            label: 'Action réalisée',
            FormComponent: MultiLevelSelect,
            customOptionRoute: { route: APIRoute.EqAction + '/ByDomain/', input: 'domainId' },
          },
          {
            id: 'isFollowUpNecessary',
            label: 'Suite à donner',
            FormComponent: MultiLevelBoolean,
          },
          {
            id: 'followUp',
            label: 'Précisions suite à donner',
            FormComponent: MultiLevelInput,
            inputOption: { multilineToggle: true },
            disableIf: (row) => !row.isFollowUpNecessary,
          },
        ],
      },
    };
  }, [equipmentOptionList]);

  const onSubmitAll = (data: any) => {
    CRUD.put<WkTask>(APIRoute.WkTask + '/All', data).then(() => {
      NotificationService.success('Les modifications ont bien été enregistrées');
      window.location.reload();
    });
  };
  const user = useMemo(() => authenticationService.getCurrentUser(), []);
  return (
    <>
      <CmsForm
        id={id}
        title="un rapport"
        currentUrl={`/castres/work/task/${id}/report`}
        goBackTo={`/work/report/upload/${id}`}
        goBackUseHref={true}
        route={APIRoute.WkTask}
        paperStyle={{ maxWidth: '1200px' }}
        onGetEditData={(data: any) => {
          if (!data.workstart) data.workstart = data.startDate;
          return data;
        }}
        onSubmit={(data: any) => {
          data = {
            distance: !data.doneRemotely ? data.distance : 0,
            resolved: data.resolved,
            senderId: data.senderId,
            reportUserId: data.reportUserId,
            workstart: data.workstart,
            startDate: data.startDate,
            endDate: data.endDate,
            doneRemotely: data.doneRemotely,
            isFollowUpNecessary: data.isFollowUpNecessary,
            followUp: data.isFollowUpNecessary ? data.followUp : null,
            id: data.id,
            wkActionEquipment: formState.wkActionEquipment,
          };
          return CRUD.post<WkReport>(APIRoute.WkTask + '/report', data, true).then(() => {
            onSubmitAll(data);
          });
        }}
        renderForm={(form) => {
          if (form.getValues('stationId') !== stationId) setStationId(form.getValues('stationId'));
          return (
            <Grid container spacing={2}>
              <Grid item sm={12} lg={6}>
                <CmsFormInput.DateAndTime
                  id="startDate"
                  label="Date de départ"
                  required
                  onDateChange={() => {
                    form.trigger(['endDate', 'workstart']);
                  }}
                  rules={{
                    validate: (value, formvalue) =>
                      ((!formvalue.workstart || value <= formvalue.workstart) &&
                        (!formvalue.endDate || value <= formvalue.endDate)) ||
                      "Doit être inférieure à la date d'arrivée",
                  }}
                />
                <CmsFormInput.DateAndTime
                  id="workstart"
                  label="Date d'arrivée sur site"
                  onDateChange={() => {
                    form.trigger(['endDate', 'startDate']);
                  }}
                  required
                  rules={{
                    validate: (value, formvalue) =>
                      ((!formvalue.startDate || formvalue.startDate <= value) &&
                        (!formvalue.endDate || value <= formvalue.endDate)) ||
                      'Doit être entre la date de départ et date de fin',
                  }}
                />
                <CmsFormInput.DateAndTime
                  id="endDate"
                  label="Date de fin"
                  onDateChange={() => {
                    form.trigger(['workstart', 'startDate']);
                  }}
                  required
                  rules={{
                    validate: (value) => value <= Date.now() || 'Cette date est dans le futur',
                  }}
                />
                <CmsFormInput.Select
                  id="senderId"
                  label="Intervenant Principal"
                  options={userOptionList}
                  readOnlyIf={(dataForm: any) => !!dataForm.reportId}
                  required
                  updateFieldIf={(dataForm: any) => {
                    if (dataForm.senderId === 0 || dataForm.senderId === null)
                      return { update: true, value: user.userId };
                    else return { update: false, value: null };
                  }}
                  filterByActive={true}
                />
                <CmsFormInput.Select
                  id="reportUserId"
                  label="Autres intervenants"
                  options={userOptionList}
                  multiple={true}
                  rules={{
                    validate: (value: any[], formvalue) =>
                      !value.some((v) => v === formvalue.senderId) ||
                      "L'intervenant principal ne peut pas être présent ici",
                  }}
                  filterByActive={true}
                />
                <CmsFormInput.Switch
                  id="doneRemotely"
                  label="Fait à distance"
                  updateFieldIf={(dataForm: any) => {
                    if (dataForm.doneRemotely == null) return { update: true, value: true };
                    else return { update: false, value: null };
                  }}
                />
                <CmsFormInput.Number
                  id="distance"
                  label="Distance"
                  requiredIf={(data: any) => !data.doneRemotely}
                  hideIf={(data: any) => data.doneRemotely}
                />
                <CmsFormInput.Switch
                  id="resolved"
                  label="Intervention résolue"
                  updateFieldIf={(dataForm: any) => {
                    if (dataForm.doneRemotely == null) return { update: true, value: false };
                    else return { update: false, value: null };
                  }}
                />
                <CmsFormInput.Switch
                  id="isFollowUpNecessary"
                  label="Suite à donner"
                  updateFieldIf={(dataForm: any) => {
                    return {
                      update:
                        !dataForm.isFollowUpNecessary &&
                        formState?.wkActionEquipment?.some((x: any) => x.isFollowUpNecessary),
                      value: formState?.wkActionEquipment?.some((x: any) => x.isFollowUpNecessary),
                    };
                  }}
                />
                <CmsFormInput.Text
                  id="followUp"
                  label="Précisions suite à donner"
                  hideIf={(data) => !data.isFollowUpNecessary}
                  multiline={true}
                />
                <CmsFormInput.Number id="reportId" label="Id du rapport" hideIf={() => true} />
              </Grid>
            </Grid>
          );
        }}
      />
      <CmsPaper title="Actions réalisées">
        <MultiLevelForm
          id={id}
          getRoute={APIRoute.WkTask}
          config={config}
          onChange={setFormState}
          unitarySave={false}
        />
      </CmsPaper>
    </>
  );
};
