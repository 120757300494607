import React, { createContext, CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import CRUD from '../../service/CRUD.service';
import APIRoute from '../../constant/API.constant';
import LoadingScreen from '../LoadingScreen';
import NotificationService from '../../service/NotificationService';
import { Collapse } from '@mui/material';
import './form.scss';
import { UI } from '../shared';
import CmsIcon from '../shared/CmsIcon';
import { MultiLevelInputProps } from './MultiLevelInput';

/**
 * Contexte du formulaire multi-niveau
 * @param state Etat du formulaire
 * @param setState Fonction pour modifier l'état du formulaire
 * @param get Fonction pour récupérer les données du formulaire
 * @param reload Fonction pour recharger les données du formulaire
 * @param setOptionListFromSubItem Fonction pour récupérer les options des champs de menu déroulant
 * @param optionList Liste des options des champs de menu déroulant
 */
export const MultiLevelContext = createContext({
  state: {} as any,
  setState: (state: any) => {},
  modifiedState: {} as any,
  setModifiedState: (state: any) => {},
  get: (id: number, route: string) => {},
  reload: () => {},
  setOptionListFromSubItem: (subitem: LevelFormProps) => {},
  optionList: {} as any,
});

/**
 * Récupère toutes les routes des options des champs de menu déroulant récursivement
 * @param subitem Sous-élément
 * @param result Liste des routes
 */
function getAllOptionListRoute(subitem: LevelFormProps, result: string[] = []): string[] {
  for (let column of subitem.columnList)
    if (!!column.optionRoute && !result.find((x) => x === column.optionRoute)) result.push(column.optionRoute);
  return !!subitem.subItem ? getAllOptionListRoute(subitem.subItem, result) : result;
}

interface MultiLevelFormContextProviderProps {
  id: number;
  getRoute: string | APIRoute | ((id: number) => string);
  onGetData?: (data: any) => any;
  children: any;
  onChange?: any;
  data?: any;
}

/**
 * Contexte du formulaire multi-niveau
 * @param props on ne récupère que les enfants du composant
 * @constructor Création du contexte du formulaire multi-niveau
 */
export const MultiLevelFormContextProvider: FC<MultiLevelFormContextProviderProps> = (props) => {
  const { children, onChange, data, id, getRoute, onGetData } = props;
  const [state, setState] = useState<any>({});
  const [modifiedState, setModifiedState] = useState<any>({});
  const [optionList, setOptionList] = useState<any>({});

  useEffect(() => {
    onChange && onChange(modifiedState.item);
  }, [onChange, modifiedState.item]);

  useEffect(() => {
    if (!data || state.item === data) return;
    setState({ ...state, item: data, id, route: getRoute });
    setModifiedState({ ...modifiedState, item: data });
    CRUD.getMultiple(optionList.routeList).then((x) => setOptionList({ ...x, routeList: optionList.routeList }));
  }, [data, id, modifiedState, getRoute, state, optionList.routeList]);

  const get = (id: number, route: string) => {
    if (state.id) return;
    setState({ ...state, id, route, item: data });
    if (!data) getById(id, route);
  };

  const setOptionListFromSubItem = (subitem?: LevelFormProps) => {
    const routeList = subitem ? getAllOptionListRoute(subitem) : (optionList.routeList ?? []);
    CRUD.getMultiple(routeList).then((x) => setOptionList({ ...x, routeList }));
  };

  const reload = () => {
    NotificationService.success('Enregistrement effectué avec succès !');
    getById(state.id, state.route);
    setOptionListFromSubItem();
  };

  const getById = (id: number, route: string) => {
    CRUD.getCustomObject<any>(route).then((item) => {
      if (onGetData) item = onGetData(item);
      setState({ ...state, id, route, item });
      setModifiedState({ ...modifiedState, id, route, item });
      onChange && onChange(item);
    });
  };

  return (
    <MultiLevelContext.Provider
      value={{
        state,
        setState,
        modifiedState,
        setModifiedState,
        get,
        reload,
        setOptionListFromSubItem,
        optionList,
      }}
    >
      {children}
    </MultiLevelContext.Provider>
  );
};

/**
 * Configuration du formulaire multi-niveau
 * @param id Id de l'élément contenant les sous-éléments, exemple: id du type de rapport d'intervention
 * qui contient les sous-éléments ( Catégorie puis les lignes de rapport d'intervention)
 * @param getRoute Route pour récupérer l'élément contenant les sous-éléments
 * @param config Configuration du formulaire (voir interface FirstLevelProps)
 */
export interface MultiLevelFormProps {
  id: number;
  getRoute: string | APIRoute | ((id: number) => string);
  onGetData?: (data: any) => any;
  config: FirstLevelProps;
  onChange?: (data: any) => void;
  unitarySave?: boolean;
  // Uniquement si vous donnez vous même les données
  data?: any;
}

/**
 * Configuration du premier niveau du formulaire
 * @param subListTitle Titre afficher au-dessus de la liste suivante
 * @param attrToList Attribut de l'élément contenant les sous-éléments, exemple: 'wkReportCategoryList' pour le type de rapport d'intervention
 * @param subItem Configuration de la liste suivante
 * @param sortByColumn Permet de trier le subItem la columnList via l'id d'une des colonnes
 */
export interface FirstLevelProps {
  subListTitle?: string;
  attrToList: string;
  subItem?: LevelFormProps;
  sortByColumn?: string;
}

/**
 * Configuration d'un sous-élément
 * @param postRoute Route pour enregistrer l'élément, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param parentIdName Nom de l'attribut de l'élément parent, exemple: 'wkReportTypeId' pour la catégorie de rapport d'intervention
 * @param columnList Liste des colonnes du formulaire, contient les informations pour créer les champs / colonnes
 * @param level Niveau du formulaire, 1 pour le premier niveau, 2 pour le deuxième niveau, etc...
 * @param data Données de l'élément du formulaire
 */
interface LevelFormProps extends FirstLevelProps {
  // key: number;
  index?: number;
  postRoute?: string | APIRoute;
  deleteRoute?: string | APIRoute;
  parentIdName: string;
  columnList: ColumnProps[];
  level?: number;
  data?: any;
  rowStyle?: (data: any) => CSSProperties;
  onChildChange?: (data: any, index: any) => void;
  onChildDelete?: (index: number) => void;
  unitarySave?: boolean;
  openByDefault?: boolean;
  columnHideStatus?: boolean[];
  onBlur?: any;
}

/**
 * Configuration d'une colonne du formulaire
 * @param id Id du champ, exemple: 'label' pour le libellé
 * @param label Label du champ, exemple: 'Libellé'
 * @param FormComponent Composant react spécifique au multilevelform, vous ne devez utiliser que des composants commancant par 'MultiLevel'
 * @param optionRoute Route pour récupérer les options du champ, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param inputOption Options du champ, exemple: {options:[{id: 1, label: 'Libellé 1'}, {id: 2, label: 'Libellé 2'}]} si votre liste est statique
 * @param width Largeur de la colonne, exemple: 50 pour 50px, sert à figé la largeur de la colonne
 * @param hideIf Permet de cacher une column en se référant aux valeurs présentes dans le parent.
 * @param customOptionRoute Route pour récupérer les options du champ en fonction de données présentes dans un autre champ. Exemple : {input 'domainId', route: 'APIRoute.EqAction + /ByDomain/'}
 * @param defaultValue Valeur par défaut du champ
 * @param defaultValueRoutes Route(s) pour récupérer la valeur par défaut du champ en fonction de données présentes dans d'autre(s) champ(s). Exemple :[{ input: 'natureId', route: APIRoute.EqDomain + '/ByNature', attribute : 'id' }, { input: 'actionId', route: APIRoute.EqDomain + '/ByAction', attribute : 'id' }]
 */
interface ColumnProps {
  id: string;
  label: string;
  FormComponent: (payload: MultiLevelInputProps) => any;
  optionRoute?: string | APIRoute;
  inputOption?: {};
  inputOptionWhen?: (data: any) => {};
  disableIf?: (data: any) => boolean;
  hideIf?: (data: any) => boolean;
  width?: number;
  customOptionRoute?: { input: string; route: string };
  defaultValue?: any;
  defaultValueRoutes?: { input: string; route: string; attribute: string }[];
}

/**
 * Formulaire multi-niveau, encapsule le formulaire de chaque niveau avec le contexte
 * @param props Configuration du formulaire multi-niveau
 * @deprecated à partir de la version 2.13, utiliser le CmsDndTable à la place
 */
export const MultiLevelForm: FC<MultiLevelFormProps> = (props) => {
  return (
    <MultiLevelFormContextProvider {...props}>
      <FirstLevel {...props} />
    </MultiLevelFormContextProvider>
  );
};

/**
 * Premier niveau du formulaire multi-niveau
 * @param id l'id d'appel du formulaire (conjoint avec getRoute)
 * @param getRoute la route d'appel du formulaire (conjoint avec id)
 * @param config la configuration du formulaire
 * @param unitarySave si le formulaire doit peut être sauvegardé unitairement
 * @param openByDefault si toutes les lignes doivent être ouvertes par défaut
 */
const FirstLevel: FC<MultiLevelFormProps> = ({ id, getRoute, config, unitarySave }) => {
  const { state, get, setOptionListFromSubItem } = useContext(MultiLevelContext);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  if (isFirstRender) {
    get(id, typeof getRoute === 'function' ? getRoute(id) : `${getRoute}/${id}`);
    if (!!config.subItem) setOptionListFromSubItem(config.subItem);
    setIsFirstRender(false);
  }

  if (!state.item) return <LoadingScreen />;
  return (
    <LevelForm
      key="multi-level-form-root"
      index={0}
      {...config}
      level={1}
      data={state.item}
      parentIdName="no-use"
      postRoute="no-use"
      columnList={[]}
      unitarySave={unitarySave}
      openByDefault={false}
    />
  );
};

/**
 * Formulaire multi-niveau (niveau 2 et plus) composant récursif infini configurer par les "subItem"
 * @param key Clé de l'élément
 * @param index Index de l'élément
 * @param level Niveau du formulaire, 1 pour le premier niveau, 2 pour le deuxième niveau, etc...
 * @param subListTitle Titre afficher au-dessus de la liste suivante
 * @param postRoute Route pour enregistrer l'élément, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param columnList Liste des colonnes du formulaire, contient les informations pour créer les champs / colonnes
 * @param subItem Configuration de la liste suivante
 * @param data Données de l'élément du formulaire
 * @param rowStyle Style de la ligne
 * @param deleteRoute Route pour supprimer l'élément, exemple: 'api/wkreporttype' pour le type de rapport d'intervention
 * @param onChildChange Fonction pour modifier les données de l'élément parent
 * @param unitarySave si le formulaire doit peut être sauvegardé unitairement
 * @param openByDefault si toutes les lignes doivent être ouvertes par défaut
 * @param columnHideStatus Liste des colonnes à cacher
 * @param onBlur Fonction à appeler lorsque l'on quitte une ligne
 */
const LevelForm: FC<LevelFormProps> = ({
  index,
  level,
  subListTitle,
  postRoute,
  columnList,
  subItem,
  data,
  rowStyle = () => ({}),
  deleteRoute,
  onChildChange,
  onChildDelete,
  unitarySave = true,
  openByDefault = false,
  columnHideStatus,
  onBlur,
}: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [optionCustom, setOptionCustom] = useState<{ [key: string]: any }>({});
  const [defaultValuesFromRoute, setDefaultValuesFromRoute] = useState<{ [key: string]: any }>();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [dataState, setDataState] = useState<any>(data);
  const { reload, setModifiedState, modifiedState } = useContext(MultiLevelContext);
  const haveSubItem = !!subItem && !!dataState[subItem.attrToList];
  const modified = dataState.multiFormModified;
  if (data.isCollapseOpen === undefined) data.isCollapseOpen = openByDefault;

  const setOptionListCustom = (column: ColumnProps) => {
    let columnName = column.customOptionRoute!.input;

    let columnValue = dataState[columnName];
    if (!columnValue) return;
    if ((!optionCustom[column.id] || columnValue !== optionCustom[column.id]?.inputValue) && !isLoading) {
      setIsLoading(true);
      CRUD.getList(column.customOptionRoute!.route + columnValue).then((result) => {
        setOptionCustom((prevState) => ({
          ...prevState,
          [column.id]: { list: result, inputValue: columnValue },
        }));
        setIsLoading(false);
      });
    }
  };

  const getDefaultValuesFromRoute = (column: ColumnProps) => {
    const firstValidValue = column.defaultValueRoutes!.find(({ input }) => {
      const columnValue = dataState[input];
      return columnValue !== null && columnValue !== undefined;
    });
    const columnValue = dataState[firstValidValue?.input ?? ''];
    if (!columnValue) return;
    CRUD.getById(firstValidValue!.route, columnValue).then((result: any) => {
      setDefaultValuesFromRoute((prevState) => ({
        ...prevState,
        [column.id]: result[firstValidValue!.attribute],
      }));
    });
  };

  const checkHideSubItemStatus = useMemo(() => {
    if (!subItem) return undefined;
    return subItem?.columnList?.map((column: any) => column.hideIf && column.hideIf(dataState));
  }, [subItem, dataState]);

  const sortDataState = () => {
    if (!!subItem?.sortByColumn && !!data) {
      data[subItem.attrToList]?.sort((a: any, b: any) => (a[subItem.sortByColumn] >= b[subItem.sortByColumn] ? 1 : -1));
      setDataState(data);
      onChildChange && onChildChange({ ...dataState }, index);
    }
  };

  if (firstRender) {
    sortDataState();
    setFirstRender(false);
  }

  const handleCollapse = () => {
    data.isCollapseOpen = !data.isCollapseOpen;
    setDataState(data);
    onChildChange && onChildChange({ ...dataState }, index);
  };

  const fullReadOnly = useMemo(() => {
    return columnList.every((x: any) => x.disableIf && x.disableIf(dataState));
  }, [columnList, dataState]);

  const handleSubmit = () =>
    CRUD.post(postRoute, dataState, !!dataState.id).then(() => {
      reload();
    });
  const handleDelete = (index: number) => {
    onChildDelete(index);
  };

  const onChildDeleteHandler = (childIndex: number) => {
    dataState[subItem!.attrToList] = dataState[subItem!.attrToList].filter(
      (_: any, index: number) => index !== childIndex,
    );
    setDataState({ ...dataState });
    if (onChildChange) onChildChange({ ...dataState }, index);
    else setModifiedState({ ...modifiedState, item: { ...dataState } });
  };
  useEffect(() => {
    setDataState(data);
  }, [data]);
  const handleUpdate = (value: any, column: any, index: number) => {
    const payload = { ...dataState, [column.id]: value, multiFormModified: true };
    setDataState(payload);
    onChildChange && onChildChange(payload, index);
  };

  const onChildChangeHandler = (child: any, index: number) => {
    dataState[subItem!.attrToList][index] = child;
    setDataState({ ...dataState });
    if (onChildChange) onChildChange({ ...dataState }, index);
    else setModifiedState({ ...modifiedState, item: { ...dataState } });
  };

  const handleCreate = () => {
    if (!subItem) return NotificationService.error("La sous-liste n'existe pas");
    const list = dataState[subItem.attrToList];
    if (!list) return NotificationService.error("La liste n'existe pas");
    list.push({ [subItem.parentIdName]: data.id });
    setDataState({ ...dataState, [subItem.attrToList]: list });
  };
  const backgroundColor = index % 2 === 0 ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)';
  const gridStyle = getGrid({ subItem, columnList, deleteRoute } as any, unitarySave, fullReadOnly, columnHideStatus);

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // Vérifier si le focus est toujours dans la ligne ou sa collapse
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onBlur && onBlur();
    }
  };
  const formComp: React.JSX.Element = (
    <div className="form-list" style={{ ...gridStyle, backgroundColor, ...rowStyle(dataState) }}>
      {columnList.map((column: any, index: number) => {
        // Ajouter une condition pour ne pas afficher l'élément si `hide` est vrai
        if (!!columnHideStatus && columnHideStatus[index]) return null; // Ne pas rendre cet élément
        if (!!column.customOptionRoute) setOptionListCustom(column);
        let inputOption = { ...column.inputOption, disabled: column.disableIf && column.disableIf(dataState) };
        if (!!optionCustom[column.id]) inputOption = { ...inputOption, options: optionCustom[column.id].list };
        if (column.defaultValue && !dataState[column.id]) dataState[column.id] = column.defaultValue;
        if (!defaultValuesFromRoute?.[column.id] && !!column.defaultValueRoutes) getDefaultValuesFromRoute(column);
        if (!!defaultValuesFromRoute?.[column.id] && !dataState[column.id])
          dataState[column.id] = defaultValuesFromRoute[column.id];
        if (column.inputOptionWhen) inputOption = { ...inputOption, ...column.inputOptionWhen(dataState) };
        return (
          <div className={'form-cell grid cell-' + index} key={index}>
            {column.FormComponent({
              value: dataState[column.id],
              setValue: (value: any) => handleUpdate(value, column, index),
              optionRoute: column.optionRoute,
              row: dataState,
              inputOption,
            })}
          </div>
        );
      })}
      <div className="form-cell action-cell">
        <div className="flex-h end actions">
          <div className="action-holder">
            {haveSubItem && <UI.DeployIcon size="small" value={data.isCollapseOpen} onchange={handleCollapse} />}
            {unitarySave && <CmsIcon disabled={!modified} icon="save" tooltip="Sauvegarder" onClick={handleSubmit} />}
            {!fullReadOnly && <CmsIcon icon="delete" tooltip="Supprimer" onClick={() => handleDelete(index)} />}
          </div>
        </div>
      </div>
    </div>
  );

  const abstractComp = (): React.JSX.Element => {
    if (!haveSubItem || !subItem) return <></>;
    const subList: any[] = dataState[subItem.attrToList];
    const isAddDisabled = !subList;
    const width = subItem.columnList.reduce((acc: number, x: any) => (acc += x.width ?? 100), 0) + 120;
    return (
      <div style={{ minWidth: width + 'px' }}>
        <div className="add-title">
          <h3>{subListTitle ?? 'Liste des sous-éléments'}</h3>
          {subItem?.postRoute && (
            <div className="circle">
              <CmsIcon icon="add" tooltip="Créer" onClick={handleCreate} disabled={isAddDisabled} />
            </div>
          )}
        </div>
        <HeaderList subItem={subItem} columnHideStatus={checkHideSubItemStatus} unitarySave={unitarySave} />
        {dataState[subItem.attrToList]
          .filter((x: any) => !x.hideRow)
          .map((row: any, index: number) => (
            <LevelForm
              key={index}
              {...subItem}
              data={row}
              level={(level ?? 0) + 1}
              onChildChange={(x: any) => onChildChangeHandler(x, index)}
              onChildDelete={() => onChildDeleteHandler(index)}
              openByDefault={!!subItem.openByDefault}
              {...{ unitarySave, index }}
              columnHideStatus={checkHideSubItemStatus}
              onBlur={sortDataState}
            />
          ))}
      </div>
    );
  };

  return (
    <div
      className={'level-form styled-h-scroll level' + level + (data.isCollapseOpen ? ' open' : '')}
      onBlur={handleBlur}
    >
      {level !== 1 && formComp}
      {(haveSubItem && level !== 1 && (
        <Collapse in={data.isCollapseOpen} timeout="auto" unmountOnExit>
          {abstractComp()}
        </Collapse>
      )) ||
        abstractComp()}
    </div>
  );
};

function getGrid(
  item?: LevelFormProps,
  unitarySave?: boolean,
  readOnly?: boolean,
  hideStatus?: boolean[],
): CSSProperties {
  // On ne prend en compte que les colonnes non cachées dans la grille
  const columns = item?.columnList ?? [];
  const filteredColumns = hideStatus ? columns.filter((_, index) => !hideStatus[index]) : columns;
  let col = filteredColumns.reduce((acc, x) => (acc += x.width ? `${x.width}px ` : 'minmax(150px, 1fr) '), '') ?? '';
  let nbActions = +!!item?.subItem + +!readOnly + +!!unitarySave;
  col += nbActions * 30 + 10 + 'px';
  return { gridTemplateColumns: col };
}

/**
 * En-tête de la liste des sous-éléments
 * @param columnList Liste des colonnes du formulaire, contient les informations pour créer les champs / colonnes
 * @param colGridStyle Style de la grille des colonnes
 */
const HeaderList: FC<{ subItem: LevelFormProps; columnHideStatus: boolean[]; unitarySave: boolean }> = ({
  subItem,
  columnHideStatus,
  unitarySave,
}) => {
  const colGrid = getGrid(subItem, unitarySave, false, columnHideStatus);
  return (
    <div className="form-list header" style={colGrid}>
      {subItem.columnList.map((column, index) => {
        if (!!columnHideStatus && columnHideStatus[index]) return null;
        return (
          <div key={index}>
            <UI.TextEllipse text={column.label} style={{ fontWeight: 'bold' }} />
          </div>
        );
      })}
      <div></div>
    </div>
  );
};
