import React, { FC, ReactNode } from 'react';
import { CmsButton } from './Ui';
import { Menu, MenuItem } from '@mui/material';

interface CmsMenuButtonProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  preventClose?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: 'text' | 'outlined' | 'contained';
  buttonStyle?: React.CSSProperties;
  overLoadButton?: (data: {
    variant: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    buttonStyle?: React.CSSProperties;
    className?: string;
  }) => ReactNode;
}

/**
 * Composant permettant de gérer les boutons de menu déroulant, surtout utilisé pour packager les boutons de navigation
 * @param title Titre du bouton du menu
 * @param children Contenu du menu
 * @param preventClose Empêche la fermeture du menu après un clic
 * @param className Classe du bouton
 * @param disabled Désactive le bouton
 * @param variant Type de bouton
 * @param buttonStyle Style du bouton
 * @param overLoadButton Fonction permettant de surcharger le bouton
 */
export const CmsMenuButton: FC<CmsMenuButtonProps> = ({
  title,
  children,
  preventClose,
  className,
  disabled,
  variant = 'outlined',
  buttonStyle,
  overLoadButton,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = () => (preventClose ? null : setAnchorEl(null));
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const button = overLoadButton ? (
    overLoadButton({ variant, disabled, handleClick, buttonStyle, className })
  ) : (
    <CmsButton variant={variant} disabled={disabled} onClick={handleClick} style={buttonStyle} className={className}>
      {title}
    </CmsButton>
  );
  return [
    button,
    <Menu anchorEl={anchorEl} onClick={handleMenuClick} open={open} onClose={() => setAnchorEl(null)}>
      {React.Children.toArray(children).map((child: any, index: number) => (
        <MenuItem key={index}>{child}</MenuItem>
      ))}
    </Menu>,
  ];
};
