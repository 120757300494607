import { DebouncedInput, FormButtonValidation, HeaderFormPanel } from './FormUi';
import {
  ButtonValidation,
  CmsButtonGroup,
  CMSCalendar,
  CmsRadio,
  CmsSwitch,
  CMSTextField,
  CMSTimePicker,
  CustomSuggest,
  DatePickerVanilla,
  DatePickerWithLabel,
  InputFile,
  MenuWithCheckBox,
  SimpleSelect,
} from './InputUi';
import {
  BoolIcon,
  BoolIconLabelValue,
  ButtonHolder,
  CheckboxCustom,
  ChipInfo,
  CmsButton,
  CMSChip,
  CmsDialog,
  CmsDivider,
  CmsLink,
  CmsPaper,
  ColorDateLimit,
  DeployIcon,
  ErrorPaper,
  Form,
  FormField,
  HeaderPanel,
  InfoPaper,
  IsVisibleIcon,
  Label,
  LabelBlockValue,
  LabelValueUI,
  LabelWithError,
  MailLink,
  MenuItemCheckBox,
  MenuItemClick,
  MenuList,
  Multiline,
  OpenInNewIconButton,
  PageTitle,
  PaperLoaderScreen,
  PhoneLink,
  Pop,
  SectionTitle,
  SelectBetweenButton,
  SquareChip,
  SubMenuItem,
  TextEllipse,
  Waiting,
  WarningBubble,
} from './Ui';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  ButtonWithAccess,
  CancelButton,
  DefaultButton,
  DeleteButton,
  EditNavigationButton,
  NavigationButton,
  NavigationButtonProps,
  OpenInNewNavigationButton,
  ShowHideIcon,
  ShowHideIconProps,
  ValidButton,
  ViewNavigationButton,
} from './Buttons';
import { TabStyleDataView } from './TabStyleDataView';
import { FC } from 'react';
import { AutoCompletor } from './AutoCompletorWithTable';

// Objet contenant tout les inputs de type "synchrone", le but est de regrouper par famille les composants
export const InputUI = {
  CmsSwitch,
  AutoCompletor,
  SimpleSelect,
  CMSCalendar,
  DatePickerVanilla,
  DatePickerWithLabel,
  CustomSuggest,
  InputFile,
  CMSTimePicker,
  DebouncedInput,
  CMSTextField,
  CmsRadio,
  ButtonValidation,
  CmsButtonGroup,
  MenuWithCheckBox,
};

// Objet regroupant les composants d'affichage de données divers.
export const UI = {
  SelectBetweenButton,
  WarningBubble,
  ColorDateLimit,
  OpenInNewIconButton,
  DeployIcon,
  HeaderPanel,
  Button: CmsButton,
  InfoPaper,
  ErrorPaper,
  Dialog: CmsDialog,
  SectionTitle,
  PageTitle,
  CMSChip,
  ChipInfo,
  BoolIcon,
  BoolIconLabelValue,
  LabelBlockValue,
  LabelValue: LabelValueUI,
  Divider: CmsDivider,
  Paper: CmsPaper,
  Form,
  TextEllipse,
  SquareChip,
  Pop,
  TabStyleDataView,
  MenuList,
  SubMenuItem,
  MenuItemCheckBox,
  CheckboxCustom,
  MenuItemClick,
  PaperLoaderScreen,
  IsVisibleIcon,
  CmsLink,
  Waiting,
  FormField,
  LabelWithError,
  Label,
  PhoneLink,
  MailLink,
  Multiline,
  ButtonHolder,
};

// Objet regroupant tous les composants de formulaire
export const FormUI = {
  FormField,
  FormButtonValidation,
  HeaderFormPanel,
};

interface ButtonsProps {
  // Sous-objet regroupant les boutons de navigation
  Nav: NavButtonsProps;
  // Boutons de validation
  Valid: FC<ButtonWithAccess>;
  // Boutons de suppression
  Delete: FC<ButtonWithAccess>;
  // Boutons par défaut
  Default: FC<ButtonWithAccess>;
  // Boutons d'annulation
  Cancel: FC<ButtonWithAccess>;
  // Boutons d'icônes vue/masquer avec un état (animé)
  ShowHideIcon: FC<ShowHideIconProps>;
}

interface NavButtonsProps {
  // Bouton de navigation par défaut
  Button: FC<NavigationButtonProps>;
  // Bouton de navigation "Retour"
  ArrowBack: FC<NavigationButtonProps>;
  // Bouton de navigation "Ouvrir dans un nouvel onglet"
  OpenInNew: FC<NavigationButtonProps>;
  // Bouton de navigation "Editer"
  Edit: FC<NavigationButtonProps>;
  // Bouton de navigation "Voir"
  View: FC<NavigationButtonProps>;
  // Bouton de navigation "Ajouter"
  Add: FC<NavigationButtonProps>;
}

// Objet regroupant tous les composants de boutons
export const Buttons: ButtonsProps = {
  Nav: {
    Button: NavigationButton,
    ArrowBack: ArrowBackNavigationButton,
    OpenInNew: OpenInNewNavigationButton,
    Edit: EditNavigationButton,
    View: ViewNavigationButton,
    Add: AddNavigationButton,
  },
  Valid: ValidButton,
  Delete: DeleteButton,
  Default: DefaultButton,
  Cancel: CancelButton,
  ShowHideIcon,
};
