import { authenticationService } from '../service/Authentication.service';
import { checkStatus } from './handle-response';
import { saveAs } from 'file-saver';
import NotificationService from '../service/NotificationService';

interface AuthorisedHeaderInterface {
  Authorization: string;
}

/**
 * Renvoie un objet contenant l'entête d'autorisation avec le token
 */
export function getHeaderWithToken(): AuthorisedHeaderInterface {
  const bearer = authenticationService.getCurrentUser()?.accessToken;
  return { Authorization: 'Bearer ' + bearer };
}

/**
 * l'API interprète directement la norme ISO 8601, j'enlève juste les millisecondes qui ne sont pas utiles pour nous
 * la transmission des : dans l'URL ne semble pas poser de problème, mais je les échappe quand même comme c'est un caractère réservé
 * Z = heure UTC (supprimé par le slice)
 * @param date Date à formater
 */
export function TranslateDateForURL(date: Date) {
  return date.toISOString().slice(0, -5).replace(/:/g, '%3A') + 'Z';
}

interface FetchProps {
  // L'url de la requête
  url: string;
  // La méthode de la requête
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
  // Les entêtes de la requête
  headers?: object;
  // Le corps de la requête
  body?: any;
}

/**
 * Effectue une requête avec l'entête d'autorisation automatiquement ajoutée
 * @param url L'url de la requête
 * @param method La méthode de la requête
 * @param headers Les entêtes de la requête
 * @param body Le corps de la requête
 */
export const fetchWithAuth = ({ url, method = 'GET', headers = {}, body = undefined }: FetchProps) => {
  const finalHeaders: Headers = new Headers({ ...headers, ...getHeaderWithToken() });
  return fetch(url, {
    method,
    headers: finalHeaders,
    body,
  }).then(checkStatus);
};

interface uploadFileProps {
  // L'url de la requête
  url: string;
  // Le fichier à envoyer
  file: File;
}

/**
 * Envoie un fichier à l'API
 * @param url L'url de la requête
 * @param file Le fichier à envoyer (type File)
 */
export const uploadFile = ({ url, file }: uploadFileProps) => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  const finalHeaders: Headers = new Headers({ ...getHeaderWithToken() });
  return fetch(url, {
    method: 'POST',
    headers: finalHeaders,
    body: formData,
  }).then(checkStatus);
};

interface FetchBlobProps extends FetchProps {
  // Si getFile est à true, la fonction renvoie le blob au lieu de l'enregistrer
  getFile?: boolean;
  // Le nom du fichier à enregistrer
  filename: string;
}

/**
 * Récupère un fichier PDF et l'enregistre
 * @param body Le corps de la requête
 * @param filename Le nom du fichier à enregistrer
 * @param getFile Si getFile est à true, la fonction renvoie le blob au lieu de l'enregistrer
 * @param headers Les entêtes de la requête
 * @param method La méthode de la requête
 * @param url L'url de la requête
 */
export const fetchPDF = ({
  body = undefined,
  filename = 'cms-doc.pdf',
  getFile = false,
  headers = {},
  method = 'GET',
  url,
}: FetchBlobProps) => {
  const finalHeaders: Headers = new Headers({
    ...headers,
    ...getHeaderWithToken(),
  });
  if (!filename.match('[a-zA-Z0-9]+.pdf')) filename += '.pdf';
  return fetch(url, {
    method,
    headers: finalHeaders,
    body,
  }).then((result: any) => {
    if (result.status === 404) {
      NotificationService.error('Le fichier PDF que vous souhaitez télécharger semble indisponible');
      return;
    }

    result.blob().then((blob: Blob) => {
      if (getFile) return blob;
      saveAs(blob, filename);
    });
  });
};
