import React, { FC, useState } from 'react';
import { CmsPaper } from '../../../component/shared/Ui';
import { Buttons, UI } from '../../../component/shared';
import { FirstLevelProps, MultiLevelForm } from '../../../component/form/MultiLevelForm';
import APIRoute from '../../../constant/API.constant';
import {
  MultiLevelDate,
  MultiLevelInput,
  MultiLevelSelect,
  MultiLevelVanilla,
} from '../../../component/form/MultiLevelInput';
import CRUD from '../../../service/CRUD.service';
import { BlPriceScheduleGroup } from '../../../interface/BlType';
import notificationService from '../../../service/NotificationService';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';

export const ContractInterventionCreation: FC<any> = ({ id }) => {
  const [formState, setFormState] = useState<any>({});
  const navigate = useNavigate();

  const onSubmitAll = () => {
    CRUD.put<BlPriceScheduleGroup>(APIRoute.BlContract + '/All', formState).then(() => {
      notificationService.success('Les modifications ont bien été enregistrées');
      navigate(`/castres/billing/contract/${id}/show`);
    });
  };
  if (!id) return <></>;
  return (
    <CmsPaper
      title="Création des interventions"
      actions={[<Buttons.Valid onClick={onSubmitAll}>Sauvegarder toutes les modifications</Buttons.Valid>]}
    >
      <MultiLevelForm
        id={id}
        onGetData={(data) => {
          if (!data?.years) return data;
          for (let d of data.years) {
            for (let x of d?.campaigns ?? []) {
              x.minDeadlineDate = new Date(d.year + '-01-01');
              x.maxDeadlineDate = new Date(d.year + '-12-31');
              x.action = 'update'; // TODO Yves mettre l'attr que tu a besoin et les valeurs par défaut
            }
          }
          return data;
        }}
        unitarySave={false}
        getRoute={(id) => `${APIRoute.BlContract}/${id}/GenerateIntervention`}
        config={config}
        onChange={setFormState}
      />
    </CmsPaper>
  );
};

const config: FirstLevelProps = {
  subListTitle: 'Création des interventions et devis',
  attrToList: 'years',
  subItem: {
    subListTitle: 'Liste des campagnes',
    attrToList: 'years',
    parentIdName: 'contractId',
    openByDefault: true,
    columnList: [{ id: 'year', label: 'Année', FormComponent: MultiLevelInput, disableIf: () => true }],
    subItem: {
      subListTitle: 'Par campagnes',
      attrToList: 'campaigns',
      parentIdName: 'year',
      columnList: [
        {
          id: 'action', // TODO Yves mettre l'attr que tu a besoin
          label: 'Action',
          FormComponent: MultiLevelSelect,
          inputOption: {
            defaultValue: 0,
            options: [
              { id: 'update', label: 'Remplacer' }, // TODO Yves mettre les options que tu veux
              { id: 'delete', label: 'Supprimer' },
            ],
          },
        },
        {
          id: 'interventions',
          label: 'Campagne',
          FormComponent: (x) => (
            <MultiLevelVanilla row={x.row} index={x.index}>
              {Array.isArray(x.value) &&
                x.value.map((y: any) => (
                  <Link style={{ display: 'block' }} href={`/castres/billing/contract/${y.id}/show`}>
                    <UI.TextEllipse text={y.name} />
                  </Link>
                ))}
            </MultiLevelVanilla>
          ),
          disableIf: () => true,
          inputOption: { multilineToggle: true },
        },
        {
          id: 'interventionDescription',
          label: 'Équipements liés',
          FormComponent: MultiLevelInput,
          disableIf: () => true,
          inputOption: { multilineToggle: true },
        },
        {
          id: 'deadline',
          label: 'DeadLine',
          FormComponent: MultiLevelDate,
          inputOptionWhen: (row) => ({ minDate: row.minDeadlineDate, maxDate: row.maxDeadlineDate }),
        },
      ],
    },
  },
};
