import React, { FC, useContext } from 'react';
import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { GlobalContext } from '../context/Global.context';
import { ToastContainer } from 'react-toastify';

export const CmsTheme: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theming } = useContext(GlobalContext);
  return (
    <ThemeProvider theme={theming.get()}>
      <CssBaseline />
      <ToastContainer
        position="bottom-right"
        autoClose={12000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={10}
        theme={theming.get().palette.mode === 'dark' ? 'dark' : 'light'}
      />
      {children}
    </ThemeProvider>
  );
};

export interface CmsGlobalThemeProps extends Theme {
  cms: CmsCustomThemeProps;
}

interface CmsCustomThemeProps {
  default: any;
  color: any;
  main: any;
  severity: any;
  container: any;
  notif: any;
  scheduler: any;
  habilitation: any;
}

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#61daff',
    },
    background: {
      default: '#282728',
    },
    secondary: {
      main: '#ff8661',
    },
    text: {
      primary: '#DDDDDD',
      secondary: '#CCCCCC',
    },
  },
  cms: {
    default: '#DDDDDD',
    color: {
      blue: '#2196f3',
      red: '#f44336',
      orange: '#ff9800',
      green: '#4caf50',
      purple: '#650eb1',
      grey: '#727272',
    },
    main: {
      paper: '#46454a',
      paperDif: '#5f5e64',
      navClosed: '#37384a',
      navOpened: '#46454a',
      background: '#282728',
      header: '#282c34',
      subHeader: '#3b4f60',
      subSubHeader: '#20465b',
      rowOdd: '#59585C',
      rowEven: '#46454a',
      sectionTitle: '#6f6f6f',
      skeleton: '#59585C',
      cardHeader: '#2a292a',
    },
    severity: {
      error: '#f44336',
      errorDark: '#7b1212',
      valid: '#BADA55',
    },
    container: {
      primary: '#858585',
      secondary: '#646464',
    },
    notif: {
      background: '#46454a',
      status: {
        info: '#5DADE2',
        success: '#28B463',
        warning: '#c39c10',
        emergency: '#e72a16',
        staff: '#A569BD',
        admin: '#d72ef1',
      },
    },
    scheduler: {
      caption: {
        absence: '#5a0fc6',
        habilitation: '#b39231',
        assignment: '#eb7b1e',
        task: '#0b92c3',
      },
    },
    habilitation: {
      status: {
        text: '#d4d3d3',
        textDark: '#373737',
        planned: '#9811d7',
        inProgress: '#03ba36',
        waitingCertificat: '#930057',
        pendingStartDate: '#d5390d',
        waitingValidationEvents: '#d5390d',
        pendingStartValidity: '#dda70f',
        validity: '#3077e0',
        failed: '#c20b0b',
        forecast: '#d90382',
        noValidatedFormation: '#c2240b',
        expiredWithoutRenewal: '#8c00a5',
        expired: '#c2240b',
        valid: '#0b5cdb',
      },
    },
  },
} as any);

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0b4883',
    },
    background: {
      default: '#FFF9E8',
    },
    secondary: {
      main: '#B70404',
    },
    text: {
      primary: '#222222',
      secondary: '#444444',
    },
  },
  cms: {
    default: '#222222',
    color: {
      blue: '#2196f3',
      red: '#f44336',
      orange: '#ff9800',
      green: '#4caf50',
      purple: '#650eb1',
      grey: '#727272',
    },
    main: {
      paper: '#FFF9E8',
      paperDif: '#E9DECA',
      navClosed: '#F2E3D0',
      navOpened: '#d9c7b0',
      background: '#EBDEC9',
      header: '#E6E1C8',
      subHeader: '#8fc4ee',
      subSubHeader: '#4db0ee',
      rowOdd: '#F3EDD4',
      rowEven: '#FFF9E8',
      sectionTitle: '#E6E1C8',
      skeleton: '#E6E1C8',
      cardHeader: '#E6E1C8',
    },
    severity: {
      error: '#f44336',
      errorDark: '#7b1212',
      valid: '#00730f',
    },
    container: {
      primary: '#afafaf',
      secondary: '#e9e9e9',
    },
    notif: {
      background: '#F5F5F5',
      status: {
        info: '#5DADE2',
        success: '#28B463',
        warning: '#c39c10',
        emergency: '#e72a16',
        staff: '#A569BD',
        admin: '#d72ef1',
      },
    },
    scheduler: {
      caption: {
        absence: '#5a0fc6',
        habilitation: '#b39231',
        assignment: '#eb7b1e',
        task: '#0b92c3',
      },
    },
    habilitation: {
      status: {
        text: '#d4d3d3',
        textDark: '#373737',
        planned: '#9811d7',
        inProgress: '#03ba36',
        waitingCertificat: '#930057',
        pendingStartDate: '#d5390d',
        waitingValidationEvents: '#d5390d',
        pendingStartValidity: '#dda70f',
        validity: '#3077e0',
        failed: '#c20b0b',
        forecast: '#d90382',
        noValidatedFormation: '#c2240b',
        expiredWithoutRenewal: '#8c00a5',
        expired: '#c2240b',
        valid: '#0b5cdb',
      },
    },
  },
} as any);
