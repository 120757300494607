import React from 'react';
import CameraIcon from '@mui/icons-material/Camera';
import { NavProps } from './index';
import ROLE from '../../../constant/role.constant';

export const Activity: NavProps = {
  name: 'Activités',
  icon: <CameraIcon />,
  subList: [
    {
      name: 'Interventions',
      link: '/castres/work/intervention/list',
      feature: ROLE.ADMIN_WORK_INTERVENTION_LIST,
    },
    {
      name: "Notes d'intervention",
      link: '/castres/work/intervention/note/list',
      feature: ROLE.ADMIN_WORK_INTERVENTION_LIST,
    },
    {
      name: "Résultats d'intervention",
      link: '/castres/work/intervention/result',
      feature: ROLE.ADMIN_WORK_INTERVENTION_EXPORT,
    },
    {
      name: 'Tâches',
      link: '/castres/work/task/list',
      feature: ROLE.ADMIN_WORK_TASK_LIST,
    },
    {
      name: "Feuille d'heure",
      link: '/castres/timereporting',
      // Lien pour les Techniciens, le lien existe également dans le menu RH mais réservé au RH
      feature: ROLE.ADMIN_TIMEREPORTINGWEEK_CREATE,
    },
    {
      name: "Demande d'absence",
      link: '/castres/staff/absence/create',
      feature: ROLE.ADMIN_STAFF_ABSENCE_CREATE,
    },
    {
      name: "Rapports D'intervention",
      linkV1: 'castres/work/report/list',
      feature: ROLE.ADMIN_WORK_REPORT_LIST,
    },
    {
      name: 'Vprims',
      linkV1: 'castres/work/vprim/list',
      feature: ROLE.ADMIN_WORK_VPRIM_LIST,
    },
    {
      name: 'Planning',
      subList: [
        {
          name: 'Interventions',
          linkV1: 'schedule/intervention',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
        {
          name: 'Équipes',
          linkV1: 'schedule/team',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
        {
          name: 'Techniciens',
          linkV1: 'schedule/user',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
        {
          name: 'Véhicules',
          linkV1: 'schedule/vehicle',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
        {
          name: 'Cartes',
          link: '/schedule/map',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
        {
          name: 'Absences',
          linkV1: 'schedule/timeoff',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
        {
          name: 'Travaux',
          linkV1: 'schedule/travail',
          feature: [
            ROLE.ADMIN_WORK_INTERVENTION_LIST,
            ROLE.ADMIN_WORK_TASK_LIST,
            ROLE.ADMIN_WORK_REPORT_LIST,
            ROLE.ADMIN_WORK_VPRIM_LIST,
          ],
        },
      ],
    },
    {
      name: 'Véhicules',
      linkV1: 'castres/geo/vehicle/list',
      feature: [ROLE.ADMIN_GEO_VEHICLE_LIST],
    },
    {
      name: 'Map',
      linkV1: 'map/',
      feature: [
        ROLE.ADMIN_WORK_INTERVENTION_LIST,
        ROLE.ADMIN_WORK_TASK_LIST,
        ROLE.ADMIN_WORK_REPORT_LIST,
        ROLE.ADMIN_WORK_VPRIM_LIST,
      ],
    },
  ],
};
