import { Column, OnChangeFn, PaginationState, SortingState } from '@tanstack/react-table';
import React, { Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Table as TansTable } from '@tanstack/table-core/build/lib/types';
import { GlobalContext } from '../../../context/Global.context';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Buttons, InputUI } from '../../shared';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { Autocomplete, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import CRUD from '../../../service/CRUD.service';
import Utils from '../../../helper/Utils';
import CmsIcon from '../../shared/CmsIcon';
import { CmsColumnDef } from '../CmsTable';
import NotificationService from '../../../service/NotificationService';
import { GetFilterValueFromURL } from '../../../helper/UrlDataTableHandler';
import { RTColumn } from '../../../interface/RTColumn';
import { Sync } from '@mui/icons-material';
import AccessFilter from '../../../helper/AccessFilter';
import { DialogUI, DialogVanillaUI } from '../../shared/DialogUI';
import { CmsDivider, CmsPaper } from '../../shared/Ui';

//#region TableFilterHeader

// Ration entre la taille du select (pour les types de filtre) et de l'input
const ratioFilter = { select: 3, input: 9 };

// Les Types de filtres
export enum CmsTableFilterType {
  StringLike,
  StringEqual,
  StringBegin,
  StringExclude,
  NumberEqual,
  NumberGreater,
  NumberLess,
  NumberBetween,
  DateEqual,
  DateGreater,
  DateLess,
  DateBetween,
  BooleanEqual,
  SearchLike,
  SearchEqual,
  SearchBegin,
  SearchExclude,
  SearchFuzzy,
}

export interface CmsTableFilterHandler {
  filter: any[];
  setFilter: Dispatch<SetStateAction<any[]>>;
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
  globalFilter: string;
  setGlobalFilter: OnChangeFn<any>;
  pagination?: PaginationState;
  setPagination?: OnChangeFn<PaginationState>;
  count?: number;
}

export interface CmsTableHandler {
  state: any[];
  setState: Dispatch<SetStateAction<any[]>>;
}

interface CmsTableFilterProps {
  table: TansTable<any>;
  route: string;
  isFilterOpen: boolean;
  filterHandler?: CmsTableFilterHandler;
  columns: CmsColumnDef<any>[];
  setFiltersInUrl?: boolean;
}

/**
 * Header de la table pour les filtres, c'est ce qui s'affiche lorsque vous cliquer sur le bouton "Filtres Avancés"
 * @param table l'objet table contentant tout les informations du CmsTable
 * @param isOpen si le header est ouvert ou non (fonctionne avec le bouton "Filtres Avancés")
 * @param filterHandler l'objet contenant les fonctions pour gérer les filtres
 * @param columns liste des colonnes du CmsTable
 * @param route route de l'API
 * @param setFiltersInUrl si les filtres doivent être sauvegardé dans l'url
 */
export const CmsFormFilter: FC<CmsTableFilterProps> = ({
  table,
  isFilterOpen,
  filterHandler,
  columns,
  route,
  setFiltersInUrl,
}) => {
  const orderedList = useMemo(() => getOrderedFilterList(table), [table]);
  return (
    <Collapse component="div" in={isFilterOpen}>
      <div className="filter-container">
        <FilterButtons {...{ table, filterHandler, columns, route, setFiltersInUrl }} />
        {orderedList.map((column: any, key: number) => (
          <Grid className="filter-item" container item key={key} xs={12} lg={6}>
            <Grid item className="column-title" xs={12} lg={3}>
              {column.columnDef?.filterHeader ?? column.columnDef?.header ?? "Pas d'entête"}
            </Grid>
            <Grid item xs={12} lg={9}>
              {column.columnDef.Filter({ column, table })}
            </Grid>
          </Grid>
        ))}
      </div>
    </Collapse>
  );
};

/**
 * Retourne la liste des filtres ordonancée pour l'affichage
 * @param table le CmsTable
 */
function getOrderedFilterList(table: TansTable<any>): Column<any, any>[] {
  const filtered = table.getAllFlatColumns().filter((x: any) => !!x.columnDef.header && !!x.columnDef.Filter);
  const colNumber = window.innerWidth < 1600 ? 2 : 3;
  const result: Column<any, unknown>[] = [];
  const length = Math.ceil(filtered.length / colNumber);
  for (let i = 0; i < length; i++) {
    result.push(filtered[i]);
    if (filtered[i + length]) result.push(filtered[i + length]);
    if (filtered[i + length * 2] && colNumber === 3) result.push(filtered[i + length * 2]);
  }
  return result;
}

/**
 * Transforme les colonnes de type CmsColumnDef en colonnes de type RTColumn pour l'urlDataHandler
 * @param columnDefList liste des colonnes
 */
export function ColumnDefToRtColumn(columnDefList: CmsColumnDef<any>[]): RTColumn[] {
  const result: any[] = [];
  for (let columnDef of columnDefList)
    result.push({ accessor: columnDef.id ?? columnDef.accessorKey, Filter: columnDef.Filter });
  return result;
}

/**
 * Transforme les filtres récupérer depuis l'url en filtres de type CmsTableFilter
 * @param filterList liste des filtres récupérer depuis l'url
 * @param columnDefList liste des colonnes
 */
export function TradFilter(filterList: any[], columnDefList: CmsColumnDef<any>[]) {
  const result: any[] = [];
  for (let filter of filterList) {
    if (filter.isCol) {
      result.push({ id: filter.id, isCol: true, value: filter.value });
      continue;
    }
    if (filter.isPage) {
      result.push({ id: filter.id, isPage: true, value: filter.value });
      continue;
    }
    const colDef = columnDefList.find((x) => x.id === filter.id);
    if (!colDef) continue;
    if (filter.isSort) {
      result.push({ id: filter.id, isSort: true, desc: filter.value });
      continue;
    }
    let val: any = Utils.tryParseNumber(filter.value);
    const isArray = colDef.filterOptions?.multiple === true;
    const isDate = colDef.Filter === CmsTableFilter.Date;
    const isNumber = colDef.Filter === CmsTableFilter.Number;
    const isString = colDef.Filter === CmsTableFilter.Text;
    if (colDef.Filter === CmsTableFilter.Bool) {
      val = filter.value === 'true';
    } else if (colDef.Filter === CmsTableFilter.Search) {
      val = { value: filter.value, type: +filter.filterType };
    } else if (isDate && +filter.filterType === CmsTableFilterType.DateBetween) {
      val = { value: { start: filter.value?.begin, end: filter.value?.end }, type: +filter.filterType };
    } else if (isNumber && +filter.filterType === CmsTableFilterType.NumberBetween) {
      const minMax = filter.value?.split(';').map((x: any) => (x === 'undefined' || x === 'NaN' ? undefined : x));
      val = { value: { min: +minMax[0], max: +minMax[1] }, type: +filter.filterType };
    } else if (isArray) {
      val = typeof filter.value === 'string' ? filter.value?.split(',').map(Utils.tryParseNumber) : [filter.value];
    } else if (filter.filterType) {
      val = { value: !isString ? filter.value : filter.value.toString(), type: +filter.filterType };
    } else if (isString) val = filter.value.toString();

    result.push({ id: filter.id, value: val });
  }
  return result;
}

interface FilterButtonsProps {
  table: TansTable<any>;
  route: string;
  filterHandler?: CmsTableFilterHandler;
  columns: CmsColumnDef<any>[];
  setFiltersInUrl?: boolean;
}

/**
 * Boutons pour les filtres avancés (se trouve en en-tête des filtres avancés)
 * @param table l'objet table contentant tout les informations du CmsTable
 * @param filterHandler l'objet contenant les fonctions pour gérer les filtres
 * @param columns liste des colonnes du CmsTable
 * @param route route de l'API
 * @param setFiltersInUrl si les filtres doivent être sauvegardé dans l'url
 */
const FilterButtons: FC<FilterButtonsProps> = ({ filterHandler, columns, route, table, setFiltersInUrl }) => {
  const { filter, setFilter } = filterHandler ?? {};
  const { globalHistory } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [defaultCustomFilter, setDefaultCustomFilter] = useState<any[]>(() => {
    const filterString = decodeURI(globalHistory.get(location.pathname, true));
    if (!filterString || filterString === '') return [];
    return TradFilter(
      GetFilterValueFromURL(ColumnDefToRtColumn(columns), globalHistory, navigate, location, 't-', filterString),
      columns,
    );
  });
  const [saveFilter, setSaveFilter] = useState<boolean>(globalHistory.isFilterSaved(location.pathname));
  const saveThisFilter = () => {
    globalHistory.setSaveFilter(location, !saveFilter);
    setSaveFilter(!saveFilter);
  };

  const saveCustomDefaultFilter = () => {
    globalHistory.setSaveFilter(location, false, true);
    if (filter) setDefaultCustomFilter(filter);
    NotificationService.info('Filtre par défaut sauvegardé');
  };

  const handleCustomDefaultReinitFilter = () => {
    const searchUrl = globalHistory.get(location.pathname, true);
    if (!searchUrl) return NotificationService.warning('Aucun filtre par défaut sauvegardé');
    if (setFilter) setFilter(defaultCustomFilter);
  };

  const title = <Typography style={{ marginBottom: '0.5em', fontSize: '1.3rem' }}>Recherche avancée</Typography>;
  if (!setFiltersInUrl) return [title, <div />];
  return (
    <div className="advanced-filter-header">
      {title}
      <Buttons.Default onClick={() => setFilter && setFilter([])}>Réinitialiser les filtres</Buttons.Default>
      {(saveFilter && (
        <Buttons.Default startIcon={<Sync className="icon-rotate reverse speed2" />} onClick={saveThisFilter}>
          Filtres conservés
        </Buttons.Default>
      )) || <Buttons.Default onClick={saveThisFilter}>Conserver les filtres</Buttons.Default>}
      <Buttons.Default onClick={saveCustomDefaultFilter}>Sauvegarder ses filtres par défaut</Buttons.Default>
      <Buttons.Default onClick={handleCustomDefaultReinitFilter}>
        Réinitialiser avec mes filtres par défaut
      </Buttons.Default>
      <BatchEdit table={table} columns={columns} route={route} />
    </div>
  );
};

//#endregion

//#region Filters

interface GlobalFilterProps {
  globalFilterHandler: {
    globalFilter?: string;
    setGlobalFilter: any;
    withType?: boolean;
  };
  isMobile?: boolean;
  globalFilterIcon?: ReactNode;
  count: number;
}

export const CmsGlobalFilter: FC<GlobalFilterProps> = ({ globalFilterHandler, isMobile, globalFilterIcon, count }) => {
  const disabled = count > 65000; // un an d'interventions + marge de 5k - requête en 700 ms
  const handleDelete = () => globalFilterHandler.setGlobalFilter(undefined);
  const getFilterValue = () => globalFilterHandler.globalFilter;
  if (!globalFilterHandler.withType) {
    const searchBy = !globalFilterIcon ? 'Rechercher sur toutes les colonnes ...' : 'Rechercher ...';
    return (
      <div className={'cms-table-global-filter' + (isMobile ? ' mobile' : '')}>
        <InputUI.DebouncedInput
          valueCanUpdate
          delayTime={1000}
          inputProps={{ startAdornment: globalFilterIcon }}
          value={globalFilterHandler.globalFilter}
          onChange={globalFilterHandler.setGlobalFilter}
          placeholder={searchBy}
        />
      </div>
    );
  }
  return (
    <div className={'cms-table-global-filter' + (isMobile ? ' mobile' : '')}>
      <CmsTextFilter
        disabled={disabled}
        column={{ getFilterValue, setFilterValue: globalFilterHandler.setGlobalFilter } as any}
        placeholder="Rechercher ..."
        icon={globalFilterIcon}
      />
      {disabled && !!(getFilterValue() as any)?.value && (
        <div className="global-filter-delete">
          <CmsIcon icon="close" onClick={handleDelete} tooltip="vider le champ" textPos="right" />
        </div>
      )}
    </div>
  );
};

interface CmsTableTextFilterProps {
  column: Column<any, unknown>;
  placeholder?: string;
  disabled?: boolean;
  icon?: any;
}

/**
 * Filtre de type texte
 * @param column l'objet colonne contient l'ensemble des données relative à la colonne du cms-table
 * @param icon icone à afficher à gauche de l'input
 * @param placeholder placeholder de l'input
 * @param disabled si le filtre est désactivé
 */
const CmsTextFilter: FC<CmsTableTextFilterProps> = ({ column, icon, placeholder, disabled }) => {
  const [filterType, setFilterType] = useState<CmsTableFilterType>(CmsTableFilterType.StringLike);
  const filter = column.getFilterValue() as { value?: string; type?: CmsTableFilterType } | undefined;

  useEffect(() => {
    if (filter?.type !== undefined && filter?.type !== null) setFilterType(filter.type);
  }, [filter?.type]);

  const handleValueChange = (value?: string) => {
    if (value === filter?.value) return;
    column.setFilterValue(value ? { type: filterType, value } : undefined);
  };

  const handleTypeChange = (event: SelectChangeEvent<CmsTableFilterType>) => {
    const type = +((event.target.value as CmsTableFilterType) ?? CmsTableFilterType.StringLike);
    setFilterType(type);
    if (type !== filter?.type) column.setFilterValue(filter?.value ? { ...filter, type } : undefined);
  };
  if (disabled) {
    const title = <h3 style={{ textAlign: 'center' }}>Attention </h3>;
    // const str = 'Vous ne pouvez pas filtrer ici sur plus de 30 000 lignes.';
    const tip = (
      <div>
        {title}
        Vous ne pouvez pas filtrer ici sur plus de 60 000 lignes. Veuillez utiliser les filtres avancées pour réduire
        votre recherche
      </div>
    );
    icon = <CmsIcon icon="warning" typeColor="error" tooltip={tip} />;
  }
  return [
    <Grid container>
      <Grid className="text-column-filter" item xs={ratioFilter.select}>
        <Select
          disabled={disabled}
          className="text-column-filter-select"
          value={filterType}
          onChange={handleTypeChange}
        >
          <MenuItem value={CmsTableFilterType.StringLike}>Contient</MenuItem>
          <MenuItem value={CmsTableFilterType.StringEqual}>Égale</MenuItem>
          <MenuItem value={CmsTableFilterType.StringBegin}>Commence</MenuItem>
          <MenuItem value={CmsTableFilterType.StringExclude}>Exclus</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={ratioFilter.input}>
        <InputUI.DebouncedInput
          inputProps={{ startAdornment: icon }}
          valueCanUpdate
          disabled={disabled}
          placeholder={placeholder}
          value={filter?.value ?? ''}
          onChange={handleValueChange}
        />
      </Grid>
    </Grid>,
  ];
};

/**
 * Filtre de type nombre
 * @param column l'objet colonne contient l'ensemble des données relative à la colonne du cms-table
 */
const CmsNumberFilter: FC<{ column: Column<any, unknown> }> = ({ column }) => {
  const [filterType, setFilterType] = useState<CmsTableFilterType>(CmsTableFilterType.NumberBetween);
  const filter = column.getFilterValue() as any;

  useEffect(() => {
    if (filter?.type !== undefined && filter?.type !== null) setFilterType(filter.type);
  }, [filter?.type]);

  const handleValueChange = (value?: number, isMax = false) => {
    value = value == null ? undefined : +value;
    if (value === filter?.value) return;
    if (filterType !== CmsTableFilterType.NumberBetween)
      return column.setFilterValue(value == null ? undefined : { type: filterType, value });
    const { min, max } = filter?.value ?? {};
    if (isNaN(value as any) && ((isNaN(min as any) && isMax) || (isNaN(max as any) && !isMax)))
      return column.setFilterValue(undefined);
    if (!!filter?.value && filter.value[isMax ? 'max' : 'min'] === value) return;
    column.setFilterValue({ value: { ...filter?.value, [isMax ? 'max' : 'min']: value }, type: filterType });
  };

  const handleTypeChange = (event: SelectChangeEvent<CmsTableFilterType>) => {
    const type = +((event.target.value as CmsTableFilterType) ?? CmsTableFilterType.NumberBetween);
    setFilterType(type);
    if (type === filter?.type) return;
    if (!filter?.value) return column.setFilterValue(undefined);
    if (type === CmsTableFilterType.NumberBetween && filter?.type !== CmsTableFilterType.NumberBetween) {
      if (typeof filter?.value !== 'number') column.setFilterValue(undefined);
      else column.setFilterValue({ value: { min: filter?.value, max: filter?.value }, type });
    } else if (type !== CmsTableFilterType.NumberBetween && filter?.type === CmsTableFilterType.NumberBetween) {
      if (!!filter.value?.min) column.setFilterValue({ value: filter.value?.min, type });
      else column.setFilterValue(undefined);
    } else column.setFilterValue({ ...filter, type });
  };

  const firstValue = filterType === CmsTableFilterType.NumberBetween ? filter?.value?.min : filter?.value;
  const maxValue = filter?.value?.max;
  return (
    <Grid container>
      <Grid className="text-column-filter" item xs={ratioFilter.select}>
        <Select className="text-column-filter-select" value={filterType} onChange={handleTypeChange}>
          <MenuItem value={CmsTableFilterType.NumberBetween}>Min/Max</MenuItem>
          <MenuItem value={CmsTableFilterType.NumberEqual}>Exact</MenuItem>
          <MenuItem value={CmsTableFilterType.NumberGreater}>Supérieur à</MenuItem>
          <MenuItem value={CmsTableFilterType.NumberLess}>Inférieur à</MenuItem>
        </Select>
      </Grid>
      <Grid item className="number-filter" xs={ratioFilter.input}>
        <InputUI.DebouncedInput
          type="number"
          style={{ width: filterType !== CmsTableFilterType.NumberBetween ? '100%' : 'inherit' }}
          valueCanUpdate
          value={firstValue}
          deleteButton
          onChange={(x: number) => handleValueChange(x, false)}
          placeholder={filterType === CmsTableFilterType.NumberBetween ? 'Min' : undefined}
        />
        {filterType === CmsTableFilterType.NumberBetween && (
          <InputUI.DebouncedInput
            style={{ marginLeft: '.3rem' }}
            type="number"
            valueCanUpdate
            value={maxValue}
            deleteButton
            onChange={(max: number) => handleValueChange(max, true)}
            placeholder="Max"
          />
        )}
      </Grid>
    </Grid>
  );
};

export interface CmsFilterOptions {
  // liste des options disponible OU route pour récupérer la liste des options
  optionList?: any[] | string;
  // Nom de l'attribut à renvoyer
  optionId?: string;
  // Nom de l'attribut à afficher dans l'autocomplétion
  optionLabel?: string;
  // Indique au back si la liste doit être filtrée sur les rôles de l'utilisateur (MASTER/OPERATOR)
  // le from permet de sélectionner le rôle correspondant à la classe sur laquelle la liste est utilisée
  optionFrom?: string;
  // indique qu'on ne doit pas ajouter /Simplified à la route pour récupérer la liste
  rawEndpoint?: boolean;
  // Si l'autocomplétion peut sélectionner plusieurs valeurs
  multiple?: boolean;
}

/**
 * Filtre de type Autocomplétion, ajit comme un select avec une recherche
 * @param column l'objet colonne contient l'ensemble des données relative à la colonne du cms-table
 * @param table Le CmsTable pour récuperer les données pour les options deduis de la colonne
 */
const CmsSelectFilter: FC<{ column: Column<any, any>; table: TansTable<any> }> = ({ column, table }) => {
  const [optionList, setOptionList] = useState<any[] | null>(); //null pour distinguer non initialisé et initialisé à vide
  const options = (column.columnDef as any).filterOptions as CmsFilterOptions | undefined;

  useEffect(() => {
    if (Array.isArray(optionList)) return;
    if (!options?.optionList) {
      const colData = column.columnDef as CmsColumnDef<any>;
      const optionsList = Utils.distinct(
        table.getCoreRowModel().flatRows.map((x: any) => {
          return colData.accessorFn ? colData.accessorFn(x.original) : x.original[colData.accessorKey ?? ''];
        }),
      );
      const idLabelList = optionsList.map((x: any) => ({ id: x, label: x }));
      setOptionList(Utils.orderListByAttr(idLabelList, 'label'));
    } else if (Array.isArray(options?.optionList)) setOptionList(options.optionList);
    else if (options.rawEndpoint) CRUD.getList(options.optionList).then(setOptionList);
    else if (options.optionFrom)
      CRUD.getSimpleList(options.optionList, '?from=' + options.optionFrom).then(setOptionList);
    else CRUD.getSimpleList(options.optionList).then(setOptionList);
  }, [options?.optionList, options?.optionFrom, options?.rawEndpoint, optionList, table, column.columnDef]);

  const handleValueChange = (value?: any) => {
    if (Array.isArray(value) && value.length === 0) column.setFilterValue(undefined);
    else column.setFilterValue(value);
  };

  return (
    <InputUI.AutoCompletor
      value={column.getFilterValue()}
      multiple={options?.multiple ?? false}
      onChange={handleValueChange}
      options={optionList ?? []}
      optionLabel={options?.optionLabel}
      optionValue={options?.optionId}
    />
  );
};

interface CmsDateFilterValue {
  value?: any;
  type?: CmsTableFilterType;
}

/**
 * Filtre de type date
 * @param column l'objet colonne contient l'ensemble des données relative à la colonne du cms-table
 */
const CmsDateFilter: FC<{ column: Column<any, any> }> = ({ column }) => {
  const [filterType, setFilterType] = useState<CmsTableFilterType>(CmsTableFilterType.DateEqual);
  const filter = column.getFilterValue() as CmsDateFilterValue | undefined;
  const isRange = filterType === CmsTableFilterType.DateBetween;

  useEffect(() => {
    if (filter?.type !== undefined && filter?.type !== null && filterType !== filter?.type) setFilterType(filter.type);
  }, [filter?.type, filterType]);

  const handleValueChange = (value?: Date, isEnd = false) => {
    value = value ? Utils.Date.pureDate(value) : undefined;
    if (value && value.getFullYear() < 1000) value = undefined;
    if (value === filter?.value) return;
    if (!isRange) column.setFilterValue(value ? { type: filterType, value } : undefined);
    else {
      if (((isEnd && !filter?.value?.start) || (!isEnd && !filter?.value?.end)) && value === undefined)
        return column.setFilterValue(undefined);
      if (isEnd) column.setFilterValue({ type: filterType, value: { ...filter?.value, end: value } });
      else column.setFilterValue({ type: filterType, value: { ...filter?.value, start: value } });
    }
  };

  const handleTypeChange = (event: SelectChangeEvent<CmsTableFilterType>) => {
    const type = (event.target.value as CmsTableFilterType) ?? CmsTableFilterType.DateEqual;
    setFilterType(type);
    if (!filter?.value || filter.type === type) return;
    const wasRange = filter?.type === CmsTableFilterType.DateBetween;
    const isRange = type === CmsTableFilterType.DateBetween;
    if (!wasRange && isRange) column.setFilterValue({ value: { start: filter.value }, type });
    else if (!wasRange && !isRange) column.setFilterValue({ ...filter, type });
    else if (wasRange && !isRange)
      column.setFilterValue(filter.value?.start ? { value: filter.value.start, type } : undefined);
  };

  return (
    <Grid container style={{ marginBottom: '0.2rem' }}>
      <Grid className="text-column-filter" item xs={ratioFilter.select}>
        <Select
          className="text-column-filter-select"
          style={{ textAlign: 'center' }}
          value={filterType}
          onChange={handleTypeChange}
        >
          <MenuItem value={CmsTableFilterType.DateEqual}>Exact</MenuItem>
          <MenuItem value={CmsTableFilterType.DateGreater}>Après le</MenuItem>
          <MenuItem value={CmsTableFilterType.DateLess}>Avant le</MenuItem>
          <MenuItem value={CmsTableFilterType.DateBetween}>Entre</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={ratioFilter.input} className="flex-h">
        <InputUI.DatePickerVanilla
          label={isRange ? 'du' : undefined}
          value={isRange ? filter?.value?.start : filter?.value}
          onChange={handleValueChange}
        />
        {isRange && (
          <InputUI.DatePickerVanilla
            label="au"
            value={filter?.value?.end}
            onChange={(date: Date) => handleValueChange(date, true)}
            containerStyle={{ marginLeft: '.3rem' }}
          />
        )}
      </Grid>
    </Grid>
  );
};

/**
 * Filtre de type booléen
 * @param column l'objet colonne contient l'ensemble des données relatives à la colonne du cms-table
 */
const CmsBooleanFilter: FC<{ column: Column<any, any> }> = ({ column }) => {
  const value = column.getFilterValue() as boolean | undefined;
  return (
    <div style={{ marginBottom: '0.1rem' }}>
      <InputUI.CmsButtonGroup
        value={value}
        onClick={column.setFilterValue}
        options={[
          { id: true, label: <CmsIcon icon="OK" typeColor={value ? 'valid' : 'default'} /> },
          { id: undefined, label: 'Tous' },
          { id: false, label: <CmsIcon icon="KO" typeColor={value === false ? 'error' : 'default'} /> },
        ]}
      />
    </div>
  );
};

/**
 * Filtre de type Autocomplétion, cependant contrairement au filtre Select, on peut donner une information partiel
 * sans avoir à rechercher dans la liste
 * @param column l'objet colonne contient l'ensemble des données relatives à la colonne du cms-table
 */
const CmsSuggestFilter: FC<{ column: Column<any, any> }> = ({ column }) => {
  const options = (column.columnDef as any).filterOptions as CmsFilterOptions | undefined;
  if (!options?.optionList) return <></>;
  const route = options.optionList as string;
  return (
    <InputUI.CustomSuggest
      name={'suggest-' + column.columnDef.id}
      initialValue={column.getFilterValue() as string | undefined}
      onValueSelected={column.setFilterValue}
      getSuggestionService={(search: string) => CRUD.getSuggestedList(route, { search })}
      getAttribute={options.optionId ?? 'id'}
      showAttribute={options.optionLabel ?? 'label'}
    />
  );
};

/**
 * /!\ Filtre purement front car il se base sur les données du tableau
 * Filtre de type Autocomplétion, cependant contrairement au filtre Select, on peut donner une information partielle
 * @param column l'objet colonne contient l'ensemble des données relatives à la colonne du cms-table
 * @param table Le CmsTable pour récuperer les données pour les options deduis de la colonne
 */
const CmsSearchFilter: FC<{ column: Column<any, any>; table: TansTable<any> }> = ({ column, table }) => {
  const [filterType, setFilterType] = useState<CmsTableFilterType>(CmsTableFilterType.SearchFuzzy);
  const [optionList, setOptionList] = useState<any[]>([]); //null pour distinguer non initialisé et initialisé à vide
  const [filterValue, setFilterValue] = React.useState(
    column.getFilterValue() as { value?: string; type?: CmsTableFilterType } | undefined,
  );

  useEffect(() => {
    if (filterValue?.type !== undefined && filterValue?.type !== null) setFilterType(filterValue.type);
  }, [filterValue?.type]);

  const handleValueChange = (value?: string, isClick?: boolean) => {
    if (value === filterValue?.value) return;
    let type = filterType;
    if (isClick) {
      type = CmsTableFilterType.SearchEqual;
      setFilterType(type);
    }
    setFilterValue(value ? { type, value } : undefined);
  };

  const handleTypeChange = (event: SelectChangeEvent<CmsTableFilterType>) => {
    const type = +((event.target.value as CmsTableFilterType) ?? CmsTableFilterType.SearchFuzzy);
    setFilterType(type);
    if (type !== filterValue?.type) column.setFilterValue(filterValue?.value ? { ...filterValue, type } : undefined);
  };

  useEffect(() => {
    const colData = column.columnDef as CmsColumnDef<any>;
    const optionsList = Utils.distinct(
      table.getCoreRowModel().flatRows.map((x: any) => {
        return colData.accessorFn ? colData.accessorFn(x.original) : x.original[colData.accessorKey ?? ''];
      }),
    );
    setOptionList(optionsList);
  }, [column.columnDef, table]);

  useEffect(() => {
    if ((column.getFilterValue() as any)?.value === filterValue?.value) return;
    const timeOut = setTimeout(() => column.setFilterValue(filterValue), 600);
    return () => clearTimeout(timeOut);
  }, [filterValue, column]);

  let filteredOptions = [];
  if (filterValue)
    filteredOptions = optionList.filter((x) => x?.toLowerCase().includes(filterValue.value?.toString().toLowerCase()));
  const noOptionLabel = filteredOptions.length > 500 ? 'Trop de résultats' : 'Aucun résultat';
  return (
    <Grid container>
      <Grid className="text-column-filter" item xs={ratioFilter.select}>
        <Select className="text-column-filter-select" value={filterType} onChange={handleTypeChange}>
          <MenuItem value={CmsTableFilterType.SearchFuzzy}>
            <div className="flex-h align-center">
              <CmsIcon
                icon="info"
                style={{ opacity: 0.7, height: '2rem', margin: '-1rem 0', fontSize: '1.2rem' }}
                tooltip="Recherche si toutes les lettres sont présentes dans l'ordre (exemple : si vous recherchez
                la référence 'NF000000012', vous pouvez écrire 'NF12' ou 'NF012', cependant vous trouverez également
                'NFAB0001234' ou 'ABNF01888882')"
              />
              Intelligent
            </div>
          </MenuItem>
          <MenuItem value={CmsTableFilterType.SearchLike}>Contient</MenuItem>
          <MenuItem value={CmsTableFilterType.SearchEqual}>Égale</MenuItem>
          <MenuItem value={CmsTableFilterType.SearchBegin}>Commence</MenuItem>
          <MenuItem value={CmsTableFilterType.SearchExclude}>Exclus</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={ratioFilter.input}>
        <Autocomplete
          value={filterValue?.value ?? ''}
          size="small"
          renderInput={(params) => (
            <TextField
              className="search-column-filter-input"
              {...params}
              onChange={(e) => handleValueChange(e?.target?.value)}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <CmsIcon
                      icon="search"
                      tooltip="Lorsque vous sélectionnez un élément de la liste,
                      le filtre passe automatiquement à la valeur 'égale'."
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
          noOptionsText={noOptionLabel}
          onChange={(_, value) => handleValueChange(value, true)}
          options={
            !filterValue || (filterValue.value?.length ?? 0) < 2 || filteredOptions.length > 500 ? [] : filteredOptions
          }
        />
      </Grid>
    </Grid>
  );
};

//#endregion

//#region BatchEdit

interface BatchEditProps {
  table: TansTable<any>;
  columns: CmsColumnDef<any>[];
  route: string;
}

/**
 * Édition de plusieurs lignes en même temps, permet d'éditer la valeur d'une colonne pour plusieurs lignes
 * le lien côté backend doit toujous être de la forme : route/ColumnUpdate
 * @param table le CmsTable
 * @param columns liste des colonnes du CmsTable
 * @param route route de l'API
 */
const BatchEdit: FC<BatchEditProps> = ({ table, columns, route }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenValidation, setIsOpenValidation] = useState<boolean>(false);
  const [form, setForm] = useState<any>({});

  const filteredColumns = useMemo((): any[] => {
    let col = columns.filter((x) => !!x.updatableColInputType);
    if (col.length === 0) return [];
    const result = [];
    for (const x of col) if (!x.updatableColRight || AccessFilter([x.updatableColRight])) result.push(x);
    return result;
  }, [columns]);

  if (filteredColumns.length === 0) return <></>;

  const handleOpen = () => {
    if (table.getFilteredRowModel().rows.length > 0) setIsOpen(true);
    else NotificationService.error('Aucune ligne sélectionnée');
  };

  const handleUpdate = () => {
    if (!route || route.length === 0) return;
    const payload = { ...form, idList: table.getFilteredRowModel().rows.map((x: any) => +x.original.id) };
    CRUD.put<any>(route + '/ColumnUpdate', payload)
      .then(() => {
        NotificationService.success('Mise à jour effectuée avec succès');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .finally(() => {
        setIsOpen(false);
        setIsOpenValidation(false);
      });
  };

  return (
    <>
      <Buttons.Default onClick={handleOpen}>Éditer par colonnes</Buttons.Default>
      <DialogVanillaUI open={isOpen} onClose={() => setIsOpen(false)}>
        <CmsPaper title="Éditer par colonnes" style={{ marginBottom: 0 }}>
          {columns
            .filter((x) => !!x.updatableColInputType)
            .map((col: CmsColumnDef<any>) => (
              <div key={col.id}>
                <Typography variant="h6">{(col.filterHeader ?? col.header) as any}</Typography>
                {(col.updatableColInputType === 'string' && (
                  <InputUI.DebouncedInput onChange={(x: any) => setForm({ ...form, [col.id]: x })} />
                )) ||
                  (col.updatableColInputType === 'number' && (
                    <InputUI.DebouncedInput onChange={(x: any) => setForm({ ...form, [col.id]: x })} type="number" />
                  )) ||
                  (col.updatableColInputType === 'date' && (
                    <InputUI.DatePickerVanilla onChange={(x: any) => setForm({ ...form, [col.id]: x })} />
                  ))}
              </div>
            ))}
          <CmsDivider />
          <div className="flex-h end">
            <Buttons.Delete style={{ marginRight: '1em' }} onClick={() => setIsOpen(false)}>
              Abandonner
            </Buttons.Delete>
            <Buttons.Valid onClick={() => setIsOpenValidation(true)}>Appliquer</Buttons.Valid>
          </div>
        </CmsPaper>
      </DialogVanillaUI>
      <DialogUI
        open={isOpenValidation}
        onClose={() => setIsOpenValidation(false)}
        title="Édition par colonnes"
        onValidation={handleUpdate}
      >
        Vous êtes sur le point de mettre à jour plusieurs lignes en même temps. Cette action est irréversible.
      </DialogUI>
    </>
  );
};

//#endregion

/**
 * Converti les filtres du front vers le backend avec un format spécifique s'accordant avec la classe Filter du backend
 * @param filterList liste des filtres
 * @param pagination pagination
 * @param sorting colonne trié
 * @param globalFilter valeur du filtre global (valeur toujours présente au format string)
 */
function tradFilterFrontToBack(
  filterList: { id: string; value: any }[],
  pagination: PaginationState,
  sorting: SortingState,
  globalFilter?: string,
) {
  const result: any = { ...(pagination ?? { page: 0, rowsPerPage: 20 }), globalFilter };
  for (let filter of filterList)
    if (filter.value?.value !== undefined && filter.value?.value !== null)
      result[filter.id] = { value: filter.value.value, type: filter.value.type };
    else if (filter.value !== undefined && filter.value !== null) result[filter.id] = filter.value;
  return sorting && sorting[0] ? { ...result, sortBy: sorting[0].id, desc: sorting[0].desc } : result;
}

// function isValueValid(value: any) {
//   if (value === undefined || value === null) return false;
//   if (typeof value === 'string' && value === '') return false;
//   if (typeof value === 'object' && value instanceof Date && isNaN(value.getTime())) return false;
//   if (typeof value === 'object' && Array.isArray(value) && value.length === 0) return false;
//   if (typeof value === 'object' && value.type !== undefined && value.value === undefined) return false;
//   return true;
// }
export const filterOutCol = (col: CmsColumnDef<any>[], ...columnToFilter: string[]): CmsColumnDef<any>[] =>
  col.filter((x) => !columnToFilter.includes(x.id));

const CmsTableFilter = {
  Text: CmsTextFilter,
  Select: CmsSelectFilter,
  Date: CmsDateFilter,
  Bool: CmsBooleanFilter,
  Suggest: CmsSuggestFilter,
  Number: CmsNumberFilter,
  Search: CmsSearchFilter,
  tradFilterFrontToBack,
};

export default CmsTableFilter;
