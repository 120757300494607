import { Quotation } from './Quotation';
import { Conf } from './Configuration';
import { Command } from './Command';
import { RH } from './RH';
import { Activity } from './Activity';
import { Stock } from './Stock';
import { Park } from './Park';
import { Greenflux } from './Greenflux';
import { Stats } from './Stats';
import { Users } from './Users';
import { Billing } from './Billing';
import { Material } from './Material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Help } from '@mui/icons-material';

export interface NavProps {
  // Nom du menu
  name: string;
  // Icone du menu
  icon?: React.ReactNode;
  // Lien vers la page
  link?: string;
  // Lien vers la page V1
  linkV1?: string;
  // est un lien à la racine de navigationConfiguration
  rootLink?: boolean;
  // Liste des sous menu
  subList?: NavProps[];
  // Liste des features (aussi appelé role) pour afficher le menu
  feature?: string | string[];
  // Définie si le menu doit être légèrement décalé par rapport aux autres entrées
  tabulation?: boolean;
  navStateHandler?: any;
  // Profondeur du menu (sous menu ou sous sous menu)
  depth?: number;
  // La route est accessible uniquement en mode dev
  devOnly?: boolean;
  forceOpenNewTab?: boolean;
}

// Liste segmenter de tous les liens de menu Sonata de la V1
export const NavigationConf: NavProps[] = [
  Activity,
  Park,
  Command,
  Stock,
  Material,
  Quotation,
  Billing,
  RH,
  Users,
  Stats,
  Greenflux,
  Conf,
  {
    name: 'Mes paramètres',
    icon: <InfoIcon />,
    link: '/myParams',
    rootLink: true,
  },
  {
    name: 'Aide CMS',
    icon: <Help />,
    linkV1: 'help/',
    rootLink: true,
    forceOpenNewTab: true,
  },
  {
    name: 'Déconnexion',
    icon: <MeetingRoomIcon />,
    link: '/logoutV2',
    rootLink: true,
  },
];
