import React, { FC } from 'react';
import APIRoute from '../../../constant/API.constant';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton, ArrowBackNavigationButton } from '../../../component/shared/Buttons';
import { ClAgency } from '../../../interface/ClType';
import { UI } from '../../../component/shared';
import { OsmMapEdit } from '../../../component/map/OsmMap';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { CmsPaper } from '../../../component/shared/Ui';
import ROLE from '../../../constant/role.constant';
import CmsTableCell from 'component/table/helper/CmsTableCell';

export const ClAgencyListConfig: CmsColumnDef<ClAgency>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Text, cell: (info) => <CmsTableCell.Ref cell={info} /> },
  { header: 'Téléphone', id: 'tel', Filter: CmsTableFilter.Text },
  { header: 'Email', id: 'email', Filter: CmsTableFilter.Text },
  { header: 'Code postal', id: 'postcode', Filter: CmsTableFilter.Text },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  {
    header: 'Adresse',
    id: 'address',
    noClick: true,
    Filter: CmsTableFilter.Text,
    cell: (info) => <CmsTableCell.Address {...info} />,
  },
];

/**
 * Liste des agences
 */
export const ClAgencyList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_AGENCY_CREATE])
    ? [<AddNavigationButton title="Ajouter une agence" to="/castres/client/agency/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des agences"
      columns={ClAgencyListConfig}
      route={APIRoute.ClAgency}
      navigateTo={(id: number) => `/castres/client/agency/${id}/edit`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Création ou édition d'une agence
 * @param id id de l'agence à éditer
 * @param setHttpCodePage fonction pour modifier le code http de la page
 */
export const ClAgencyCreateEdit: FC = ({ id }: any) => {
  const props = { id, currentUrl: '/castres/client/agency/', route: APIRoute.ClAgency };
  const action = [<ArrowBackNavigationButton title="Retourner à la liste" to={`${props.currentUrl}list`} />];
  return (
    <CmsForm
      {...props}
      renderForm={(form: any) => {
        const { latitude, longitude } = form.watch();
        const handleMarkerDragend = (lat: number, lng: number) => {
          form.setValue('latitude', lat);
          form.setValue('longitude', lng);
        };
        return (
          <div className="agency-page">
            <CmsPaper title="Agence" actions={action}>
              <CmsFormInput.Text id="ref" label="Référence" required max={50} />
              <CmsFormInput.Text id="label" label="Nom" required max={50} />
              <CmsFormInput.Address id="address" label="Adresse" required max={100} />
              <CmsFormInput.Text id="postcode" label="Code Postal" required max={5} />
              <CmsFormInput.Text id="city" label="Ville" required max={50} />
              <CmsFormInput.Text id="tel" label="Téléphone" required max={50} />
              <CmsFormInput.Text id="email" label="Email" />
              <CmsFormInput.Number id="latitude" label="Latitude" min={-90} max={90} required />
              <CmsFormInput.Number id="longitude" label="Longitude" min={-180} max={180} required />
              <CmsFormSubmit type={!!id ? 'editForm' : 'createForm'} />
            </CmsPaper>
            {!!latitude && !!longitude && (
              <UI.Paper title="Carte (Latitude/Longitude)">
                <OsmMapEdit
                  setCenter={{ lat: +latitude, lng: +longitude }}
                  containerHeight="51rem"
                  onMarkerDragEnd={handleMarkerDragend}
                />
              </UI.Paper>
            )}
          </div>
        );
      }}
    ></CmsForm>
  );
};
