import React, { CSSProperties, FC, useContext, useState } from 'react';
import { InputUI } from '../shared';
import { MultiLevelContext } from './MultiLevelForm';
import APIRoute from '../../constant/API.constant';
import CmsIcon from '../shared/CmsIcon';

/**
 * Props des composants MultiLevelInput
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param optionRoute Route pour récupérer les options (select) de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 */
export interface MultiLevelInputProps {
  value: any;
  row: any;
  setValue: (value: any) => void;
  optionRoute?: string | APIRoute;
  selectOptionValue?: string;
  inputOption?: any;
  defaultValue?: any;
  index: number;
  handleUpdate: (value: any, column: string) => void;
}

/**
 * Composant de base (Type texte) pour les inputs du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 * @param index Index de la cellule dans la ligne
 * @param row Ligne du formulaire
 */
export const MultiLevelInput: FC<MultiLevelInputProps> = ({ value, setValue, inputOption, index, row }) => {
  return (
    <InputUI.DebouncedInput value={value} valueCanUpdate onChange={setValue} {...inputOption} key={row?.id + index} />
  );
};

export const MultiLevelInputNumber: FC<MultiLevelInputProps> = ({ value, setValue, inputOption, index, row }) => {
  return (
    <InputUI.DebouncedInput
      value={value}
      valueCanUpdate
      onChange={setValue}
      {...inputOption}
      type="number"
      key={row?.id + index}
    />
  );
};

export const MultiLevelReadOnly: FC<MultiLevelInputProps> = ({ value, index, row }) => {
  return <span key={row?.id + index}>{value}</span>;
};

interface MultiLevelVanillaProps {
  children: any;
  className?: string;
  style?: CSSProperties;
  cellStyle?: CSSProperties;
  row: any;
  index: number;
}

export const MultiLevelVanilla: FC<MultiLevelVanillaProps> = ({
  children,
  className,
  style,
  cellStyle,
  row,
  index,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const classN = (isFocused ? 'focused ' : '') + 'multi-lvl-vanilla ' + className;
  return (
    <div className={classN} key={row?.id + index} style={cellStyle}>
      <CmsIcon className="multi-line-icon" icon="resize" onClick={() => setIsFocused(!isFocused)} />
      <div style={style}>{children}</div>
    </div>
  );
};

export interface MultiLevelAutoCompleteProps extends MultiLevelInputProps {
  // Retourné l'objet complet ou juste la valeur
  returnObject?: boolean;
}

/**
 * Composant pour les inputs de type select du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param optionRoute Route pour récupérer les options (select) de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 * @param selectOptionValue Liste des options selectionnable
 * @param row Ligne du formulaire
 * @param index Index de la cellule dans la ligne
 */
export const MultiLevelAutoComplete: FC<MultiLevelAutoCompleteProps> = ({
  value,
  setValue,
  optionRoute,
  inputOption,
  returnObject,
  selectOptionValue,
  row,
  index,
}) => {
  const { optionList } = useContext(MultiLevelContext);
  if ((!optionRoute || !optionList[optionRoute]) && !inputOption?.options) return <div>Aucune options</div>;
  return (
    <InputUI.AutoCompletor
      key={row?.id + index}
      value={value}
      returnObject={returnObject}
      optionValue={selectOptionValue}
      onChange={setValue}
      options={optionList[optionRoute ?? 'notfound']}
      {...inputOption}
    />
  );
};

/**
 * Composant pour les inputs de type boolean du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param row Ligne du formulaire
 * @param index Index de la cellule dans la ligne
 */
export const MultiLevelBoolean: FC<MultiLevelInputProps> = ({ value, setValue, row, index }) => {
  return <InputUI.CmsSwitch key={row?.id + index} value={value} onChange={setValue as any} />;
};

/**
 * Composant pour les inputs de type date du formulaire multi niveau
 * @param value Valeur de l'input
 * @param setValue Fonction pour modifier la valeur de l'input
 * @param inputOption Option de l'input (tout type d'options, dont les options de l'autoComplete)
 * @param row Ligne du formulaire
 * @param index Index de la cellule dans la ligne
 */
export const MultiLevelDate: FC<MultiLevelInputProps> = ({ value, setValue, inputOption, row, index }) => {
  return <InputUI.DatePickerVanilla value={value} onChange={setValue} {...inputOption} key={row?.id + index} />;
};

export const MultiLevelSelect: FC<MultiLevelInputProps> = ({
  value,
  setValue,
  optionRoute,
  inputOption,
  row,
  index,
}) => {
  const { optionList } = useContext(MultiLevelContext);
  if ((!optionRoute || !optionList[optionRoute]) && !inputOption?.options) return <div>Aucune options</div>;
  return (
    <InputUI.SimpleSelect
      key={row?.id + index}
      className="simple-select"
      value={value}
      onChange={setValue}
      options={optionList[optionRoute ?? 'notFound']}
      {...inputOption}
    />
  );
};
