import { InputUI } from 'component/shared';
import { CellDndContext } from './CmsDndTable';
import React from 'react';
import Utils from '../../helper/Utils';
import { CmsLink } from '../shared/Ui';

//#region Inputs

/**
 * Input de type texte pour le DndTable
 * @param x contexte de la cellule : CellDndContext<any>
 */
const handleTextInput: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <InputUI.DebouncedInput
    variant="standard"
    key={x.cell.key}
    valueCanUpdate
    onChange={x.cell.setCellValue}
    value={x.getValue()}
  />
);

/**
 * Input de type nombre pour le DndTable
 * @param x contexte de la cellule : CellDndContext<any>
 */
const handleNumberInput: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <InputUI.DebouncedInput
    variant="standard"
    key={x.cell.key}
    valueCanUpdate
    onChange={x.cell.setCellValue}
    value={x.getValue()}
    type="number"
  />
);

/**
 * Input de type prix pour le DndTable, affiche un symbole € et aligne le texte à droite
 * @param x contexte de la cellule : CellDndContext<any>
 */
const handlePriceInput: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <InputUI.DebouncedInput
    variant="standard"
    key={x.cell.key}
    valueCanUpdate
    onChange={x.cell.setCellValue}
    value={x.getValue()}
    type="number"
    priceInput
  />
);

/**
 * Input de type select pour le DndTable, l'optionList est calculéé a partir des inputOptions fournis dans la
 * configuration de la colonne (inputOptions)
 * @param x contexte de la cellule : CellDndContext<any>
 */
const handleSelect: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <InputUI.AutoCompletor
    variant="standard"
    options={x.cell.optionList ?? []}
    onChange={x.cell.setCellValue}
    value={x.getValue()}
  />
);

/**
 * Input de type switch pour le DndTable
 * @param x contexte de la cellule : CellDndContext<any>
 */
const handleSwitch: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <InputUI.CmsSwitch value={x.getValue()} onChange={x.cell.setCellValue} style={{ margin: '-0.3rem 0' }} />
);

//#endregion

//#region Cells

/**
 * Cellule de type texte pour le DndTable
 * @param x contexte de la cellule : CellDndContext<any>
 */
const textCell: ((info: CellDndContext<any>) => any) | undefined = (x) => <div>{x.getValue()}</div>;

/**
 * Cellule de type nombre pour le DndTable, aligne le texte à droite, alignement à droite
 * @param x contexte de la cellule : CellDndContext<any>
 */
const numberCell: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <div style={{ textAlign: 'right' }}>{x.getValue()}</div>
);

/**
 * Cellule de type prix pour le DndTable, aligne le prix à droite avec le symbole €
 * @param x contexte de la cellule : CellDndContext<any>
 */
const priceCell: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <div style={{ textAlign: 'right' }}>{x.getValue()} €</div>
);

/**
 * Cellule de type pourcentage pour le DndTable, aligne le pourcentage à droite
 * @param x contexte de la cellule : CellDndContext<any>
 */
const percentCell: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <div style={{ textAlign: 'right' }}>{x.getValue()} %</div>
);

/**
 * Cellule de type date pour le DndTable, affiche uniquement la date sans l'heure
 * @param x contexte de la cellule : CellDndContext<any>
 */
const dateOnlyCell: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <div>{Utils.Date.dateOnlyFormat(x.getValue())}</div>
);

/**
 * Cellule de type date pour le DndTable, affiche la date et l'heure
 * @param x contexte de la cellule : CellDndContext<any>
 */
const dateTimeCell: ((info: CellDndContext<any>) => any) | undefined = (x) => (
  <div>{new Date(x.getValue()).toLocaleDateString()}</div>
);

/**
 * Cellule de type lien pour le DndTable, ouvre le lien dans un nouvel onglet, le href est calculé à partir de la
 * ligne de donnée, le lien s'ouvre dans un nouvel onglet
 * @param href fonction qui retourne l'url de destination
 */
const linkCell: (href: (rowData: any) => string) => any = (href) => (x: CellDndContext<any>) => (
  <CmsLink href={href(x.cell.row.original)} label={x.getValue()} target="_blank" defaultStyle />
);

//#endregion

//#region ChapterAction

//#endregion

/**
 * Objet contenant les fonctions de rendu des inputs et des cellules pour le DndTable
 */
export const DndInput = {
  Text: handleTextInput,
  Number: handleNumberInput,
  Price: handlePriceInput,
  Select: handleSelect,
  Switch: handleSwitch,
};

/**
 * Objet contenant les fonctions de rendu des cellules pour le DndTable
 */
export const DndCell = {
  Text: textCell,
  Number: numberCell,
  Price: priceCell,
  Percent: percentCell,
  DateOnly: dateOnlyCell,
  DateTime: dateTimeCell,
  Link: linkCell,
};
