import React, { FC, useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, Tooltip, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { CmsPaper } from 'component/shared/Ui';

// Coordonnées GPS
interface LatLng {
  // Latitude
  lat?: number;
  // Longitude
  lng?: number;
}

interface MapCustomProps {
  // Coordonnées GPS
  setCenter: LatLng;
  // Hauteur du conteneur (par défaut 300px)
  containerHeight?: string;
  // déplacement du marker
  onMarkerDragEnd?: (lat: number, lng: number) => void;
}

//Fonction qui dessine le svg représentant le marker
const MarkerIcon = () => {
  return (
    <svg width="20" height="35" viewBox="0 0 25 41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 0C6.149 0 0 6.307 0 14.089c0 10.502 12.5 26.911 12.5 26.911s12.5-16.409 12.5-26.911C25 6.307 18.851 0 12.5 0zm0 19.091a5.002 5.002 0 110-10.003 5.002 5.002 0 010 10.003z"
        fill="#4ca4ed"
        stroke="#000000"
      />
      <circle cx="12.5" cy="14" r="6" fill="white" stroke="#0044cc" stroke-width="1" />
    </svg>
  );
};

// Définir l'icône de marker par défaut
const defaultIcon = L.divIcon({
  className: 'custom-marker', // you can set a class here if you need to style the container
  html: ReactDOMServer.renderToString(<MarkerIcon />),
  iconSize: [20, 35],
  iconAnchor: [12, 35],
});

//Composant utilisé pour ajuster les limites de la carte
const MapUpdater: FC<any> = ({ setCenter }: any) => {
  const map = useMap();
  useEffect(() => {
    const bounds = L.latLngBounds([L.latLng(setCenter.lat ?? 49.3896, setCenter.lng ?? 1.08)]);
    map.fitBounds(bounds);
  }, [setCenter, map]);
  return null;
};

//Composant permettant d'afficher un marker sur une carte
export const OsmMapShow: FC<MapCustomProps> = ({ setCenter, containerHeight = '19rem' }) => {
  const center = [setCenter.lat ?? 49.3896, setCenter.lng ?? 1.08] as any; //Si pas de latlng, on met les coordonnées de Castres Saint Etienne du Rouvray
  return (
    <MapContainer
      center={center}
      zoom={14}
      minZoom={3}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
      maxBoundsViscosity={1.0}
      scrollWheelZoom={true}
      style={{ height: containerHeight, zIndex: 40 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={center} icon={defaultIcon}></Marker>
    </MapContainer>
  );
};

//Composant permettant de récupérer des coordonnées sur une colonne de
//cmsTable et de l'afficher sur une carte
//Si Coordonnées invalides ou égales à 0, on affiche un popup
export const OsmMapFromList: FC = () => {
  const { latitude, longitude } = useParams() as any;
  const lat = !isNaN(latitude) ? parseFloat(latitude) : null;
  const lng = !isNaN(longitude) ? parseFloat(longitude) : null;
  const isCoordinatesValid = lat !== null && lng !== null && !(lat === 0 && lng === 0);
  const position = isCoordinatesValid ? ([lat, lng] as any) : [47.0243, 2.351];
  const zoom = isCoordinatesValid ? 14 : 6;
  return (
    <CmsPaper>
      <MapContainer
        center={position}
        zoom={zoom}
        minZoom={3}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        maxBoundsViscosity={1.0}
        scrollWheelZoom={true}
        style={{ height: '55rem', zIndex: 40 }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {isCoordinatesValid && <Marker position={[lat, lng]} icon={defaultIcon}></Marker>}
        {!isCoordinatesValid && <Popup position={[47.0243, 2.351]}>Coordonnées non valides</Popup>}
      </MapContainer>
    </CmsPaper>
  );
};

// Composant de carte utiliser lors d'un edit
// il permet de déplacer un marker sur une carte
// tout en modifiant automatiquement latitude et longitude sur le formulaire
export const OsmMapEdit: FC<MapCustomProps> = ({ setCenter, containerHeight = '15rem', onMarkerDragEnd }) => {
  const [coordinates, setCoordinates] = useState({ lat: setCenter.lat ?? 49.3896, lng: setCenter.lng ?? 1.08 });
  const [isCoordinatesValid, setIsCoordinatesValid] = useState<boolean>(false);
  useEffect(() => {
    setCoordinates({ lat: setCenter.lat ?? 49.3896, lng: setCenter.lng ?? 1.08 });
    setCenter.lat &&
    setCenter.lng &&
    setCenter.lat >= -90 &&
    setCenter.lat <= 90 &&
    setCenter.lng >= -180 &&
    setCenter.lng <= 180
      ? setIsCoordinatesValid(true)
      : setIsCoordinatesValid(false);
  }, [setCenter]);

  return (
    <MapContainer
      center={[coordinates.lat, coordinates.lng]}
      zoom={15}
      minZoom={3}
      maxBounds={[
        [-90, -180],
        [90, 180],
      ]}
      maxBoundsViscosity={1.0}
      scrollWheelZoom={true}
      style={{ height: containerHeight, zIndex: 40 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={[coordinates.lat, coordinates.lng]}
        icon={defaultIcon}
        draggable={true}
        eventHandlers={{
          dragend: (e) => {
            const latLng = e.target.getLatLng();
            setCoordinates({ lat: latLng.lat, lng: latLng.lng });
            !!onMarkerDragEnd && onMarkerDragEnd(latLng.lat.toFixed(6), latLng.lng.toFixed(6));
          },
        }}
      >
        <Tooltip>Vous pouvez me déplacer !</Tooltip>
      </Marker>
      {isCoordinatesValid && <MapUpdater setCenter={setCenter} />}
      {!isCoordinatesValid && <MapUpdater setCenter={{ lat: 0.1, lng: 0.1 }} />}
      {!isCoordinatesValid && <Popup position={[0.1, 0.1]}>Coordonnées non valides</Popup>}
    </MapContainer>
  );
};
