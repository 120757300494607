import { FilterFn, SortingFn, sortingFns } from '@tanstack/react-table';
import { compareItems, RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import Utils, { isDate } from '../../../helper/Utils';
import { CmsTableFilterType } from './CmsTableFilter';

//#region Calculators

// Module pour l'utilisation des filter et sort fuzzy
declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

//#region Filtering

/**
 * Filtre fuzzy, ce filtre diffère du contient par le fait qu'il utilise le match-sorter.
 * Il est donc plus permissif et permet de trouver des résultats même si la recherche est incomplète.
 * Exemple: "Jean" trouvera "Jean", "Jean-Pierre", "Jeanne", "Jean-Paul" mais aussi "Je tentant" car il contient "Jean"
 * car les lettres sont présentes dans le bon ordre.
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 * @param addMeta fonction permettant d'ajouter des métadonnées à la ligne, permet de récupérer le rang de l'item
 * le rang d'un item en fuzzy détermine à quel point l'item correspond à la recherche
 */
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({ itemRank });
  return itemRank.passed;
};

const includesFilter: FilterFn<any> = (row, columnId, value) => {
  const rowValue: string = row.getValue(columnId);
  if (!rowValue) return false;
  return !!rowValue && rowValue.toString().toLocaleLowerCase().includes(value.toLocaleLowerCase());
};

/**
 * Filtre de type texte, permet de filtrer les colonnes de type texte avec les 4 types de filtres suivants:
 * - StringEqual: égalité stricte
 * - StringBegin: commence par
 * - StringExclude: ne contient pas
 * - StringLike: recherche fuzzy
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 * @param addMeta fonction permettant d'ajouter des métadonnées à la ligne, permet de récupérer le rang de l'item
 */
const textFilter: FilterFn<any> = (row, columnId, rawValue, addMeta) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  const rowValue: string = row.getValue(columnId);
  if (!rowValue) return false;
  const rowLowerValue = rowValue.toString().toLocaleLowerCase();
  const valueLower = value.toLocaleLowerCase();
  if (type === CmsTableFilterType.StringEqual) return rowLowerValue === valueLower;
  if (type === CmsTableFilterType.StringBegin) return rowLowerValue.startsWith(valueLower);
  if (type === CmsTableFilterType.StringExclude) return !rowLowerValue.includes(valueLower);
  if (type === CmsTableFilterType.StringLike) return rowLowerValue.includes(valueLower);
  else return true;
};

/**
 * Recherche pour le filtre de type search, permet de filtrer les colonnes de type texte avec les 5 types de filtres suivants:
 * - SearchFuzzy: recherche fuzzy
 * - SearchEqual: égalité stricte
 * - SearchBegin: commence par
 * - SearchExclude: ne contient pas
 * - SearchLike: contient
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 * @param addMeta fonction permettant d'ajouter des métadonnées à la ligne, permet de récupérer le rang de l'item
 */
const searchFilter: FilterFn<any> = (row, columnId, rawValue, addMeta) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  const rowValue: string = row.getValue(columnId);
  if (!rowValue) return false;
  const rowLowerValue = rowValue.toString().toLocaleLowerCase();
  const valueLower = value.toString().toLocaleLowerCase();
  if (type === CmsTableFilterType.SearchFuzzy) return fuzzyFilter(row, columnId, value, addMeta);
  if (type === CmsTableFilterType.SearchEqual) return rowLowerValue === valueLower;
  if (type === CmsTableFilterType.SearchBegin) return rowLowerValue.startsWith(valueLower);
  if (type === CmsTableFilterType.SearchExclude) return !rowLowerValue.includes(valueLower);
  if (type === CmsTableFilterType.SearchLike) return rowLowerValue.includes(valueLower);
  else return true;
};

/**
 * Filtre de type nombre, permet de filtrer les colonnes de type nombre avec les 4 types de filtres suivants:
 * - NumberEqual: égalité stricte
 * - NumberGreater: supérieur
 * - NumberLess: inférieur
 * - NumberBetween: entre deux valeurs (bornes incluses)
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const numberFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  const rowValue: number = row.getValue(columnId);
  if (rowValue === undefined || rowValue === null) return false;
  if (type === CmsTableFilterType.NumberEqual) return rowValue === value;
  if (type === CmsTableFilterType.NumberGreater) return rowValue > value;
  if (type === CmsTableFilterType.NumberLess) return rowValue < value;
  if (type === CmsTableFilterType.NumberBetween)
    return rowValue >= (value.min ?? -9999999999) && rowValue <= (value.max ?? 9999999999);
  return true;
};

/**
 * Filtre de type date, permet de filtrer les colonnes de type date avec les 4 types de filtres suivants:
 * - DateEqual: égalité stricte
 * - DateGreater: supérieur ou égal
 * - DateLess: inférieur ou égal
 * - DateBetween: entre deux valeurs
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const dateFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  let rowValue: Date = new Date(row.getValue(columnId));
  if (!Utils.isDate(rowValue)) return false;
  rowValue.setHours(0, 0, 0, 0);
  if (type === CmsTableFilterType.DateEqual) return rowValue.getTime() === value.getTime();
  else if (type === CmsTableFilterType.DateGreater) return rowValue.getTime() >= value.getTime();
  else if (type === CmsTableFilterType.DateLess) return rowValue.getTime() <= value.getTime();
  else if (type === CmsTableFilterType.DateBetween) {
    return (
      rowValue.getTime() >= (value?.start?.getTime() ?? -9999999999) &&
      rowValue.getTime() <= (value?.end?.getTime() ?? 9999999999)
    );
  } else return true;
};

/**
 * Filtre de type date UTC, utilisez le filtre dateFilter par défaut dans la plupart des cas.
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const utcDateFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  const rowValue: Date = new Date(row.getValue(columnId));
  if (!Utils.isDate(rowValue)) return false;
  const rowUtcValue = new Date(rowValue.getTime() - rowValue.getTimezoneOffset() * 60000);
  rowUtcValue.setHours(0, 0, 0, 0);
  const filterUtcValue = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
  filterUtcValue.setHours(0, 0, 0, 0);
  if (type === CmsTableFilterType.DateEqual) return rowUtcValue.getTime() === filterUtcValue.getTime();
  else if (type === CmsTableFilterType.DateGreater) return rowUtcValue.getTime() >= filterUtcValue.getTime();
  else if (type === CmsTableFilterType.DateLess) return rowUtcValue.getTime() <= filterUtcValue.getTime();
  else if (type === CmsTableFilterType.DateBetween) {
    return (
      rowUtcValue.getTime() >= (value?.start?.getTime() ?? -9999999999) &&
      rowUtcValue.getTime() <= (value?.end?.getTime() ?? 9999999999)
    );
  } else return true;
};

/**
 * Filtre de type "date du lundi", retourne les dates du lundi de la semaine de la date sélectionnée
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const mondayDateFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  const { value, type } = rawValue;
  value.setHours(0, 0, 0, 0);
  const day = value.getDay() - 1;
  value.setDate(value.getDate() - day);
  const rowValue: Date = new Date(row.getValue(columnId));
  if (!Utils.isDate(rowValue)) return false;
  if (type === CmsTableFilterType.DateEqual) return rowValue.getTime() === value.getTime();
  else if (type === CmsTableFilterType.DateGreater) return rowValue >= value;
  else if (type === CmsTableFilterType.DateLess) return rowValue <= value;
  else if (type === CmsTableFilterType.DateBetween) {
    const result = !!value.min ? rowValue >= value.min : true;
    return !!value.max ? rowValue <= value.max : result;
  } else return true;
};

/**
 * Filtre de type select (gère le multi-select)
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param rawValue valeur du filtre
 */
const selectFilter: FilterFn<any> = (row, columnId, rawValue) => {
  if (!rawValue) return true;
  if (Array.isArray(rawValue)) return rawValue.includes(row.getValue(columnId));
  return rawValue === row.getValue(columnId);
};

/**
 * Filtre de type contient
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const containsFilter: FilterFn<any> = (row, columnId, value) =>
  Array.isArray(value) ? value.includes(row.getValue(columnId)) : true;

/**
 * Filtre de type contient certains éléments, (si une ligne contient au moins un des éléments du tableau de recherche)
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const containsSomeFilter: FilterFn<any> = (row, columnId, value) => {
  if (!Array.isArray(value)) return true;
  const rowValue = row.getValue(columnId);
  if (!Array.isArray(rowValue)) return false;
  return value.some((x) => rowValue?.includes(x) ?? false);
};

/**
 * Filtre de type contient certains éléments, (si une ligne contient au moins un des éléments du tableau de recherche)
 * La différence par rapport au filtre containsSomeFilter est que les éléments du tableau de recherche sont des objets
 * dans lequel nous allons rechercher l'attribut id
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const containsSomeIdFilter: FilterFn<any> = (row, columnId, value) => {
  if (!Array.isArray(value)) return true;
  const rowValue = row.getValue(columnId);
  if (!Array.isArray(rowValue)) return false;
  return value.some((x) => rowValue?.find((y) => y.id === x) ?? false);
};

/**
 * Filtre de type égalité stricte
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const exactFilter: FilterFn<any> = (row, columnId, value) => {
  if (value === undefined || value === null) return true;
  return row.getValue(columnId) === value;
};

/**
 * Filtre de type égalité stricte pour les dates
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const exactDateFilter: FilterFn<any> = (row, columnId, value) => {
  if (!isDate(value)) return true;
  let date: Date = row.getValue(columnId);
  if (!isDate(date)) return false;
  return Utils.Date.pureDate(date).getTime() === Utils.Date.pureDate(value).getTime();
};

/**
 * Filtre de type inférieur ou égal
 * @param row ligne du tableau
 * @param columnId id de la colonne du tableau
 * @param value valeur du filtre
 */
const lessOrEqualFilter: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId) as number | undefined;
  return !!rowValue && rowValue <= value;
};

//#endregion

//#region Sorting

/**
 * Fonction de tri fuzzy, ce tri diffère du tri alphanumérique par le fait qu'il utilise le match-sorter.
 * @param rowA Ligne A
 * @param rowB Ligne B
 * @param columnId id de la colonne du tableau
 */
const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId])
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank!, rowB.columnFiltersMeta[columnId]?.itemRank!);
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// Comparaison "Naturelle" des strings (c'est-à-dire qu'il va tenter de sort par nombre si possible)
const compare = (a?: string, b?: string) =>
  (a?.toString() ?? '').localeCompare(b?.toString() ?? '', undefined, { numeric: true, sensitivity: 'base' });

// Fonction de tri pour les strings
const StringSortingFn = (a?: string, b?: string, desc = false) => (a === b ? 0 : desc ? compare(a, b) : compare(b, a));

// Fonction de tri pour les strings avec les undefined en dernier
const StringOnlySortingFn = (a?: string, b?: string, desc = false) =>
  a === b ? 0 : !a ? 1 : !b ? -1 : desc ? compare(a, b) : compare(b, a);

// Fonction de tri pour les booléens
const BooleanSortingFn = (a?: boolean, b?: boolean, desc = false) => (a === b ? 0 : desc ? (a ? -1 : 1) : a ? 1 : -1);

// Fonction de tri pour les booléens avec les undefined en dernier
const BooleanOnlySortingFn = (a?: boolean, b?: boolean, desc = false) =>
  a === b ? 0 : a === undefined ? 1 : b === undefined ? -1 : desc ? (a ? -1 : 1) : a ? 1 : -1;

// Fonction de tri pour les nombres
const NumberSortingFn = (a?: number, b?: number, desc = false) =>
  a === b ? 0 : desc ? ((a ?? 0) > (b ?? 0) ? -1 : 1) : (a ?? 0) > (b ?? 0) ? 1 : -1;

// Fonction de tri pour les nombres avec les undefined en dernier
const NumberOnlySortingFn = (a?: number, b?: number, desc = false) =>
  a === b ? 0 : !a ? 1 : !b ? -1 : desc ? (a > b ? -1 : 1) : a > b ? 1 : -1;

// Fonction de tri pour les dates
const SortingDateFn = (a?: Date, b?: Date, desc = false) =>
  a === b ? 0 : desc ? ((a ?? 0) > (b ?? 0) ? -1 : 1) : (a ?? 0) > (b ?? 0) ? 1 : -1;

// Fonction de tri pour les dates avec les undefined en dernier
const SortingDateOnlyFn = (a?: Date, b?: Date, desc = false): number =>
  a === b ? 0 : !a ? 1 : !b ? -1 : desc ? (a > b ? -1 : 1) : a > b ? 1 : -1;

//#endregion

const CmsFilterCalculator = {
  fuzzy: fuzzyFilter,
  text: textFilter,
  search: searchFilter,
  number: numberFilter,
  date: dateFilter,
  utcDate: utcDateFilter,
  mondayDate: mondayDateFilter,
  select: selectFilter,
  contains: containsFilter,
  containsSome: containsSomeFilter,
  containsSomeId: containsSomeIdFilter,
  includes: includesFilter,
  exact: exactFilter,
  exactDate: exactDateFilter,
  lessOrEqual: lessOrEqualFilter,
  sort: {
    fuzzy: fuzzySort,
    date: SortingDateFn,
    string: StringSortingFn,
    boolean: BooleanSortingFn,
    number: NumberSortingFn,
  },
  pureSort: {
    date: SortingDateOnlyFn,
    string: StringOnlySortingFn,
    boolean: BooleanOnlySortingFn,
    number: NumberOnlySortingFn,
  },
};

export default CmsFilterCalculator;

//#endregion
