import React, { FC, useEffect, useRef, useState } from 'react';
import APIRoute from '../../../constant/API.constant';
import { CmsForm, CmsFormPaper, CmsFormSubmit } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import { BlFixedCostContribution, BlFixedCostContributionLine } from 'interface/BlType';
import { CmsPaper } from 'component/shared/Ui';
import CRUD from 'service/CRUD.service';
import { useNavigate } from 'react-router-dom';
import NotificationService from 'service/NotificationService';
import { Buttons } from 'component/shared';
import { CellDndContext, CmsColumnDndDef, CmsDndTable } from 'component/table/CmsDndTable';
import CmsIcon from 'component/shared/CmsIcon';
import { DndInput } from '../../../component/table/DnDTableCell';
import Utils from 'helper/Utils';

function getNewId(list: BlFixedCostContributionLine[]): number {
  for (let i = 0; i < 1000; i++) {
    const newId = +(-Math.random() * 100000).toFixed(0);
    if (list.find((x) => x.id === newId)) continue;
    return newId;
  }
  return -1;
}

const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    let list = [...(x.cell.stateHandler.state as BlFixedCostContributionLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) NotificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  return (
    <div className="flex-center">
      <CmsIcon icon="delete" tooltip="Supprimer" onClick={handleDelete} />
      {!(Utils.isDefined(x.row.original.caMin) && Utils.isDefined(x.row.original.fixedCostPercentage)) && (
        <CmsIcon
          key="warning"
          icon="warning"
          style={{ color: 'yellow', margin: '-0.2rem 0' }}
          textPos="left"
          tooltip="Cette ligne n'est pas entièrement remplie, elle sera donc ignorée lors de la sauvegarde du formulaire"
        />
      )}
    </div>
  );
};

const fixedCostConfigList: CmsColumnDndDef<any>[] = [
  { header: "Chiffre d'affaires minimum", id: 'caMin', size: 400, cell: DndInput.Number },
  {
    header: 'Pourcentage de coût fixe (en %)',
    id: 'fixedCostPercentage',
    cell: DndInput.Number,
    size: 400,
  },

  { header: 'Actions', id: 'action', size: 20, cell: handleActions },
];

export const BlFixedCostContributionCreateEdit: FC = ({ id }: any) => {
  const [state, setState] = React.useState<any[]>([]);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [isFolded, setIsFolded] = useState(!!id);
  const [newLegalEntityId, setNewLegalEntityId] = useState<number>();
  const [updateLegalEntityId, setUpdateLegalEntityId] = useState<number>();
  const [updateLegalEntity, setUpdateLegalEntity] = useState<boolean>(false);
  const [newServiceId, setNewServiceId] = useState<number | null>();
  const [resetService, setResetService] = useState<boolean>(false);
  const [serviceOptionList, setServiceOptionList] = useState<any>([]);

  const navigate = useNavigate();
  const legalEntityId = useRef<number | undefined | null>(null);
  const serviceId = useRef<number | undefined | null>(null);

  useEffect(() => {
    if (newLegalEntityId !== legalEntityId.current)
      if (legalEntityId.current) setResetService(true);
      else setResetService(false);
    const route = APIRoute.SfService + (newLegalEntityId ? `/ByLegalEntity/${newLegalEntityId}` : '');
    CRUD.getList(route).then(setServiceOptionList);
    legalEntityId.current = newLegalEntityId;
    setUpdateLegalEntity(false);
  }, [newLegalEntityId]);

  function SortCaMin() {
    const sortedState = [...state].sort((a: any, b: any) => (a.caMin > b.caMin ? 1 : -1));
    // Vérifie si l'état trié est différent avant de mettre à jour
    if (JSON.stringify(state) !== JSON.stringify(sortedState)) {
      setState(sortedState);
    }
  }

  useEffect(() => {
    if (newServiceId) {
      if (!legalEntityId.current) {
        CRUD.getById(`${APIRoute.ClLegalEntity}/ByService`, newServiceId).then((response: any) => {
          setUpdateLegalEntityId(response.id);
          setUpdateLegalEntity(true);
          legalEntityId.current = response.id;
        });
      }
    }
    setResetService(false);
    serviceId.current = newServiceId;
  }, [newServiceId]);

  const onSubmitAll = (data: any, goBackTo?: boolean) => {
    const payload = {
      ...data,
      blFixedCostContributionLine: state.filter(
        (line) => Utils.isDefined(line.caMin) && Utils.isDefined(line.fixedCostPercentage),
      ),
    };
    return CRUD.post<BlFixedCostContribution>(
      APIRoute.BlFixedCostContribution + (id ? '/All' : ''),
      payload,
      !!id,
    ).then((result: BlFixedCostContribution) => {
      NotificationService.success('Les modifications ont bien été enregistrées');
      if (!id) navigate(`/castres/billing/fixedcost/${result.id}/edit`);
      if (goBackTo) navigate('/castres/billing/fixedcost/list');
      setState(result.blFixedCostContributionLine ?? []);
      return result;
    });
  };
  return [
    <>
      <CmsForm
        id={id}
        currentUrl="/castres/billing/fixedcost/"
        route={APIRoute.BlFixedCostContribution}
        thin
        onSubmit={(data: any, _, isRedirect?: boolean) => onSubmitAll(data, isRedirect)}
        onGetEditData={(fisedCost: BlFixedCostContribution) => {
          setState(
            fisedCost?.blFixedCostContributionLine?.sort((a: any, b: any) => (a.caMin >= b.caMin ? 1 : -1)) ?? [],
          );
          return fisedCost;
        }}
        renderForm={(form) => {
          if (firstRender) {
            setFirstRender(false);
            form.trigger(); // sinon le formulaire n'est pas validable lors du premier chargement de la page
          }
          const data = form.watch();
          if (newServiceId !== form.watch('serviceId')) setNewServiceId(form.watch('serviceId'));
          if (newLegalEntityId !== form.watch('legalEntityId')) setNewLegalEntityId(form.watch('legalEntityId'));
          if (isFolded)
            return (
              <CmsPaper>
                <div className="flex-h space-between">
                  <h3>
                    Coûts fixes: {data.serviceLabel} - {data.startDate}
                  </h3>
                  <Buttons.Valid style={{ margin: '0 0.3rem 0 auto' }} onClick={() => setIsFolded(false)}>
                    Déplier
                  </Buttons.Valid>
                  <CmsFormSubmit withoutDivider type={!!id ? 'editForm' : 'createForm'} />
                </div>
              </CmsPaper>
            );
          return (
            <CmsFormPaper
              contentType={!!id ? 'editForm' : 'createForm'}
              title="Coûts fixes d'intervention"
              actions={id ? [<Buttons.Valid onClick={() => setIsFolded(true)}>Replier</Buttons.Valid>] : []}
              noReturn
            >
              <CmsFormInput.Select
                id="legalEntityId"
                label="Entité légale"
                options={APIRoute.ClLegalEntity + '/Simplified'}
                required
                updateFieldIf={() => {
                  if (!serviceId.current || !!newLegalEntityId) return { update: false, value: null };
                  else return { update: updateLegalEntity, value: updateLegalEntityId };
                }}
              />
              <CmsFormInput.Select
                id="serviceId"
                label="Service"
                options={serviceOptionList}
                optionLabel={'labelWithAgency'}
                required
                updateFieldIf={() => {
                  return { update: resetService && !!serviceId.current, value: null };
                }}
              />
              <CmsFormInput.Date id="startDate" label="Date de début de validité" required />
            </CmsFormPaper>
          );
        }}
      />
      {!!id && (
        <CmsDndTable
          title="Coût par tranches de chiffre d'affaires"
          stateHandler={{ state, setState }}
          columns={fixedCostConfigList}
          actions={[
            <Buttons.Nav.Add onClick={() => setState([...state, { id: getNewId(state) }])} title="Ajouter une ligne" />,
          ]}
          onRowBlur={SortCaMin}
          paperStyle={{ width: '1000px' }}
          withoutDnd
        />
      )}
    </>,
  ];
};
