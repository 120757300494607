import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import { ClClient, ClContact, ClStation } from '../../../interface/ClType';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import React, { FC } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import ROLE from '../../../constant/role.constant';
import CRUD from '../../../service/CRUD.service';
import APIRoute from '../../../constant/API.constant';
import notificationService from '../../../service/NotificationService';
import {
  getCompanyLeaderData,
  getCompanySupplierData,
  InseeCompanyResource,
  InseeDirigeantResource,
} from '../../../component/other/InseeImport';
import { CmsButton } from '../../../component/shared/Ui';
import { UI } from '../../../component/shared';
import CmsIcon from '../../../component/shared/CmsIcon';
import { OpenInNewNavigationButton } from '../../../component/shared/Buttons';
import Utils from '../../../helper/Utils';

//#region Import Client Contact

const ClContactImportConfigList: CmsColumnDef<ClContact>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Qualité', id: 'job', Filter: CmsTableFilter.Text },
];

interface ImportClientContactProps {
  client: ClClient;
  existingContact: ClContact[];
  setExistingContact: (x: ClContact[]) => void;
}

export const ImportClientContact: FC<ImportClientContactProps> = ({ client, existingContact, setExistingContact }) => {
  const [newContact, setNewContact] = React.useState<ClContact[] | null>(null);
  const [fusionContact, setFusionContact] = React.useState<ClContact | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openFusion, setOpenFusion] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  if (!AccessFilter([ROLE.ADMIN_CLIENT_EDIT])) return <></>;
  const handleAddImport = (data: any) => {
    setLoading(true);
    CRUD.post<ClContact>(APIRoute.ClContact, data)
      .then((result) => {
        setExistingContact([result, ...existingContact]);
        setNewContact(newContact?.filter((x) => x.index !== data.index) ?? []);
      })
      .finally(() => setLoading(false));
  };

  const handleFusionOpen = (data: any) => {
    setOpenFusion(true);
    setFusionContact(data);
  };

  const handleFusion = (data: any) => {
    const payload = {
      ...data,
      firstname: fusionContact?.firstname,
      lastname: fusionContact?.lastname,
      job: fusionContact?.job,
    } as ClContact;
    setLoading(true);
    CRUD.put<ClContact>(APIRoute.ClContact, payload)
      .then((result) => {
        setExistingContact([result, ...existingContact.filter((x) => x.id !== data.id)]);
        setNewContact(newContact?.filter((x) => x.index !== fusionContact?.index) ?? []);
        setOpenFusion(false);
      })
      .finally(() => setLoading(false));
  };

  const handleImport = () => {
    if (!client?.siret) return notificationService.error('SIRET non renseigné');
    if (!client?.lastApiSync) return notificationService.error('Synchronisation API non effectuée');
    getCompanyLeaderData(client.siret).then((result) => {
      if (!result) return notificationService.error('Pas de dirigeant (personne physique) trouvé pour ce SIRET');
      const filtered = convertImportDataForContact(result, existingContact, client);
      if (filtered.length === 0) return notificationService.error('Aucun nouveau dirigeant trouvé');
      setNewContact(filtered);
      setOpen(true);
    });
  };
  const button = <CmsButton onClick={handleImport}>Importer les contacts</CmsButton>;
  if (!newContact) return button;
  return [
    button,
    <UI.Dialog fullWidth maxWidth="xl" open={open} onClose={() => setOpen(false)}>
      <CmsFrontendTable
        title="Contacts actuellement rattachés au client du CMS"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        route="none"
        initialPageSize={10}
        columns={[
          ...ClContactImportConfigList,
          {
            header: 'Action',
            id: 'none',
            size: 0,
            noClick: true,
            cell: (info) => (
              <OpenInNewNavigationButton
                target="_blank"
                title="Voir"
                to={`/castres/client/contact/${info.row.original.id}/show`}
              />
            ),
          },
        ]}
        controlledState={{ state: existingContact, setState: setExistingContact }}
        navigateTo={(id: number) => `/castres/client/contact/${id}/show`}
      />
      <CmsFrontendTable
        paperStyle={{ marginBottom: 0 }}
        title="Contacts trouvés sur l'INSEE pour ce client"
        route="none"
        initialPageSize={10}
        columns={[
          ...ClContactImportConfigList,
          {
            header: 'Action',
            id: 'none',
            size: 0,
            noClick: true,
            cell: (info) => [
              <CmsButton disabled={loading} onClick={() => handleAddImport(info.row.original)}>
                Ajouter
              </CmsButton>,
              <CmsButton
                color="warning"
                style={{ marginLeft: '1rem' }}
                disabled={loading}
                onClick={() => handleFusionOpen(info.row.original)}
              >
                Mettre à jour
              </CmsButton>,
            ],
          },
        ]}
        controlledState={{ state: newContact, setState: setNewContact }}
      />
      <UI.Dialog maxWidth="xl" open={openFusion} onClose={() => setOpenFusion(false)}>
        <CmsFrontendTable
          paperStyle={{ marginBottom: 0, minWidth: '80rem' }}
          title="Mettre à jour un contact"
          headerComponent={
            <div>
              Mettre à jour un contact ci-dessous avec les informations de l'INSEE :{fusionContact?.name},{' '}
              {fusionContact?.job}
            </div>
          }
          route="none"
          actions={[<CmsIcon icon="close" onClick={() => setOpenFusion(false)} />]}
          initialPageSize={10}
          columns={[
            ...ClContactImportConfigList,
            {
              header: 'Action',
              id: 'none',
              size: 0,
              noClick: true,
              cell: (info) => [
                <CmsButton disabled={loading} onClick={() => handleFusion(info.row.original)}>
                  Mettre à jour
                </CmsButton>,
              ],
            },
          ]}
          controlledState={{ state: existingContact, setState: setExistingContact }}
        />
      </UI.Dialog>
    </UI.Dialog>,
  ];
};

function convertImportDataForContact(
  data: InseeDirigeantResource[],
  existingContact: ClContact[],
  client: ClClient,
): ClContact[] {
  const filteredList = data.filter((x: InseeDirigeantResource) => {
    const newFullName = (x.firstname + x.lastname).toLowerCase();
    return !existingContact.find((y) => newFullName === (y.firstname + y.lastname).toLowerCase());
  });
  return filteredList.map((filtered: InseeDirigeantResource, index: number) => ({
    name: `${filtered.firstname} ${filtered.lastname}`,
    firstname: filtered.firstname,
    lastname: filtered.lastname,
    clientId: client.id,
    job: filtered.job,
    active: true,
    index,
  }));
}

//#endregion

//#region Import Client Site

const ClientStationTableConfig: CmsColumnDef<ClStation>[] = [
  { header: 'Nom', id: 'label', Filter: CmsTableFilter.Text },
  { header: 'Adresse', id: 'address', Filter: CmsTableFilter.Text },
  { header: 'Code postal', id: 'postcode', Filter: CmsTableFilter.Text, size: 0 },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  { header: 'SIRET', id: 'siret', Filter: CmsTableFilter.Text },
];

interface ImportClientStationProps {
  client: ClClient;
  existingStation: ClStation[];
  setExistingStation: (x: ClStation[]) => void;
}

export const ImportClientStation: FC<ImportClientStationProps> = ({ client, existingStation, setExistingStation }) => {
  const [newStation, setNewStation] = React.useState<ClStation[] | null>(null);
  const [fusionStation, setFusionStation] = React.useState<ClStation | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openFusion, setOpenFusion] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  if (!AccessFilter([ROLE.ADMIN_PARK_STATION_EDIT])) return <></>;
  const handleAddImport = (data: any) => {
    setLoading(true);
    CRUD.post<ClStation>(APIRoute.ClStation + '/Import', { ...data, clientImportId: client.id })
      .then((result) => {
        setExistingStation([result, ...existingStation]);
        setNewStation(newStation?.filter((x) => x.index !== data.index) ?? []);
        Utils.openInNewTab(`castres/client/station/${result.id}/show`);
      })
      .finally(() => setLoading(false));
  };

  const handleFusionOpen = (data: any) => {
    setOpenFusion(true);
    setFusionStation(data);
  };

  const handleFusion = (data: any) => {
    setLoading(true);
    CRUD.put<ClStation>(APIRoute.ClStation + '/Import', { ...fusionStation, id: data.id })
      .then((result) => {
        setExistingStation([result, ...existingStation.filter((x) => x.id !== data.id)]);
        setNewStation(newStation?.filter((x) => x.index !== fusionStation?.index) ?? []);
        setOpenFusion(false);
      })
      .finally(() => setLoading(false));
  };

  const handleImport = () => {
    if (!client?.siret) return notificationService.error('SIRET non renseigné');
    if (!client?.lastApiSync) return notificationService.error('Synchronisation API non effectuée');
    getCompanySupplierData(client.siret).then((result) => {
      if (!result) return notificationService.error('Aucun site trouvé pour ce SIRET');
      const filtered = convertImportDataForStation(result, existingStation, client);
      if (filtered.length === 0) return notificationService.error('Aucun nouveau site trouvé');
      setNewStation(filtered);
      setOpen(true);
    });
  };
  const button = <CmsButton onClick={handleImport}>Importer les sites</CmsButton>;
  if (!newStation) return button;
  return [
    button,
    <UI.Dialog fullWidth maxWidth="xl" open={open} onClose={() => setOpen(false)}>
      <CmsFrontendTable
        title="Sites actuellement rattachés au client du CMS"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        route="none"
        initialPageSize={10}
        columns={[
          ...ClientStationTableConfig,
          {
            header: 'Action',
            id: 'none',
            size: 200,
            noClick: true,
            cell: (info) => (
              <OpenInNewNavigationButton
                target="_blank"
                title="Voir"
                to={`/castres/client/station/${info.row.original.id}/show`}
              />
            ),
          },
        ]}
        controlledState={{ state: existingStation, setState: setExistingStation }}
        navigateTo={(id: number) => `/castres/client/station/${id}/show`}
      />
      <CmsFrontendTable
        paperStyle={{ marginBottom: 0 }}
        title="Sites trouvés sur l'INSEE pour ce client"
        route="none"
        initialPageSize={10}
        columns={[
          ...ClientStationTableConfig,
          {
            header: 'Action',
            id: 'none',
            size: 200,
            noClick: true,
            cell: (info) => [
              <CmsButton disabled={loading} onClick={() => handleAddImport(info.row.original)}>
                Ajouter
              </CmsButton>,
              <CmsButton
                color="warning"
                style={{ marginLeft: '1rem' }}
                disabled={loading}
                onClick={() => handleFusionOpen(info.row.original)}
              >
                Mettre à jour
              </CmsButton>,
            ],
          },
        ]}
        controlledState={{ state: newStation, setState: setNewStation }}
      />
      <UI.Dialog maxWidth="xl" open={openFusion} onClose={() => setOpenFusion(false)}>
        <CmsFrontendTable
          paperStyle={{ marginBottom: 0, minWidth: '85rem' }}
          title="Mettre à jour un site"
          headerComponent={
            <div>Mettre à jour un site ci-dessous avec les informations de l'INSEE :{fusionStation?.label}</div>
          }
          route="none"
          actions={[<CmsIcon icon="close" onClick={() => setOpenFusion(false)} />]}
          initialPageSize={10}
          columns={[
            ...ClientStationTableConfig,
            {
              header: 'Action',
              id: 'none',
              size: 100,
              noClick: true,
              cell: (info) => [
                <CmsButton disabled={loading} onClick={() => handleFusion(info.row.original)}>
                  Mettre à jour
                </CmsButton>,
              ],
            },
          ]}
          controlledState={{ state: existingStation, setState: setExistingStation }}
        />
      </UI.Dialog>
    </UI.Dialog>,
  ];
};

function convertImportDataForStation(
  data: InseeCompanyResource[],
  existingStation: ClStation[],
  client: ClClient,
): ClContact[] {
  const filteredList = data.filter((x: any) => {
    return !existingStation.find((y) => y.siret === x.siret);
  });
  return filteredList.map((x, i) => {
    return {
      index: i,
      label: x.label,
      siret: x.siret,
      address: x.address,
      postcode: x.postcode,
      city: x.city,
      latitude: x.latitude ? parseFloat(x.latitude) : null,
      longitude: x.longitude ? parseFloat(x.longitude) : null,
    } as ClStation;
  });
}

//#endregion
