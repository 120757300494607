import React, { FC, useContext, useEffect, useMemo } from 'react';
import {
  AddNavigationButton,
  ArrowBackNavigationButton,
  EditNavigationButton,
  NavigationButton,
  OpenInNewNavigationButton,
  PayLoadButton,
  ViewNavigationButton,
} from 'component/shared/Buttons';
import { SkCompany, SkSupplier } from '../../../interface/SkType';
import APIRoute from '../../../constant/API.constant';
import { Grid } from '@mui/material';
import AccessFilter from '../../../helper/AccessFilter';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import CmsTableFilter from '../../../component/table/helper/CmsTableFilter';
import { CmsForm, CmsFormSubmit, FormSkeleton } from '../../../component/form/CmsForm';
import { CmsFormInput } from 'component/form/CmsFormInput';
import {
  getCompanySupplierData,
  ImportClientInseeData,
  InseeCompanyResource,
  InseeNameCityLinkIcon,
  SiretLinkIcon,
} from '../../../component/other/InseeImport';
import { CmsButton, CmsPaper, HeaderPanel, WarningBubble } from '../../../component/shared/Ui';
// import MapCustom from '../../../component/map/GoogleMapCustom';
import { IdLabel, LabelValue } from '../../../interface/CommonType';
import { useLocation, useNavigate } from 'react-router-dom';
import CRUD from '../../../service/CRUD.service';
import LoadingScreen from '../../../component/LoadingScreen';
import { LabelValueIf, TabStyleDataView } from '../../../component/shared/TabStyleDataView';
import { UI } from '../../../component/shared';
import { SkSupplierListConfig } from './SkSupplier.pack';
import notificationService from '../../../service/NotificationService';
import { GlobalContext } from '../../../context/Global.context';
import Utils from '../../../helper/Utils';
import CmsIcon from '../../../component/shared/CmsIcon';
import ROLE from '../../../constant/role.constant';
import { OsmMapShow } from '../../../component/map/OsmMap';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import { BASE_URL } from 'constant/API_URL';

const codesNaf: IdLabel[] = require('@socialgouv/codes-naf/index.json');
const codesNafWithRef = codesNaf.map((x) => ({ id: x.id, label: `${x.id} - ${x.label}` }));

const SkCompanyListConfig: CmsColumnDef<SkCompany>[] = [
  { header: 'Référence', id: 'ref', Filter: CmsTableFilter.Text },
  { header: 'Raison sociale', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Nom commercial', id: 'commercialName', Filter: CmsTableFilter.Text },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  { header: 'Téléphone', id: 'tel', Filter: CmsTableFilter.Text },
  { header: 'SIRET valide', id: 'haveSiret', Filter: CmsTableFilter.Bool, size: 20, hide: true },
  { header: 'SIRET', id: 'siret', Filter: CmsTableFilter.Text, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour le', id: 'sirenUpdatedAt', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  { header: 'SIRET mis à jour par', id: 'sirenUpdatedByName', Filter: CmsTableFilter.Select, hide: 'hiddenByDefault' },
  {
    header: 'APE',
    id: 'ape',
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: codesNafWithRef, multiple: true },
    cell: (info) => codesNafWithRef.find((x) => x.id === info.getValue())?.label ?? info.getValue(),
    hide: 'hiddenByDefault',
  },
  { header: 'Date synchronisation API', id: 'lastApiSync', Filter: CmsTableFilter.Date, hide: 'hiddenByDefault' },
  {
    header: 'Synchronisation API par',
    id: 'lastApiSyncByName',
    Filter: CmsTableFilter.Select,
    hide: 'hiddenByDefault',
  },
  { header: 'Synchronisé API', id: 'isApiSync', Filter: CmsTableFilter.Bool, size: 20, hide: 'hiddenByDefault' },
  {
    header: <div className="text-center w100">Annuaire... </div>,
    id: 'none1',
    backgroundColor: {
      dark: 'rgb(48,53,66)',
      light: 'rgb(213,208,181)',
    },
    columns: [
      {
        header: 'Choisi',
        id: 'none2',
        cell: (info) => <SiretLinkIcon siret={info.row.original.siret} />,
        size: 0,
        noClick: true,
      },
      {
        header: 'Proposé',
        id: 'none3',
        cell: (info) => <InseeNameCityLinkIcon name={info.row.original.name} city={info.row.original.city} />,
        size: 0,
        noClick: true,
      },
    ],
  },
  { header: 'Bloqué', id: 'locked', Filter: CmsTableFilter.Bool, hide: 'hiddenByDefault', defaultFilterValue: false },
  {
    header: 'En activité',
    id: 'active',
    Filter: CmsTableFilter.Bool,
    hide: 'hiddenByDefault',
    defaultFilterValue: true,
  },
  {
    header: (
      <div className="flex-h align-center">
        Dupliqué
        <CmsIcon
          icon="info"
          tooltip="Fournisseur en doublon dont il en reste à bloquer pour qu'ils puissent être fusionnés plus tard (seules les entités ayant fait l'objet d'un import INSEE seront fusionnés)"
        />
      </div>
    ),
    id: 'haveDuplicatedSiret',
    hide: 'hiddenByDefault',
    size: 0,
    Filter: CmsTableFilter.Bool,
    noClick: true,
    cell: (info) => (
      <CmsTableCell.BoolWithInfo
        cell={info}
        tooltip={info.row.original?.duplicatedSiret?.map(({ label }) => `${label}`).join(' || ')}
        url={`${BASE_URL}castres/stock/company/list?t-siret=${info.row.original?.siret}=1`}
      />
    ),
  },
  { header: 'Sous-traitant', id: 'isSubcontractor', hide: 'hiddenByDefault', Filter: CmsTableFilter.Bool },
];
/**
 * Liste des fournisseurs
 */
export const SkCompanyList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_CREATE])
    ? [<AddNavigationButton title="Ajouter un fournisseur" to="/castres/stock/company/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des fournisseurs"
      columns={SkCompanyListConfig}
      route={APIRoute.SkCompany}
      downloadable="csv&excel"
      navigateTo={(id: number) => `/castres/stock/company/${id}/show`}
      dataBuilder={mapRefInList}
      actions={[<CmsIcon href="/help/Fournisseurs/" icon="info" tooltip="Aide" target="_blank" />, ...actions]}
      setFiltersInUrl
    />
  );
};

const mapRefInList = (list: SkCompany[]) =>
  list?.map((x) => ({ ...x, ref: `F${x.id.toString().padStart(8, '0')}` })) ?? [];

export const SkCompanyShow: FC = ({ id }: any) => {
  const [company, setCompany] = React.useState<SkCompany | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/castres/stock/company/list');
    else CRUD.getById<SkCompany>(APIRoute.SkCompany, id).then(setCompany);
  }, [navigate, id]);

  if (!company) return <LoadingScreen />;
  return (
    <>
      <HeaderPanel
        title={`Fournisseur: ${company?.name}${company?.commercialName ? ` (${company?.commercialName})` : ''}`}
        subtitle={`(${company.ref})`}
        actions={[
          <ArrowBackNavigationButton title="Retourner à la liste" to="/castres/stock/company/list" />,
          AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT]) && (
            <EditNavigationButton title="Éditer le fournisseur" to={`/castres/stock/company/${company?.id}/edit`} />
          ),
        ]}
      />
      <Locker company={company} />
      {!company.locked && <WarnDuplicateSiret siret={company.siret} id={id} />}
      {!!company.latitude && !!company.longitude && (
        <CmsPaper>
          <OsmMapShow setCenter={{ lat: company.latitude, lng: company.longitude }} />
        </CmsPaper>
      )}
      <Grid container spacing={2}>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Contact">
            <TabStyleDataView conf={CompanyContactConfig} mapFrom={company} />
          </CmsPaper>
        </Grid>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Général">
            <TabStyleDataView conf={CompanyGeneralConfig} mapFrom={company} />
          </CmsPaper>
        </Grid>
        <Grid item sm={12} lg={4}>
          <CmsPaper title="Administratif" actions={[<SiretLinkIcon siret={company.siret} />]}>
            <TabStyleDataView conf={CompanyBankConfig} mapFrom={company} />
          </CmsPaper>
        </Grid>
      </Grid>
      <SkCompanySupplierList company={company} />
    </>
  );
};

const CompanyGeneralConfig: LabelValue[] = [
  { label: 'Raison sociale', value: (x: SkCompany) => x.name },
  { label: 'Nom commercial', value: (x: SkCompany) => x.commercialName },
  { label: 'Référence (auto-généré)', value: (x: SkCompany) => x.ref },
  { label: 'Bloqué', value: (x: SkCompany) => (x.locked ? 'Oui' : 'Non') },
  { label: 'Commentaire de blocage', value: (x: SkCompany) => x.lockComment },
  { label: 'Date création entreprise', value: (x: SkCompany) => x.companyCreationDate },
  { label: 'En activité', value: (x: SkCompany) => (x.active ? 'Oui' : 'Non') },
  { label: 'Sous-traitant', value: (x: SkCompany) => (x.isSubcontractor ? 'Oui' : 'Non') },
];
const CompanyContactConfig: LabelValueIf[] = [
  { label: 'Manager', value: (x: SkCompany) => x.manager },
  { label: 'Adresse', value: (x: SkCompany) => <UI.Multiline text={x.address} /> },
  { label: 'Code Postal', value: (x: SkCompany) => x.postcode },
  { label: 'Ville', value: (x: SkCompany) => x.city },
  { label: 'Pays', value: (x: SkCompany) => x.country, ifIs: (x: SkCompany) => !!x.country },
  { label: 'Téléphone', value: (x: SkCompany) => <UI.PhoneLink phone={x.tel} /> },
  { label: 'Email', value: (x: SkCompany) => <UI.MailLink mail={x.email} /> },
  { label: 'Site web', value: (x: SkCompany) => x.website },
];
const CompanyBankConfig: LabelValue[] = [
  { label: 'APE', value: (x: SkCompany) => codesNafWithRef.find((y) => y.id === x.ape)?.label ?? x.ape },
  { label: 'SIRET', value: (x: SkCompany) => [x.siret, <SiretLinkIcon siret={x.siret} />] },
  { label: 'SIRET mis à jour par', value: (x: SkCompany) => x.sirenUpdatedByName },
  { label: 'SIRET mis à jour le', value: (x: SkCompany) => Utils.displayDate(x.sirenUpdatedAt) },
  { label: 'TVA', value: (x: SkCompany) => x.tva },
  { label: 'Nom de la banque', value: (x: SkCompany) => x.bankLabel },
  { label: 'Ville de la banque', value: (x: SkCompany) => x.bankCity },
  { label: 'BIC', value: (x: SkCompany) => x.bic },
  { label: 'IBAN', value: (x: SkCompany) => x.iban },
  {
    label: 'Synchronisation API',
    value: (c: SkCompany) => (c.lastApiSync ? Utils.displayDate(c.lastApiSync) : 'Jamais effectuée'),
  },
  { label: 'Fait par', value: (c: SkCompany) => c.lastApiSyncByName },
];

const SkCompanySupplierList: FC<{ company: SkCompany }> = ({ company }) => {
  const [state, setState] = React.useState<SkSupplier[]>([]);
  const config = useMemo(() => SkSupplierListConfig, []);
  const payloadSettings = { title: 'Ajouter un magasin', to: '/castres/stock/supplier/create' };

  useEffect(() => {
    if (!company) return;
    CRUD.getList<SkSupplier>(APIRoute.SkSupplier + '/ByCompany/' + company.id).then(setState);
  }, [company]);

  return (
    <CmsFrontendTable
      actions={[
        <ImportSupplierCompany company={company} existingSupplier={state} setExistingSupplier={setState} />,
        <PayLoadButton {...payloadSettings} payload={{ companyId: company.id }} />,
      ]}
      route="none"
      columns={config}
      navigateTo={(id: number) => `/castres/stock/supplier/${id}/show`}
      title="Liste des magasins"
      controlledState={{ state, setState }}
    />
  );
};

/**
 * Création ou édition d'un fournisseur
 * @param id id du fournisseur à éditer
 * @param setHttpCodePage fonction pour changer le code de la page
 */
export const SkCompanyCreateEdit: FC = ({ id }: any) => {
  const title = (!id ? 'Création' : 'Édition') + " d'un fournisseur";
  const setRefAndCatchWarning = (data: any) => {
    const message =
      'Ce SIRET est déjà utilisé sur un autre fournisseur (les modifications ont tout de même été enregistrées)';
    if (data.haveDuplicatedSiret) notificationService.warning(message);
    return { ...data, ref: `F${data.id.toString().padStart(8, '0')}` };
  };
  return (
    <CmsForm
      id={id}
      currentUrl="/castres/stock/company/"
      route={APIRoute.SkCompany}
      defaultValues={{ ref: 'Auto-généré' }}
      goToOnValidForm={(id) => `/castres/stock/company/${id}/show`}
      onGetEditData={setRefAndCatchWarning}
      renderForm={(form) => {
        const data: SkCompany = form.getValues() as any;
        const readOnly = !!data.lastApiSync;
        const siret = data.siret;
        let siretTitle: any = 'SIRET';
        if (!!siret && siret.length === 14) siretTitle = ['SIRET', <SiretLinkIcon siret={siret} />];
        const see = [
          <ImportClientInseeData form={form} mandatory={!data.country && !data.lastApiSync} />,
          <NavigationButton title="Retourner à la liste" to="/castres/stock/company/list" />,
        ];
        if (!!id) see.push(<ViewNavigationButton title="Voir" to={`/castres/stock/company/${id}/show`} />);
        return (
          <CmsPaper title={title} actions={see}>
            {!data.locked && <WarnDuplicateSiret siret={siret} id={id} />}
            <FormSkeleton isLoading={!!id && !form.getValues().id} fieldNumber={10} columnNumber={3}>
              <Grid container spacing={2}>
                <Grid item sm={12} lg={4}>
                  <h2 style={{ textAlign: 'center' }}>Contact</h2>
                  <CmsFormInput.Text id="manager" label="Manager" />
                  <CmsFormInput.Address id="address" label="Adresse" readOnly={readOnly} max={100} />
                  <CmsFormInput.Text id="postcode" label="Code Postal" readOnly={readOnly} max={5} />
                  <CmsFormInput.Text id="city" label="Ville" readOnly={readOnly} max={50} />
                  <CmsFormInput.Text id="country" label="Pays (si hors France)" />
                  <CmsFormInput.Text id="tel" label="Téléphone" />
                  <CmsFormInput.Text id="email" label="Email" />
                  <CmsFormInput.Text id="website" label="Site web" />
                </Grid>
                <Grid item sm={12} lg={4}>
                  <h2 style={{ textAlign: 'center' }}>Général</h2>
                  <CmsFormInput.Text id="name" label="Raison sociale" required max={255} readOnly={readOnly} />
                  <CmsFormInput.Text id="commercialName" label="Nom commercial" max={255} />
                  <CmsFormInput.Text id="ref" label="Référence (auto-généré)" readOnly />
                  <CmsFormInput.Switch id="locked" label="Bloqué" />
                  <CmsFormInput.Text
                    id="lockComment"
                    label="Commentaire de blocage"
                    max={255}
                    disabled={data.locked && data.lockComment?.includes('Doublon')}
                    multiline
                  />
                  <CmsFormInput.Date
                    id="companyCreationDate"
                    label="Date création entreprise"
                    readOnly={readOnly}
                    dateOnlyFormat
                  />
                  <CmsFormInput.Switch id="active" label="En activité" readOnly={readOnly} />
                  <CmsFormInput.Switch id="isSubcontractor" label="Sous-traitant" readOnly={readOnly} />
                  <CmsFormInput.Number id="latitude" label="Latitude" min={-90} max={90} readOnly={readOnly} />
                  <CmsFormInput.Number id="longitude" label="Longitude" min={-180} max={180} readOnly={readOnly} />
                </Grid>
                <Grid item sm={12} lg={4}>
                  <h2 style={{ textAlign: 'center' }}>Administratif</h2>
                  <CmsFormInput.Select id="ape" label="APE" options={codesNafWithRef} readOnly={readOnly} />
                  <CmsFormInput.Text
                    id="siret"
                    label={siretTitle}
                    readOnly={readOnly}
                    required={!data.country}
                    min={14}
                    max={14}
                  />
                  <CmsFormInput.Text id="tva" label="TVA" readOnly={readOnly} />
                  <CmsFormInput.Text id="bankLabel" label="Nom de la banque" />
                  <CmsFormInput.Text id="bankCity" label="Ville" />
                  <CmsFormInput.Text id="bic" label="BIC" />
                  <CmsFormInput.Text id="iban" label="IBAN" />
                </Grid>
              </Grid>
            </FormSkeleton>
            <CmsFormSubmit type={id ? 'editForm' : 'createForm'} />
          </CmsPaper>
        );
      }}
    />
  );
};

const SkCompanyDuplicatesListConfig: CmsColumnDef<SkCompany>[] = [
  { header: 'Email', id: 'email', noClick: true, cell: (info) => <UI.MailLink mail={info.getValue()} /> },
  { header: 'IBAN', id: 'iban' },
  { header: 'Nombre de magasins', id: 'nbSupplier' },
  { header: 'Nombre de commandes', id: 'nbOrder' },
  { header: 'Date de dernière commande', id: 'lastOrderDate', cell: (info) => Utils.displayDate(info.getValue()) },
  { header: 'Nombre factures', id: 'nbInvoice' },
  { header: 'Date de dernière facture', id: 'lastInvoiceDate', cell: (info) => Utils.displayDate(info.getValue()) },
  {
    header: 'Action',
    id: 'none3',
    size: 0,
    noClick: true,
    cell: (info) => <LockerButton company={info.row.original} />,
  },
];

const WarnDuplicateSiret: FC<{ siret: string; id: number }> = ({ siret, id }) => {
  const [duplicateList, setDuplicateList] = React.useState<SkCompany[]>([]);
  const { theming } = useContext(GlobalContext);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const config = useMemo(() => {
    return [...SkCompanyListConfig.filter((x) => x.id !== 'siret2'), ...SkCompanyDuplicatesListConfig];
  }, []);

  useEffect(() => {
    if (!siret) return;
    CRUD.getList<SkCompany>(APIRoute.SkCompany + '/BySiret/' + siret).then((result) => {
      let notLocked: any[] = result.filter((x) => !x.locked);
      if (notLocked.find((x) => x.id === +id))
        notLocked.find((x) => x.id === +id).rowColor = theming.get().cms.color.blue;
      setDuplicateList(mapRefInList(notLocked));
    });
  }, [id, siret, theming]);

  if (!siret || siret.length !== 14 || duplicateList.length < 2) return <></>;
  if (location.search.includes('toBlock=true')) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} customColor="#FF0000">
      <span style={{ fontWeight: 'bold' }}>Attention ! </span>
      <span>
        Ce SIRET est utilisé sur plusieurs fournisseurs. S'il s'agit de doublons, veuillez les bloquer pour n'en garder
        qu'un, sinon corrigez les SIRET.
      </span>
      <CmsButton className="hint-button" style={{ marginLeft: '0.5rem' }} color="inherit" onClick={() => setOpen(true)}>
        Voir la liste
      </CmsButton>
      <UI.Dialog fullWidth maxWidth={false} open={open} onClose={() => setOpen(false)}>
        <CmsFrontendTable
          columns={config}
          title="Doublons potentiels trouvés (vous êtes actuellement sur le fournisseur en bleu)"
          route="none"
          invertClick
          navigateTo={(id: number) => `/castres/stock/company/${id}/show?toBlock=true`}
          paperStyle={{ marginBottom: 0 }}
          controlledState={{ state: duplicateList, setState: setDuplicateList }}
        />
      </UI.Dialog>
    </WarningBubble>
  );
};

const Locker: FC<{ company: SkCompany }> = ({ company }) => {
  const location = useLocation();
  if (!location.search.includes('toBlock=true') || company.locked) return <></>;
  if (!AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_COMPANY_EDIT])) return <></>;
  return (
    <WarningBubble style={{ marginBottom: '1rem' }} customColor="#FF0000">
      <span style={{ fontWeight: 'bold' }}>Attention ! </span>
      <span> Ce fournisseur est potentiellement un doublon. </span>
      <LockerButton company={company} />
    </WarningBubble>
  );
};

const LockerButton: FC<{ company: SkCompany }> = ({ company }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleBlock = () => {
    const payload = { id: company.id };
    CRUD.put<SkCompany>(APIRoute.SkCompany + '/Lock', payload).then(() => {
      setOpen(false);
      notificationService.info('Fournisseur bloqué');
      navigate(0);
    });
  };

  return (
    <>
      <CmsButton onClick={() => setOpen(true)}>Bloquer</CmsButton>
      <UI.Dialog open={open} onClose={() => setOpen(false)}>
        <CmsPaper title="Bloquer un fournisseur en doublon" style={{ marginBottom: 0 }}>
          <p>Assurez-vous qu'il s'agit bien d'un fournisseur en doublon.</p>
          <p>Le champ "Commentaire de blocage" sera rempli avec : "Doublon bloqué le XX/XX/20XX par XXX".</p>
          <p>
            Cette action n'est pas définitive. Pour revoir un fournisseur bloqué, accédez à la liste des fournisseurs et
            décochez "Bloqué" dans le filtre avancé.
            <br />
            Vous pourrez alors le débloquer.
          </p>
          <UI.ButtonHolder divider>
            <CmsButton onClick={() => setOpen(false)}>Annuler</CmsButton>
            <CmsButton onClick={handleBlock}>Bloquer</CmsButton>
          </UI.ButtonHolder>
        </CmsPaper>
      </UI.Dialog>
    </>
  );
};

const SkSupplierImportConfigList: CmsColumnDef<SkSupplier>[] = [
  { header: 'Nom', id: 'name', Filter: CmsTableFilter.Text },
  { header: 'Code Postal', id: 'postcode', Filter: CmsTableFilter.Text, size: 0 },
  { header: 'Ville', id: 'city', Filter: CmsTableFilter.Text },
  { header: 'Adresse', id: 'address', Filter: CmsTableFilter.Text },
  { header: 'SIRET', id: 'siret', Filter: CmsTableFilter.Text, size: 0 },
];

interface ImportSupplierCompanyProps {
  company: SkCompany;
  existingSupplier: SkSupplier[];
  setExistingSupplier: (x: SkSupplier[]) => void;
}

const ImportSupplierCompany: FC<ImportSupplierCompanyProps> = ({ company, existingSupplier, setExistingSupplier }) => {
  const [newSupplier, setNewSupplier] = React.useState<SkSupplier[] | null>(null);
  const [fusionSupplier, setFusionSupplier] = React.useState<SkSupplier | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openFusion, setOpenFusion] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const canAccess = AccessFilter([ROLE.ADMIN_CONFIGURATION_STOCK_SUPPLIER_CREATE]);
  if (!company?.lastApiSync || !company.siret || !canAccess) return <></>;
  const handleAddImport = (data: any) => {
    setLoading(true);
    data.lastApiSync = new Date();
    CRUD.post<SkSupplier>(APIRoute.SkSupplier, data)
      .then((result) => {
        setExistingSupplier([result, ...existingSupplier]);
        setNewSupplier(newSupplier?.filter((x) => x.index !== data.index) ?? []);
      })
      .finally(() => setLoading(false));
  };

  const handleFusionOpen = (data: any) => {
    setOpenFusion(true);
    setFusionSupplier(data);
  };

  const handleFusion = (data: any) => {
    setLoading(true);
    data.lastApiSync = new Date();
    CRUD.put<SkSupplier>(APIRoute.SkSupplier, { ...data, ...fusionSupplier })
      .then((result) => {
        setExistingSupplier([result, ...existingSupplier.filter((x) => x.id !== data.id)]);
        setNewSupplier(newSupplier?.filter((x) => x.index !== fusionSupplier?.index) ?? []);
        setOpenFusion(false);
      })
      .finally(() => setLoading(false));
  };

  const handleImport = () => {
    getCompanySupplierData(company.siret).then((result) => {
      if (!result) return notificationService.error('Aucun magasin trouvé pour ce SIRET');
      const filtered = convertImportDataForSupplier(result, existingSupplier, company);
      if (filtered.length === 0) return notificationService.error('Aucun nouveau magasin trouvé');
      setNewSupplier(filtered);
      setOpen(true);
    });
  };

  const button = <CmsButton onClick={handleImport}>Importer les magasins</CmsButton>;
  if (!newSupplier) return button;
  return [
    button,
    <UI.Dialog fullWidth maxWidth="xl" open={open} onClose={() => setOpen(false)}>
      <CmsFrontendTable
        title="Magasins actuellement rattachés au fournisseur du CMS"
        actions={[<CmsIcon icon="close" onClick={() => setOpen(false)} />]}
        route="none"
        initialPageSize={10}
        columns={[
          ...SkSupplierImportConfigList,
          {
            header: 'Action',
            id: 'none',
            size: 0,
            noClick: true,
            cell: (info) => (
              <OpenInNewNavigationButton
                target="_blank"
                title="Voir"
                to={`/castres/stock/supplier/${info.row.original.id}/show`}
              />
            ),
          },
        ]}
        controlledState={{ state: existingSupplier, setState: setExistingSupplier }}
        navigateTo={(id: number) => `/castres/stock/supplier/${id}/show`}
      />
      <CmsFrontendTable
        paperStyle={{ marginBottom: 0 }}
        title="Magasins trouvés sur l'INSEE pour ce fournisseur"
        route="none"
        initialPageSize={10}
        columns={[
          ...SkSupplierImportConfigList,
          {
            header: 'Action',
            id: 'none',
            size: 0,
            noClick: true,
            cell: (info) => [
              <CmsButton disabled={loading} onClick={() => handleAddImport(info.row.original)}>
                Ajouter
              </CmsButton>,
              <CmsButton color="warning" disabled={loading} onClick={() => handleFusionOpen(info.row.original)}>
                Mettre à jour
              </CmsButton>,
            ],
          },
        ]}
        controlledState={{ state: newSupplier, setState: setNewSupplier }}
      />
      <UI.Dialog maxWidth="xl" open={openFusion} onClose={() => setOpenFusion(false)}>
        <CmsFrontendTable
          paperStyle={{ marginBottom: 0 }}
          actions={[<CmsIcon icon="close" onClick={() => setOpenFusion(false)} />]}
          title="Mettre à jour un magasin"
          headerComponent={
            <div>
              Mettre à jour un magasin ci-dessous avec les informations de l'INSEE : {fusionSupplier?.name},{' '}
              {fusionSupplier?.city}, {fusionSupplier?.siret}
            </div>
          }
          route="none"
          initialPageSize={10}
          columns={[
            ...SkSupplierImportConfigList,
            {
              header: 'Action',
              id: 'none',
              size: 0,
              noClick: true,
              cell: (info) => (
                <CmsButton disabled={loading} onClick={() => handleFusion(info.row.original)}>
                  Mettre à jour
                </CmsButton>
              ),
            },
          ]}
          controlledState={{ state: existingSupplier, setState: setExistingSupplier }}
        />
      </UI.Dialog>
    </UI.Dialog>,
  ];
};

function convertImportDataForSupplier(
  data: InseeCompanyResource[],
  existingSupplier: SkSupplier[],
  company: SkCompany,
): SkSupplier[] {
  const filteredList = data.filter((x: InseeCompanyResource) => !existingSupplier.find((y) => y.siret === x.siret));
  return filteredList.map((filtered: InseeCompanyResource, index: number) => ({
    address: filtered.address,
    city: filtered.city,
    postcode: filtered.postcode,
    name: filtered.label,
    siret: filtered.siret,
    latitude: filtered.latitude ? parseFloat(filtered.latitude) : undefined,
    longitude: filtered.longitude ? parseFloat(filtered.longitude) : undefined,
    active: true,
    companyId: company.id,
    index,
  }));
}
