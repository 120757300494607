import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { CmsForm } from '../../../component/form/CmsForm';
import APIRoute from '../../../constant/API.constant';
import { CmsFormInput } from '../../../component/form/CmsFormInput';
import { CmsPaper } from '../../../component/shared/Ui';
import { UseFormReturn } from 'react-hook-form';
import {
  BlPriceScheduleGroup,
  BlQuotation,
  BlQuotationLine,
  BlQuotationSuggestedLine,
  BlVat,
} from '../../../interface/BlType';
import { WkIntervention } from '../../../interface/WkType';
import { FormUI, InputUI, UI } from '../../../component/shared';
import { CellDndContext, CmsColumnDndDef, CmsDndTable } from '../../../component/table/CmsDndTable';
import CmsIcon from '../../../component/shared/CmsIcon';
import notificationService from '../../../service/NotificationService';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CRUD from '../../../service/CRUD.service';
import { CmsMenuButton } from '../../../component/shared/Menu';
import { DndInput } from '../../../component/table/DnDTableCell';

//#region QuotationPriceScheduleConfig

const designationCell: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const handleIndent = (right: boolean) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    let indent = (row?.chapterLevel ?? 0) + (right ? 1 : -1);
    row.chapterLevel = indent < 0 ? 0 : indent > 5 ? 5 : indent;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  const handleSuggestionSelection = (value: any, item: any) => {
    x.cell.lineSharedFunctionList?.handleLineSuggestion(item, x.row.original);
  };

  const handleLabel = (label: string) => {
    const row = x.cell.stateHandler.state.find((y) => y.id === x.row.original.id);
    if (!row) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    row.label = label;
    x.cell.stateHandler.setState([...x.cell.stateHandler.state]);
  };

  if (x.row.original.chapter) {
    return (
      <div key={x.cell.key} className="cms-grid" style={{ gridTemplateColumns: 'auto 5rem' }}>
        <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
          <InputUI.DebouncedInput variant="standard" value={x.getValue()} valueCanUpdate onChange={handleLabel} />
        </div>
        <div className="flex-h align-center">
          <CmsIcon icon="unIndent" onClick={() => handleIndent(false)} />
          <CmsIcon icon="indent" onClick={() => handleIndent(true)} />
        </div>
      </div>
    );
  }
  if (x.row.original.freeField) {
    return (
      <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
        <InputUI.DebouncedInput variant="standard" value={x.getValue()} valueCanUpdate onChange={handleLabel} />
      </div>
    );
  }
  return (
    <div style={{ marginLeft: (x.row.original.indent ?? 0) + 'em' }}>
      <InputUI.CustomSuggest
        key={x.cell.key}
        initialValue={x.row.original.label}
        onValueSelected={handleSuggestionSelection}
        getSuggestionService={(search) => CRUD.getSuggestedList(APIRoute.BlQuotation + '/LineProposition', { search })}
        showAttribute="label"
      />
    </div>
  );
};

const handleVat: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  if (x.row.original.chapter) return [];

  const handleSelect = (value: any) => {
    let list = [...(x.cell.stateHandler.state as BlQuotationLine[])];
    const item = list.find((y) => y.id === x.row.original.id);
    if (!item) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    item!.vatId = value;
    item!.vatValue = x.cell.optionList?.find((y: BlVat) => y.id === value)?.value;
    x.cell.stateHandler.setState(list);
  };

  return (
    <InputUI.AutoCompletor
      variant="standard"
      options={x.cell.optionList ?? []}
      onChange={handleSelect}
      value={x.getValue()}
    />
  );
};

function getEndChapterIndex(table: BlQuotationLine[], startIndex: number) {
  return table.slice(startIndex).findIndex((row, i) => i !== 0 && row.chapter) || table.length - startIndex;
}

function getNewId(list: BlQuotationLine[]): number {
  for (let i = 0; i < 1000; i++) {
    const newId = +(-Math.random() * 100000).toFixed(0);
    if (list.find((x) => x.id === newId)) continue;
    return newId;
  }
  return -1;
}

const handleActions: ((info: CellDndContext<any>) => any) | undefined = (x) => {
  const getNewListAndStartIndex = (): [any[], number] => {
    let list = [...(x.cell.stateHandler.state as BlQuotationLine[])];
    const startIndex = list.findIndex((y) => y.id === x.row.original.id);
    if (startIndex === -1) notificationService.error('Erreur Dnd Table: ligne non trouvée');
    return [list, startIndex];
  };

  const handleDelete = () => {
    const [list, index] = getNewListAndStartIndex();
    list.splice(index, 1);
    x.cell.stateHandler.setState(list);
  };

  const handleChapterDelete = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, getEndChapterIndex(list, startIndex));
    x.cell.stateHandler.setState(list);
  };

  const handleChapterAddEndChapter = () => {
    const [list, startIndex] = getNewListAndStartIndex();
    let endIndex = getEndChapterIndex(list, startIndex);
    list.splice(endIndex, 0, { id: getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleAdd = (after = false) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex + (after ? 1 : 0), 0, { id: getNewId(x.cell.stateHandler.state) } as any);
    x.cell.stateHandler.setState(list);
  };

  const handleReplace = (attr: string) => {
    const [list, startIndex] = getNewListAndStartIndex();
    list.splice(startIndex, 1, { id: getNewId(x.cell.stateHandler.state), [attr]: true } as any);
    x.cell.stateHandler.setState(list);
  };

  const buttonList = x.row.original.chapter
    ? [
        <span onClick={handleDelete}>Supprimer le chapitre</span>,
        <span onClick={handleChapterDelete}>Supprimer le chapitre et son contenu</span>,
        <span onClick={handleChapterAddEndChapter}>Ajouter une nouvelle ligne à la fin de ce chapitre</span>,
        <span onClick={() => handleAdd(false)}>Ajouter une nouvelle ligne avant</span>,
        <span onClick={() => handleAdd(true)}>Ajouter une nouvelle ligne après</span>,
      ]
    : [
        <span onClick={handleDelete}>Supprimer cette ligne</span>,
        <span onClick={() => handleReplace('chapter')}>Transformer cette ligne en chapitre</span>,
        <span onClick={() => handleReplace('freeField')}>Transformer cette ligne en champ libre</span>,
        <span onClick={() => handleAdd(false)}>Ajouter une nouvelle ligne avant</span>,
        <span onClick={() => handleAdd(true)}>Ajouter une nouvelle ligne après</span>,
      ];

  const style = { border: 'none', margin: '0 -5rem 0 0.5rem' };
  return (
    <CmsMenuButton preventClose={false} title={<CmsIcon icon="moreVertical" />} buttonStyle={style}>
      {buttonList}
    </CmsMenuButton>
  );
};

const quotationPriceScheduleConfigList: CmsColumnDndDef<any>[] = [
  { header: 'Bordereau/Désignation', id: 'label', size: 500, cell: designationCell },
  {
    header: 'Unité',
    id: 'unitId',
    size: 0,
    inputOptions: { data: APIRoute.WkUnit + '/Simplified' },
    cell: DndInput.Select,
  },
  { header: 'Prix unitaire', id: 'price', size: 0, cell: DndInput.Number },
  { header: 'Quantité', id: 'quantity', size: 0, cell: DndInput.Number },
  { header: 'Taxe %', id: 'vatId', maxSize: 0, cell: handleVat, inputOptions: { data: APIRoute.BlVat } },
  { header: 'Réduction', id: 'discount', size: 0, cell: DndInput.Number },
  {
    header: 'Montant',
    id: 'amount',
    size: 0,
    cell: (x) => {
      const val: number = x.cell.row.original.chapterAmount ?? x.cell.getValue() ?? 0;
      return <div style={{ textAlign: 'right' }}>{val.toFixed(2)} €</div>;
    },
  },
  { header: 'Action', id: 'action', size: 0, cell: handleActions },
];

export function getChapterAmount(table: BlQuotationLine[], rowId: number, attr = 'amount'): number {
  let amount = 0;
  let counting = false;
  for (let i = 0; i < table.length; i++) {
    if (table[i].id !== rowId && !counting) continue;
    else counting = true;
    amount += +((table[i] as any)?.[attr] ?? 0);
    if (table[i].id !== rowId && table[i].chapter) return +amount?.toFixed(2) ?? 0;
  }
  return +amount?.toFixed(2) ?? 0;
}

//#endregion

//#region QuotationCreateEdit

function useHandleBusinessRules(): [BlQuotationLine[], Dispatch<SetStateAction<BlQuotationLine[]>>] {
  const [state, setState] = React.useState<any[]>([]);
  console.log('state', state);
  const handleStateUpdate: any = (table: any[]) => {
    if (!table || !table.length) return;
    let currentIndent = 0;
    for (let row of table.filter((x) => !x.chapter)) {
      row.amount = (row.price ?? 0) * (row.quantity ?? 0) - (row.discount ?? 0);
      row.vatAmount = !row.vatValue ? 0 : row.amount * (row.vatValue ?? 0);
    }
    setState(
      table.map((row, i) => {
        if (row.chapter) {
          currentIndent = row.chapterLevel ?? 0;
          row.chapterAmount = getChapterAmount(table, row.id);
        }
        row.position = i + 1;
        row.indent = currentIndent;
        return row;
      }),
    );
  };
  return [state, handleStateUpdate];
}

export const QuotationCreateEdit: FC<any> = ({ id }) => {
  const [state, setState] = useHandleBusinessRules();
  const baseUrl = '/castres/billing/quotation/';

  // TODO DELETE after test
  useEffect(() => {
    CRUD.getList<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup + '/ForImport').then((x) => {
      console.log('result group', x);
    });
  }, []);

  const handleLineSuggestion = (itemSuggested: BlQuotationSuggestedLine, line: BlQuotationLine) => {
    if (!itemSuggested) return;
    let lineIndex = state.findIndex((x) => x.id === line.id);
    if (lineIndex === -1) return notificationService.error('Erreur Dnd Table: ligne non trouvée');
    if (!itemSuggested.childrenList) {
      state.splice(lineIndex, 1, itemSuggested);
      return setState([...state]);
    }
    let result = [];
    for (let l of itemSuggested.childrenList) {
      result.push({ ...l, id: getNewId(state) });
      if (!l.childrenList) continue;
      for (let sub of l.childrenList) result.push({ ...sub, id: getNewId(state) });
    }
    setState([...state.slice(0, lineIndex), ...result, ...state.slice(lineIndex + 1)]);
  };

  const handleSave = async (data: any) => {
    let errorList = [];
    for (let line of state.filter((x) => !x.chapter)) {
      line.rowColor = undefined;
      if (!line.label || !line.unitId || !line.price || !line.quantity || !line.vatId) {
        line.rowColor = 'rgba(255, 0, 0, 0.2)';
        errorList.push(line.position);
      }
    }
    if (errorList.length) {
      notificationService.error('Les lignes ' + errorList.join(', ') + ' sont incomplètes');
      return Promise.resolve({ ...data, quotationLines: state });
    }
    let payload = { ...data, linesStringified: state, quotationLines: null };
    const result = await CRUD.postFormData(APIRoute.BlQuotation, payload, !!id);
    notificationService.success('Devis ' + (id ? 'modifié' : 'ajouté') + ' avec succès');
    return result;
  };

  const handleCustomReorder = (draggedRowIndex: number, targetRowIndex: number) => {
    if (!state) return;
    const data = [...state];
    const row = data[draggedRowIndex];
    if (!row.chapter) {
      data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0] as any);
      return setState(data);
    }
    // TODO Not working properly
    let endIndex = getEndChapterIndex(data, draggedRowIndex);
    data.splice(targetRowIndex, 0, ...data.splice(draggedRowIndex, endIndex));
    return setState(data);
  };

  return (
    <CmsForm
      id={id}
      currentUrl={'/castres/billing/quotation'}
      route={APIRoute.BlQuotation}
      onSubmit={handleSave}
      onGetEditData={(quotation: BlQuotation) => {
        setState(quotation?.quotationLines ?? []);
        return quotation;
      }}
      renderForm={(formState: UseFormReturn, submit: any) => {
        const { interventionRef, clientLabel, contactName, ref } = formState.watch();
        let title = id ? 'Éditer le devis ' + ref : 'Créer un devis';
        return [
          <FormUI.HeaderFormPanel pageId={id} title={title} form={formState} baseUrl={baseUrl} onSubmit={submit} />,
          <div className="cms-grid fr3">
            <CmsPaper title="Informations générales">
              <CmsFormInput.Text id="title" label="Titre" required multiline />
              <CmsFormInput.Select id="jobId" label="Corps d'état" options={APIRoute.WkJob + '/Simplified'} />
              <CmsFormInput.Select
                id="agreementId"
                label="Type de contrat"
                options={APIRoute.WkAgreement + '/Simplified'}
              />
              <CmsFormInput.Select id="groupId" label="Groupe" options={APIRoute.WkInterventionGroup + '/Simplified'} />
              <CmsFormInput.Suggest
                id="interventionId"
                label="Affectation"
                optionLabel="ref"
                defaultValueHolder={interventionRef}
                route={APIRoute.WkIntervention}
                // TODO vérifier non régression
                onValueSelected={(item: any) => setStationChildren(formState, item)}
              />
              <CmsFormInput.Select id="serviceId" label="Service" options={APIRoute.SfService} />
            </CmsPaper>
            <CmsPaper title="Destinataire">
              <CmsFormInput.Select id="stationId" label="Site" options={APIRoute.ClStation + '/Simplified'} />
              <CmsFormInput.Suggest
                id="clientId"
                label="Client"
                defaultValueHolder={clientLabel}
                route={APIRoute.ClClient}
              />
              <CmsFormInput.Suggest
                id="contactId"
                label="Contact"
                defaultValueHolder={contactName}
                route={APIRoute.ClContact}
              />
            </CmsPaper>
            <QuotationPicAndFiles state={formState.watch() as BlQuotation} />
          </div>,
          <QuotationComments state={formState.watch() as BlQuotation} />,
          <CmsDndTable
            title="Lignes du devis"
            stateHandler={{ state, setState }}
            handleCustomReorder={handleCustomReorder}
            columns={quotationPriceScheduleConfigList}
            lineSharedFunctionList={{ handleLineSuggestion }}
            rowStyle={(row) => {
              if (row.original.rowColor) return { backgroundColor: row.original.rowColor };
              return row.original.chapter ? { backgroundColor: '#0055AA' } : {};
            }}
            footerLines={QuotationFooter({ state })}
          />,
        ];
      }}
    />
  );
};

const QuotationPicAndFiles: FC<{ state: BlQuotation }> = ({ state }) => {
  // const {pic} = state;
  // let imageUrl: string;
  // const havePic = !! && userPic.size > 0;
  // if (havePic) {
  //   const urlCreator = window.URL || window.webkitURL;
  //   imageUrl = urlCreator.createObjectURL(userPic);
  // } else imageUrl = gender === 'm' ? homme : femme;
  return (
    <CmsPaper className="pic-form" title="Pièce jointes">
      {/*<div className={'quotation-holder'} style={{ backgroundImage: `url(${imageUrl})` }} />*/}
      <div className="pic-buttons">
        <CmsFormInput.File id="quotation-pic" label="Photo en entète du devis" image />
      </div>
      <UI.Divider />
      <CmsFormInput.File id="linked-files" label="Pièce jointes" multiple />
    </CmsPaper>
  );
};

const QuotationComments: FC<{ state: BlQuotation }> = ({ state }) => {
  return (
    <CmsPaper className="pic-form">
      <CmsFormInput.Text id="comment" label="Commentaire" multiline />
    </CmsPaper>
  );
};

const QuotationFooter: FC<{ state: BlQuotationLine[] }> = ({ state }) => {
  const ht = state.reduce((acc, x) => acc + (x.amount ?? 0), 0);
  const taxTotal = state.reduce((acc, x) => acc + (x.vatAmount ?? 0), 0);
  const emptyCol = [1, 2, 3, 4, 5, 6].map((x) => <TableCell key={x} />);
  return [
    <TableRow key="footer-ht">
      {emptyCol}
      <TableCell>HT</TableCell>
      <TableCell style={{ textAlign: 'right' }}>{ht.toFixed(2)} €</TableCell>
    </TableRow>,
    <TableRow key="footer-tax">
      {emptyCol}
      <TableCell>Taxe</TableCell>
      <TableCell style={{ textAlign: 'right' }}>{taxTotal.toFixed(2)} €</TableCell>
    </TableRow>,
    <TableRow key="footer-ttc">
      {emptyCol}
      <TableCell>Total TTC</TableCell>
      <TableCell style={{ textAlign: 'right' }}>{(ht + taxTotal).toFixed(2)} €</TableCell>
    </TableRow>,
  ];
};

function setStationChildren(form: UseFormReturn, intervention: WkIntervention): void {
  if (!intervention || form.getValues('interventionId') === intervention.id) return;
  form.reset({ ...form.watch(), interventionId: intervention.id, stationId: intervention.station?.id });
}

//#endregion

//#region ImportPanel

// interface BlRefStudyImportPanelProps {
//   open: boolean;
//   setOpen: Dispatch<SetStateAction<boolean>>;
//   refStudyList: any[];
//   handleImport: (importItem: any) => void;
// }
//
// const importTypeList: IdLabel[] = [
//   { id: 1, label: 'Bordereau' },
//   { id: 2, label: "Référence d'étude" },
//   { id: 3, label: 'Articles' },
// ];

// const BlRefStudyImportPanel: FC<BlRefStudyImportPanelProps> = ({ open, setOpen, handleImport }) => {
//   const [selectedButton, setSelectedButton] = useState(1);
//   const [refStudyList, setRefStudyList] = useState<any[]>([]);
//   const [priceScheduleList, setPriceScheduleList] = useState<any[]>([]);
//   const [articleList, setArticleList] = useState<any[]>([]);
//   console.log('priceScheduleList', priceScheduleList);
//
//   useEffect(() => {
//     CRUD.getList<BlPriceScheduleGroup>(APIRoute.BlPriceScheduleGroup + '/ForImport').then(setPriceScheduleList);
//     CRUD.getList<BlReferenceStudyGroup>(APIRoute.BlReferenceStudyGroup).then(setRefStudyList);
//   }, []);
//
//   return (
//     <CmsImportPanel {...{ open, setOpen }} title={<h3>Panneau d'import</h3>}>
//       <div className="flex-center" style={{ marginBottom: '.5rem' }}>
//         <InputUI.CmsButtonGroup value={selectedButton} options={importTypeList} onClick={setSelectedButton} />
//       </div>
//       {/*{selectedButton === 1 && <CmsImportSimpleList optionsList={refStudyList} onClick={handleImport} />}*/}
//       {/*{selectedButton === 2 && <BlRefStudyGroupImport {...{ refStudyList, handleImport }} />}*/}
//     </CmsImportPanel>
//   );
// };

// interface BlPriceScheduleGroupImportProps {
//   refStudyList: any[];
//   handleImport: (refStudy: any) => void;
// }

// const BlRefStudyGroupImport: FC<BlPriceScheduleGroupImportProps> = ({ refStudyList, handleImport }) => {
//   const [filterValue, setFilterValue] = useState<string>();
//   const groupedByUnit = useMemo(() => {
//     let grouped: { id: any; label: string; children: any[] }[] = [];
//     for (let x of refStudyList) {
//       let unit = grouped.find((y) => y.id === x.unitId);
//       if (!unit) {
//         unit = { id: x.unitId, label: x.unitLabel, children: [] };
//         grouped.push(unit);
//       }
//       unit.children.push(x);
//     }
//     return grouped;
//   }, [refStudyList]);
//   const filteredAndGroupedByUnit = useMemo(() => {
//     if (!filterValue) return groupedByUnit;
//     let filtered: { id: any; label: string; children: any[] }[] = [];
//     for (let x of groupedByUnit) {
//       let unitFilteredChildren = x.children.filter((y: any) =>
//         (y.label as string)?.toLowerCase().includes(filterValue ?? ''),
//       );
//       if (unitFilteredChildren.length === 0) continue;
//       filtered.push({ ...x, children: unitFilteredChildren });
//     }
//     return filtered;
//   }, [groupedByUnit, filterValue]);
//   return (
//     <div>
//       <InputUI.DebouncedInput onChange={(x: string) => setFilterValue(x?.toLowerCase())} />
//       <CmsTreeView tree={filteredAndGroupedByUnit} onCardClick={handleImport} />
//     </div>
//   );
// };

//#endregion
