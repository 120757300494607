import CmsTableFilter from 'component/table/helper/CmsTableFilter';
import React, { FC, useCallback, useEffect, useState } from 'react';
import AccessFilter from '../../../helper/AccessFilter';
import { AddNavigationButton, ArrowBackNavigationButton } from '../../../component/shared/Buttons';
import APIRoute from '../../../constant/API.constant';
import { CmsColumnDef, CmsFrontendTable } from '../../../component/table/CmsTable';
import ROLE from '../../../constant/role.constant';
import { BlFixedCostContribution, BlFixedCostContributionLine } from 'interface/BlType';
import CmsTableCell from 'component/table/helper/CmsTableCell';
import { CmsPaper } from 'component/shared/Ui';
import CRUD from 'service/CRUD.service';
import { useNavigate } from 'react-router-dom';
import { handleErrorPage } from 'helper/handle-response';
import LoadingScreen from 'component/LoadingScreen';
import { TabStyleDataView } from 'component/shared/TabStyleDataView';
import { LabelValue } from 'interface/CommonType';

export const BlFixedCostContributionListLineConfig: CmsColumnDef<BlFixedCostContributionLine>[] = [
  { header: "Chiffre d'affaires minimum", id: 'caMin', Filter: CmsTableFilter.Number },
  { header: "Chiffre d'affaires maximum", id: 'caMax', Filter: CmsTableFilter.Number },
  { header: 'Pourcentage de coût fixe (en %)', id: 'fixedCostPercentage', Filter: CmsTableFilter.Number },
  {
    header: 'Service',
    id: 'serviceId',
    cell: (info) => <CmsTableCell.Ref cell={info} value={info.row.original.serviceLabel} />,
    Filter: CmsTableFilter.Select,
    filterOptions: { optionList: APIRoute.SfService, optionLabel: 'label', multiple: true },
  },
  {
    header: 'Dates de début de validité',
    id: 'startDate',
    Filter: CmsTableFilter.Date,
    defaultFilterValue: { value: new Date(), type: 10 },
  },
  {
    header: 'Dates de fin de validité',
    id: 'endDate',
    Filter: CmsTableFilter.Date,
    defaultFilterValue: { value: new Date(), type: 9 },
  },
];

export const BlFixedCostContributionList: FC = () => {
  const actions = AccessFilter([ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_CREATE])
    ? [<AddNavigationButton title="Ajouter une définition de coûts fixes" to="/castres/billing/fixedcost/create" />]
    : [];
  return (
    <CmsFrontendTable
      title="Liste des définitions de coûts fixes"
      route={APIRoute.BlFixedCostContributionLine}
      columns={BlFixedCostContributionListLineConfig}
      navigateTo={(_, cell) => `/castres/billing/fixedcost/${cell.row.original.fixedCostContributionId}/show`}
      actions={actions}
      setFiltersInUrl
    />
  );
};

/**
 * Fiche fixedCost (page show)
 * @param id identifiant de la fixedCost
 * @param setHttpCodePage fonction pour changer le code http de la page
 */
export const FixedCostDetail: React.FC = ({ id, setHttpCodePage }: any) => {
  const [fixedCost, setFixedCost] = useState<any>();
  const navigate = useNavigate();

  const returnToList = useCallback(() => {
    navigate('/castres/billing/fixedcost/list');
  }, [navigate]);

  useEffect(() => {
    if (id && !fixedCost) {
      setFixedCost({ isLoading: true });
      CRUD.getById(APIRoute.BlFixedCostContribution, id)
        .then((res: any) => (res ? setFixedCost(res) : returnToList()))
        .catch((response) => handleErrorPage(response, setHttpCodePage));
    } else if (!id) returnToList();
    else if (id && fixedCost?.id && id !== fixedCost.id.toString()) setFixedCost(undefined);
  }, [id, fixedCost, navigate, returnToList, setHttpCodePage]);

  if (!fixedCost || fixedCost.isLoading) return <LoadingScreen />;

  return (
    <>
      <CmsPaper
        title="Général"
        style={{ display: 'flex', flexDirection: 'column', maxWidth: 600 }}
        actions={[<ArrowBackNavigationButton title="Retourner à la liste" to="/castres/billing/fixedcost/list" />]}
        tied
      >
        <TabStyleDataView conf={GeneralLabelValue} mapFrom={fixedCost} />
      </CmsPaper>
      <CmsFrontendTable
        title="Tranche de coûts fixe"
        route="none"
        actions={[
          <AddNavigationButton
            title="Editer la définition de coût fixe"
            to={`/castres/billing/fixedcost/${id}/edit`}
            access={[ROLE.ADMIN_CONFIGURATION_FIXEDCOSTCONTRIBUTION_EDIT]}
          />,
        ]}
        showHeader="never"
        columns={localFixedCostLineColumn}
        initialPageSize={100}
        controlledState={{ state: fixedCost.blFixedCostContributionLine ?? [], setState: () => {} }}
      />
    </>
  );
};

const GeneralLabelValue: LabelValue[] = [
  { label: 'Service', value: (x: BlFixedCostContribution) => x.serviceLabel },
  { label: 'Date de début', value: (x: BlFixedCostContribution) => x.startDate },
];
const localFixedCostLineColumn: CmsColumnDef<BlFixedCostContributionLine>[] = [
  { header: "Chiffre d'affaires minimum", id: 'caMin' },
  { header: "Chiffre d'affaires maximum", id: 'caMax' },
  { header: 'Pourcentage de coût fixe (en %)', id: 'fixedCostPercentage' },
];
