// Ce fichier à pour but de factoriser toute les URL de l'API au même endroit

export const WhiteListLogoutToLoginV2: Array<(x: string) => {}> = [
  (x: string) =>
    x.indexOf('/castres/timereporting') !== -1 && x.indexOf('/castres/timereporting/administration') === -1,
  (x: string) => x.indexOf('/castres/staff/absence/') !== -1,
];

/**
 * Enumération des routes de l'API (pour éviter les fautes de frappe)
 */
enum APIRoute {
  Login = 'Login',
  WkTimeReportingWeek = 'WkTimeReportingWeek',
  WkTimeReportingDay = 'WkTimeReportingDay',
  WkAlarmAlx = 'WkAlarmAlx',
  WkAlarmAlxType = 'WkAlarmAlxType',
  Other = 'Other',

  // ALL Database Names
  BlManPower = 'BlManPower',
  SfHabilitation = 'SfHabilitation',
  SfHabilitationType = 'SfHabilitationType',
  SfHabilitationCellPosition = 'SfHabilitationCellPosition',
  SfFormation = 'SfFormation',
  UsNotification = 'UsNotification',
  BlAccount = 'BlAccount',
  BlAccountOrder = 'BlAccountOrder',
  BlContract = 'BlContract',
  BlContractCounter = 'BlContractCounter',
  BlContractDiscount = 'BlContractDiscount',
  BlContractFrequencyInvoice = 'BlContractFrequencyInvoice',
  BlContractLine = 'BlContractLine',
  BlContractLineEquipment = 'BlContractLineEquipment',
  BlContractLineIntervention = 'BlContractLineIntervention',
  BlContractLineInterventionStatus = 'BlContractLineInterventionStatus',
  BlContractSchedulePayment = 'BlContractSchedulePayment',
  BlContractStatus = 'BlContractStatus',
  BlContractTypeInvoice = 'BlContractTypeInvoice',
  BlDelivery = 'BlDelivery',
  BlDeliveryCounter = 'BlDeliveryCounter',
  BlDeliveryLine = 'BlDeliveryLine',
  BlDeliveryType = 'BlDeliveryType',
  BlFixedCostContribution = 'BlFixedCostContribution',
  BlFixedCostContributionLine = 'BlFixedCostContributionLine',
  BlDeposit = 'BlDeposit',
  BlInvoice = 'BlInvoice',
  BlInvoiceCounter = 'BlInvoiceCounter',
  BlInvoiceCustomLine = 'BlInvoiceCustomLine',
  BlInvoiceLine = 'BlInvoiceLine',
  BlInvoiceSupplier = 'BlInvoiceSupplier',
  BlInvoiceSupplierLine = 'BlInvoiceSupplierLine',
  BlNature = 'BlNature',
  BlOrderCounter = 'BlOrderCounter',
  BlOrderLine = 'BlOrderLine',
  BlOrders = 'BlOrder',
  BlPaymentMode = 'BlPaymentMode',
  BlPriceSchedule = 'BlPriceSchedule',
  BlPriceScheduleChapter = 'BlPriceScheduleChapter',
  BlPriceScheduleGroup = 'BlPriceScheduleGroup',
  BlPriceScheduleGroupCompany = 'BlPriceScheduleGroupCompany',
  BlPriceScheduleGroupType = 'BlPriceScheduleGroupType',
  BlPriceScheduleType = 'BlPriceScheduleType',
  BlQuotation = 'BlQuotation',
  BlQuotationCounter = 'BlQuotationCounter',
  BlQuotationInvoice = 'BlQuotationInvoice',
  BlQuotationLine = 'BlQuotationLine',
  BlQuotationRequest = 'BlQuotationRequest',
  BlSituation = 'BlSituation',
  BlSituationLine = 'BlSituationLine',
  BlStatus = 'BlStatus',
  BlStatusOrder = 'BlStatusOrder',
  BlTolerance = 'BlTolerance',
  BlVat = 'BlVat',
  ClAgency = 'ClAgency',
  ClClient = 'ClClient',
  ClClientCode = 'ClClientCode',
  ClClientStation = 'ClClientStation',
  ClCompany = 'ClCompany',
  ClContact = 'ClContact',
  ClContactStation = 'ClContactStation',
  ClLegalEntity = 'ClLegalEntity',
  ClNetwork = 'ClNetwork',
  ClStation = 'ClStation',
  ClStationImport = 'ClStationImport',
  ClStationService = 'ClStationService',
  EqAction = 'EqAction',
  EqBelt = 'EqBelt',
  EqCalculator = 'EqCalculator',
  EqConnection = 'EqConnection',
  EqDecision = 'EqDecision',
  EqDiameter = 'EqDiameter',
  EqDispenserMotorType = 'EqDispenserMotorType',
  EqDomain = 'EqDomain',
  EqEffect = 'EqEffect',
  EqEquipment = 'EqEquipment',
  EqEquipmentAttribute = 'EqEquipmentAttribute',
  EqEquipmentAttributeList = 'EqEquipmentAttributeList',
  EqEquipmentAttributetype = 'EqEquipmentAttributetype',
  EqEquipmentImport = 'EqEquipmentImport',
  EqEquipmentType = 'EqEquipmentType',
  EqEquipmentValue = 'EqEquipmentValue',
  EqFlexlength = 'EqFlexlength',
  EqGroup = 'EqGroup',
  EqManufacturer = 'EqManufacturer',
  EqMetrologicModel = 'EqMetrologicModel',
  EqModel = 'EqModel',
  EqModelEquipmenttype = 'EqModelEquipmenttype',
  EqNature = 'EqNature',
  EqPistol = 'EqPistol',
  EqProduct = 'EqProduct',
  EqSolenoidType = 'EqSolenoidType',
  EqSticker = 'EqSticker',
  EqStickerMark = 'EqStickerMark',
  EqStickerPack = 'EqStickerPack',
  EqStickerType = 'EqStickerType',
  EqSupplier = 'EqSupplier',
  EqTaskEquipment = 'EqTaskEquipment',
  GoAddress = 'GoAddress',
  GoAddressFound = 'GoAddressFound',
  GoPoi = 'GoPoi',
  GoPoiCategory = 'GoPoiCategory',
  GoVehicles = 'GoVehicles',
  LmConfig = 'LmConfig',
  LoCity = 'LoCity',
  LoDepartment = 'LoDepartment',
  LoRegion = 'LoRegion',
  LoState = 'LoState',
  MdGallery = 'MdGallery',
  MdMedia = 'MdMedia',
  SfAbsence = 'SfAbsence',
  SfAbsenceType = 'SfAbsenceType',
  SfAssignment = 'SfAssignment',
  SfAttachmentType = 'SfAttachmentType',
  SfAttachments = 'SfAttachments',
  SfContract = 'SfContract',
  SfDrive = 'SfDrive',
  SfExpenseReceipt = 'SfExpenseReceipt',
  SfExpenseType = 'SfExpenseType',
  SfLicenseType = 'SfLicenseType',
  SfMedicalvisit = 'SfMedicalvisit',
  SfMedicalvisitType = 'SfMedicalvisitType',
  SfService = 'SfService',
  SfServiceDomain = 'SfServiceDomain',
  SfTeam = 'SfTeam',
  SfTimesheet = 'SfTimesheet',
  SfTraining = 'SfTraining',
  SfTrainingType = 'SfTrainingType',
  SfWarning = 'SfWarning',
  SfWorktime = 'SfWorktime',
  SkArticle = 'SkArticle',
  SkArticleType = 'SkArticleType',
  SkBrand = 'SkBrand',
  SkCastresArticle = 'SkCastresArticle',
  SkCompany = 'SkCompany',
  SkExpeditionAddress = 'SkExpeditionAddress',
  SkFamily = 'SkFamily',
  SkInventory = 'SkInventory',
  SkMovement = 'SkMovement',
  SkMovementGroup = 'SkMovementGroup',
  SkStatus = 'SkStatus',
  SkStatusMovement = 'SkStatusMovement',
  SkStock = 'SkStock',
  SkSupplier = 'SkSupplier',
  SkTool = 'SkTool',
  SkToolEvent = 'SkToolEvent',
  SkToolMark = 'SkToolMark',
  SkToolModel = 'SkToolModel',
  SkToolStatus = 'SkToolStatus',
  SkToolType = 'SkToolType',
  UsGroups = 'UsGroups',
  UsRole = 'UsRole',
  UsUserLicensetype = 'UsUserLicensetype',
  UsUsers = 'UsUsers',
  UsUsersGroups = 'UsUsersGroups',
  VStatPanneFinalFiltre = 'VStatPanneFinalFiltre',
  VStatPanneTaskClosedDate = 'VStatPanneTaskClosedDate',
  VStatPanneTaskClosedDateId = 'VStatPanneTaskClosedDateId',
  WkActionEquipment = 'WkActionEquipment',
  WkAgreement = 'WkAgreement',
  WkAnomaly = 'WkAnomaly',
  WkCampaignType = 'WkCampaignType',
  WkCampaignTypePriceSchedule = 'WkCampaignTypePriceSchedule',
  WkCampaigntypeCompany = 'WkCampaigntypeCompany',
  WkCampaigntypeNetwork = 'WkCampaigntypeNetwork',
  WkDomain = 'WkDomain',
  WkDomainGroup = 'WkDomainGroup',
  WkEmergencyLevel = 'WkEmergencyLevel',
  WkEvent = 'WkEvent',
  WkFamily = 'WkFamily',
  WkFiletype = 'WkFiletype',
  WkIntervention = 'WkIntervention',
  WkInterventionFailure = 'WkInterventionFailure',
  WkInterventionNote = 'WkInterventionNote',
  WkInterventionGroup = 'WkInterventionGroup',
  WkJob = 'WkJob',
  WkJsonReport = 'WkJsonReport',
  WkMetrologicTest = 'WkMetrologicTest',
  WkNature = 'WkNature',
  WkPhotoReportItem = 'WkPhotoReportItem',
  WkReport = 'WkReport',
  WkReportCategory = 'WkReportCategory',
  WkReportItem = 'WkReportItem',
  WkReportMeasure = 'WkReportMeasure',
  WkReportType = 'WkReportType',
  WkReportValue = 'WkReportValue',
  WkReportVersion = 'WkReportVersion',
  WkRv2Test = 'WkRv2Test',
  WkSource = 'WkSource',
  WkStatusCertas = 'WkStatusCertas',
  WkTask = 'WkTask',
  WkTaskEquipmentCri = 'WkTaskEquipmentCri',
  WkTaskFailure = 'WkTaskFailure',
  WkTotalEnergies = 'WkTotalEnergies',
  WkVprim = 'WkVprim',
  WkVprimAnomaly = 'WkVprimAnomaly',
  WkVprimEquipmentEmr = 'WkVprimEquipmentEmr',
  WkVprimGroup = 'WkVprimGroup',
  WkGreenfluxCdr = 'WkGreenflux/cdr',
  WkGreenfluxStations = 'WkGreenflux/station',
  WkGreenfluxNoteDeVirement = 'WkGreenflux/NoteDeVirement',
  WkGreenflux = 'WkGreenflux',
  WkUnit = 'WkUnit',
  BlStudyCategory = 'BlStudyCategory',
  BlReferenceStudyLine = 'BlReferenceStudyLine',
  BlReferenceStudyGroup = 'BlReferenceStudyGroup',
}

export default APIRoute;
